// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import{ getFirestore } from "firebase/firestore" ;
// Import other Firebase services as needed


const firebaseConfig = {
  apiKey: "AIzaSyBHZcXNEqsD7knybX6HcAgVD_TWxDCY2nk",
  authDomain: "karat-560b7.firebaseapp.com",
  projectId: "karat-560b7",
  storageBucket: "karat-560b7.appspot.com",
  messagingSenderId: "660086673976",
  appId: "1:660086673976:web:5e6168b6c90d6adca78ccd",
  measurementId: "G-ZKX29BWHED"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
// Initialize Firebaseconst app = initializeApp(firebaseConfig);
const db = getFirestore(app);
 
export { db, auth };
