const Colors = {
    primary: '#ea7713',
    portalPrimary: '#363978',
    secondaryprimary:'#2d2f51',
    disableColor:'#a8b1bd',
    primarygradient:'linear-gradient(to right, #2D2F51, #004E92)',
    secondaryGradient:'linear-gradient(93.84deg, #2D2F51 0%, #004E92 100%)',
    whatsappgreen:'#3ebd61',
    black:'#333333',
    reloadColor:'#27f4ff',
    primaryRed:'#c02c0c',
};

export default Colors;