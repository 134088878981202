import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import GlobalStateReducer from './Store/Reducers/formReducer';
import { configureStore } from '@reduxjs/toolkit'
import {BrowserRouter} from 'react-router-dom'
import { reducer as formReducer } from 'redux-form';
import formStateReducer from './Store/Reducers/formReducer';
import reducer from './Store/Reducers/globalReducer';
import restApiReducers from './Store/Reducers/restApiReducers';
import { combineReducers, compose } from 'redux';

import logger from 'redux-logger';
// const store = configureStore ({
//   reducer: { globalState : GlobalStateReducer,
//     form: formReducer,
//   }
// })
const rootReducers = combineReducers({
  form: formReducer,
  globalState: reducer,
  formState: formStateReducer,
  restApiState: restApiReducers
})

// const store = createStore(reducer);
const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}).concat(logger),
}
  // rootReducers, composeEnhancers(applyMiddleware(thunk))
)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
      <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
