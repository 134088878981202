import React, { Component, useEffect, useMemo, useState} from 'react'
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { reduxForm, change} from 'redux-form';
import * as BaseScreen from '../../BaseScreen.js';
import Colors from '../../Constants/Colors.js';
import Strings from '../../Constants/Strings.js';
import { FormItem } from '../../REDUXFORM/InputField.js';
import { renderFields } from '../../REDUXFORM/ReduxFrom.js';
import Images from "../../Images/Images.js";
import { validate } from '../../REDUXFORM/Validate.js';
import ReduxValues from '../../Store/ReduxValues.js';
// import classes from "../../Style/Profile.module.css";
import { isLoading, isPOPOpen,setIsCurrentSelectedUserDataForModal ,setStepCount} from "../../Store/Actions/GlobalActions.js";
import moment from "moment";
import dayjs from 'dayjs';
// import style from  "../../Style/Portal/steper.css";

import {
  createProfile,
  fetchProfile,
  fetchUserProfile
} from "../../Store/Actions/RestAPiActions.js";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Styles from '../../Style/Portal/global.module.css'
import { routeName ,portelRouteName} from '../../Routers/Router.js';
import Stepper from '../Stepper'
let FormTypes = Strings.FormTypes


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
function submit(values, onSave) {
  return sleep(1)
    .then(() => {
      onSave(values);
    })
    .catch((err) => {
      console.log("form error in from ", err);
    });
}

let stepperList=[2,3,4]
let stepperIfaOnboarding=[5,6,7,8]

export const getStepperColor=(ele,globalState)=>{
  if(globalState?.isStepCountStatus === ele){
    return Colors.primary
  }else if(ele < globalState?.isStepCountStatus){
    return '#26A649' //green
  }else{
    return "#CCCCCC" //grey
  }
}

export const renderStepper = (globalState,customStepperList=[],IsFromIfaOnboarding=false,CustomWidth=null) => {
  stepperList= customStepperList && customStepperList?.length>0 ?customStepperList:IsFromIfaOnboarding === true ? stepperIfaOnboarding : stepperList || customStepperList
  return(
    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:CustomWidth || "150px"}}>
    {
      stepperList.map((ele,idx)=>{
        return  <Stepper borderColor={getStepperColor(ele,globalState)} />
      })
    }
    </div>
  )  
 
};


// export default function profileInfo() {
const UserPersonalDetails = (props) => {
  const location = useLocation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formState = useSelector((state) => state.formState);
  const form = useSelector((state) => state.form)
  const restApiState = useSelector(state => state.restApiState)
  const globalState = useSelector(state => state.globalState)
  const [step, setStep] = useState(1);

  const onBlurHandler = (e) => {
    console.log("onblur", e.target.name)


  }
  const ClipLoader = (flag) => {
    dispatch(isLoading(flag));
  };

  useEffect(() => {
    if(globalState.isStepCountStatus){
      setStep(globalState.isStepCountStatus)
    }
}, [globalState.isStepCountStatus])

useEffect(() => {
  dispatch(setStepCount(2));
},[])

  useEffect(() => {
    if (globalState?.currentSelectedUserDataForModal || props?.addNewUserLoggedInUserID) {
    let params=`?user=${props?.addNewUserLoggedInUserID || globalState.currentSelectedUserDataForModal.user}`
  
    dispatch(fetchUserProfile({
      pathParams:params,
      callback: (res) => {
        console.log("fetchuserfromEdit", res);
        if(res){
          res?.name && dispatch(change("userPersonalDetailsForm",ReduxValues.userPersonalDetailsFields.FULL_NAME,res.name))
          res?.date_of_birth &&  dispatch(change("userPersonalDetailsForm", ReduxValues.userPersonalDetailsFields.DOB_ID,dayjs(res.date_of_birth)))
          res?.gender && dispatch(change("userPersonalDetailsForm",ReduxValues.userPersonalDetailsFields.GENDER,res.gender))
          res?.email && dispatch(change("userPersonalDetailsForm",ReduxValues.userPersonalDetailsFields.EMAIL_ID,res.email))
          res?.pan_number && dispatch(change("userPersonalDetailsForm",ReduxValues.userPersonalDetailsFields.PAN_ID,res.pan_number))       
          res?.address && dispatch(change("userPersonalDetailsForm",ReduxValues.userPersonalDetailsFields.CURRENTADDRESS_ID,res.address))
          res?.pincode && dispatch(change("userPersonalDetailsForm",ReduxValues.userPersonalDetailsFields.PINCODE_ID,res.pincode))
        }
    
      }
    }));
  }
  }, [globalState.currentSelectedUserDataForModal]);

  const formSubmitHandler = async (isOnlySave) => {
    let errorValues = await props.handleSubmit((value) =>
      submit(value, onFormSaveHandler, isOnlySave)
    )();

    console.log("error formSubmitdHandler", errorValues);
  };

  const onFormSaveHandler = async (Values) => {
    console.log("Values", Values);
    successHandler(Values)
  };

  const successHandler = (Values) => {
      if (Values) {
        let reqdate= Values['DOB_ID']? moment(Values.DOB_ID['$d']?Values.DOB_ID['$d']:Values.DOB_ID['_d']? Values.DOB_ID['_d']:Values['DOB_ID']).format('YYYY-MM-DD'):null
        if (reqdate == null || Values?.FULL_NAME == null || Values?.PAN_ID == null || Values?.EMAIL_ID == null || Values?.CURRENTADDRESS_ID == null|| Values?.PINCODE_ID == null|| Values?.GENDER == null) {
          return;
        }

        let prevdateofProfile=restApiState?.fetchPortalUserProfileResponse || null
        if(location?.state?.iseditProfile && prevdateofProfile && 
          prevdateofProfile?.email?.toLowerCase()?.trim() === Values?.EMAIL_ID.toLowerCase()?.trim()&&
          prevdateofProfile?.pincode?.toLowerCase()?.trim() === Values?.PINCODE_ID.toLowerCase()?.trim() &&
          prevdateofProfile?.address?.toLowerCase()?.trim() === Values?.CURRENTADDRESS_ID.toLowerCase()?.trim()
          ){
            navigate(props?.navigateTo?props.navigateTo:portelRouteName.users)
            return;
        }

        let request;

    if(globalState.fieldDisabled) {
      request = {
        "pincode": Values?.PINCODE_ID,
        "address": Values?.CURRENTADDRESS_ID,
        "user":location?.state?.userID || restApiState?.newUserResponse?.user_id
      } 

      if(restApiState?.fetchPortalUserProfileResponse?.email && restApiState?.fetchPortalUserProfileResponse?.email?.toLowerCase() !== Values?.EMAIL_ID?.toLowerCase()){
        request.email= Values?.EMAIL_ID
      }
    }else {
        request = {
          "name": Values?.FULL_NAME,
          "email": Values?.EMAIL_ID,
          "pincode": Values?.PINCODE_ID,
          "address": Values?.CURRENTADDRESS_ID,
          "date_of_birth": reqdate,
          "pan_number": Values?.PAN_ID,
          "user":restApiState?.newUserResponse?.user_id,
          "gender": Values?.GENDER && Values?.GENDER.toLowerCase() === "male" ?1: Values?.GENDER && Values?.GENDER.toLowerCase() === "female"?2:Values?.GENDER.toLowerCase() === "trans"?3:4 ,
       
      }
      };
      console.log("request:", request)
      ClipLoader(true)
      dispatch(createProfile({
        request: request, isIFAPortalUser: true,
        callback: (res) => {
          if (res && !res.isErrorOccure && !location?.state?.iseditProfile) {
            console.log('success response of createProfile:', res)
            ClipLoader(false)
            props.onNextStep()
          }else if (res && (res?.isProfileSaved || location?.state?.iseditProfile)) {
            ClipLoader(false)
            //show profile save popup
            dispatch(
              isPOPOpen({
                isOpen: true,
                btntxt: "OK",
                msg: `Profile Save Successfully`,
                title: "Profile",
                customImg: Images.profileSave,
                successHandler:()=>{
                  navigate(props?.navigateTo?props.navigateTo:portelRouteName.users)
                }
              })
            );
          } else if (res && res.isErrorOccure) {
            ClipLoader(false)
          } else {
            ClipLoader(false)
            console.log("res Error 123 ", res)
          }
        }
      }));


    }
  }
  const renderGenderTypeRadio = () => {
    let disabled = (restApiState?.fetchPortalUserProfileResponse && restApiState?.fetchPortalUserProfileResponse?.is_new_user)?false : globalState.fieldDisabled

    return [
      { value: 1, label: 'Male',disabled: disabled},
      { value: 2, label: 'Female', disabled: disabled },
      { value: 3, label: 'Other', disabled: disabled },

    ]
  }

  const renderInputFields = (props) => {
    return [
      new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.FULL_NAME,
        label: ReduxValues.userProfileInfoLabels.FULL_NAME,
        placeholder: ReduxValues.userProfileInfoPlaceholders.FULL_NAME,
        type: ReduxValues.inputFieldtype.text,
        inputLimit: 50,
        formType: FormTypes.OUTLINE_INPUT,
        isRequired: true,
        showBottomBorder: false,
        defaultvalue: 123,
        onBlur: onBlurHandler,
        customWidth: "50px",
        disabled:(restApiState?.fetchPortalUserProfileResponse && restApiState?.fetchPortalUserProfileResponse?.is_new_user)?false:globalState.fieldDisabled
      }),
        new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.DOB_ID,
        label: ReduxValues.userProfileInfoLabels.DOB_ID,
        placeholder:ReduxValues.userProfileInfoPlaceholders.DOB_ID,
        type: ReduxValues.inputFieldtype.date,
        inputLimit: 16,
        formType: FormTypes.DATE_INPUT,
        isRequired: true,
        showBottomBorder: false,
        disabled:(restApiState?.fetchPortalUserProfileResponse && restApiState?.fetchPortalUserProfileResponse?.is_new_user)?false:globalState.fieldDisabled
      }),
      new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.GENDER,
        label: ReduxValues.userProfileInfoLabels.GENDER,
        placeholder: ReduxValues.userProfileInfoPlaceholders.GENDER,
        type: ReduxValues.inputFieldtype.radio,
        inputLimit: 12,
        isRequired: true,
        showBottomBorder: false,
        radioButtonElements: renderGenderTypeRadio(),
        formType: FormTypes.RADIO_INPUT,
        inputValue: form && form.userPersonalDetailsForm && form.userPersonalDetailsForm['values'] && form.userPersonalDetailsForm['values']['GENDER'],
        isFromPortal: true,
        disabled:(restApiState?.fetchPortalUserProfileResponse && restApiState?.fetchPortalUserProfileResponse?.is_new_user)?false:globalState.fieldDisabled

      }),
      new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.EMAIL_ID,
        label: ReduxValues.userProfileInfoLabels.EMAIL_ID,
        placeholder: ReduxValues.userProfileInfoPlaceholders.EMAIL_ID,
        type: ReduxValues.inputFieldtype.email,
        inputLimit: 50,
        formType: FormTypes.OUTLINE_INPUT,
        isRequired: true,
        showBottomBorder: false,
        // disabled:globalState.fieldDisabled

      }),
      new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.PAN_ID,
        label: ReduxValues.userProfileInfoLabels.PAN_ID,
        placeholder: ReduxValues.userProfileInfoPlaceholders.PAN_ID,
        type: ReduxValues.inputFieldtype.pan,
        inputLimit: 10,
        formType: FormTypes.OUTLINE_INPUT,
        isRequired: true,
        showBottomBorder: false,
        disabled:(restApiState?.fetchPortalUserProfileResponse && restApiState?.fetchPortalUserProfileResponse?.is_new_user)?false:globalState.fieldDisabled
      }),

      new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.CURRENTADDRESS_ID,
        label: ReduxValues.userProfileInfoLabels.CURRENTADDRESS_ID,
        placeholder: ReduxValues.userProfileInfoPlaceholders.CURRENTADDRESS_ID,
        type: ReduxValues.inputFieldtype.address,
        inputLimit: 80,
        formType: FormTypes.OUTLINE_INPUT,
        isRequired: true,
        showBottomBorder: false
      }),
      new FormItem({
        fieldName: ReduxValues.userPersonalDetailsFields.PINCODE_ID,
        label: ReduxValues.userProfileInfoLabels.PINCODE_ID,
        placeholder: ReduxValues.userProfileInfoPlaceholders.PINCODE_ID,
        type: ReduxValues.inputFieldtype.tel,
        inputLimit: 6,
        formType: FormTypes.NUMBER_INPUT,
        isRequired: true,
        showBottomBorder: false,
      })

    ]
  }

  return (
    <>
        <div class="col-7 col-xs-12">
            <div style={{backgroundColor:'#F7F9FB',borderRadius:8, padding:20,  display:'flex',flexDirection:'column'}}>
            <div style={{backgroundColor:'#ffffff', borderRadius:8, flex:1,  display:'flex',flexDirection:'column', padding:10}}>
              <div style={{display:'flex',flex:'row',justifyContent:'space-between',alignItems:'center',padding:10}}>
                <span className={Styles.cardHead}>Personal Details </span>
                   <div>
                   {renderStepper(globalState,[2,3,4])}
                  </div>
                 </div>

              {renderFields(renderInputFields(), props, dispatch, formState)}

      <div style={{marginTop:'auto', marginBottom:'10px'}}>
        {BaseScreen.renderFooter({
          showLeftBtn: false,
          leftBtnHeight: 44,
          leftBtnWidth: 44,
          leftBtnBorderColor: "2px solid black",
          leftBtnBgColor: " #FFFFFF",
          showRightBtn: true,
          rightBtnHeight: 44,
          rightBtnWidth: "100%",
          rightBtnBorder: "0px",
          textColor: "#FFFFFF",
          title: Strings.documentUpload.submit,
          showRightBtnArrow: true,
          ShowLeftBtnArrow: true,
          rightBtnBgColor: Colors.secondaryprimary,
          // disabled: restApiState.fetchProfileResponse && restApiState.fetchProfileResponse.is_profile_completed?true:false,
          // onLeftBtnClicked: leftBtnHandler,
          onRightBtnClicked: formSubmitHandler,
        })}
      </div>
      </div>
      
      </div>  
      </div>
    </>
  )
}
const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps
}

export default connect(mapStateToProps, null)(reduxForm({
  form: "userPersonalDetailsForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,

})(UserPersonalDetails));


