const _0x20e229 = _0x41a7;
(function (_0x5c6f40, _0x101c27) {
    const _0x1886b6 = _0x41a7, _0x37976c = _0x5c6f40();
    while (!![]) {
        try {
            const _0x526c55 = -parseInt(_0x1886b6(0x212)) / (0xb * 0x2a4 + -0x1e55 * -0x1 + -0x3b60) * (-parseInt(_0x1886b6(0x1d4)) / (0x275 + 0x1fb1 + -0x4c * 0x73)) + -parseInt(_0x1886b6(0x1e2)) / (-0x1a05 * 0x1 + 0x1a4b + 0x43 * -0x1) * (-parseInt(_0x1886b6(0x1fc)) / (0x1 * 0x22a9 + -0x1 * -0xf0b + 0x9f0 * -0x5)) + -parseInt(_0x1886b6(0x209)) / (-0x1881 + 0x75 * -0x2c + -0x1651 * -0x2) + parseInt(_0x1886b6(0x1c5)) / (0x16f3 + 0x1f1 + 0x6 * -0x425) + parseInt(_0x1886b6(0x22b)) / (-0x19ab + 0xcd * -0x4 + -0x1b * -0x112) * (-parseInt(_0x1886b6(0x201)) / (0x989 * 0x3 + 0x256f * 0x1 + -0x4202)) + -parseInt(_0x1886b6(0x22e)) / (-0x1cd1 + -0x2 * 0xa2b + 0x313 * 0x10) + parseInt(_0x1886b6(0x237)) / (0x4d * 0x75 + -0x19 * -0x103 + -0x3c72);
            if (_0x526c55 === _0x101c27)
                break;
            else
                _0x37976c['push'](_0x37976c['shift']());
        } catch (_0x2714cb) {
            _0x37976c['push'](_0x37976c['shift']());
        }
    }
}(_0x5c33, 0xda16d + -0x62f3 * 0x27 + 0xd5536));
function _0x41a7(_0x472f10, _0x588717) {
    const _0x5742f9 = _0x5c33();
    return _0x41a7 = function (_0x47f824, _0x4f146b) {
        _0x47f824 = _0x47f824 - (0x3a * 0x4f + -0x2 * -0xdd3 + -0x2bc8);
        let _0x5eb425 = _0x5742f9[_0x47f824];
        return _0x5eb425;
    }, _0x41a7(_0x472f10, _0x588717);
}
function _0x5c33() {
    const _0x275f70 = [
        'ist',
        '52a-10581b',
        'ateway.cas',
        '123AEwQnv',
        'le/doc-fet',
        '/payments/',
        '_event/',
        '/enterpris',
        'd/getUserR',
        'https://qa',
        'e/dashboar',
        '/user/deac',
        'stment-req',
        'rrer_code/',
        'he.co.in',
        'le/state-c',
        'https://b2',
        'be.13karat',
        'ity-list',
        'capi.cashe',
        '/util/faq/',
        'h-doc/',
        'e0235414-4',
        '_segment/',
        '3000',
        'le/bank-if',
        'le/doc/',
        'le/banks-l',
        '/core/prem',
        '149916NPEhEH',
        'a-download',
        'le/networt',
        'ze/',
        '/campaign',
        '174992yEjsOQ',
        'payment-st',
        'le/update-',
        '.in',
        'a2c-4214-8',
        'stor-portf',
        'he.co.in/',
        '/flyy/user',
        '7475015kOAgiK',
        '66a-48f3-a',
        'ionReport',
        '/o/authori',
        'tivate/',
        '/core/inve',
        'le/nominee',
        'karat.in',
        'age-paymen',
        '311pytuFu',
        '/appsflyer',
        'sify_cust/',
        'drawal/',
        'd/transact',
        'profile',
        'pay-order/',
        'ature-with',
        'agebe.13ka',
        'ut/',
        '7cc057',
        'https://ua',
        'd.cashe.co',
        '0baa2073-3',
        'epdashboar',
        'vestment-p',
        'le/detail',
        'odbe.13kar',
        '/user/logo',
        'anch-ifsc',
        '/flyy/refe',
        '/userprofi',
        '/core/txn-',
        '58b60a',
        'eprodbe.13',
        '91xbIfea',
        'hboard.cas',
        'le/bank-ac',
        '13454541AQZQYj',
        '-agent.das',
        'olePermiss',
        'at.in',
        'rat.in',
        'atus/',
        '/util/medi',
        '_token/',
        '-details',
        '11421820UurUKd',
        'count',
        '/core/rein',
        '2092020lNFQuP',
        'le/invest-',
        'stats',
        'sc-code',
        'h-doc-fetc',
        '198-8cbb41',
        'le/consent',
        'tgateway.c',
        '/flyy/clas',
        'd/login',
        'localhost:',
        'reference',
        'uest/',
        'https://st',
        '/flyy/send',
        '6598mcRVMo',
        'ions',
        'olio/',
        '.co.in',
        'ashe.co.in',
        'https://pr',
        '/graphql',
        't-paymentg',
        'le/bank-br',
        '/o/token/',
        'history/'
    ];
    _0x5c33 = function () {
        return _0x275f70;
    };
    return _0x5c33();
}
const ApiConstants = {
    'localServerURL': _0x20e229(0x1e8) + _0x20e229(0x1f0) + _0x20e229(0x204),
    'qaURL': _0x20e229(0x1e8) + _0x20e229(0x1f0) + _0x20e229(0x204),
    'stageURL': _0x20e229(0x1d2) + _0x20e229(0x21a) + _0x20e229(0x232),
    'prodURL': _0x20e229(0x1d9) + _0x20e229(0x223) + _0x20e229(0x231),
    'preProdURL': _0x20e229(0x1d9) + _0x20e229(0x22a) + _0x20e229(0x210),
    'qaHostUrl': _0x20e229(0x1e8) + _0x20e229(0x22f) + _0x20e229(0x22c) + _0x20e229(0x207),
    'localHostUrl': _0x20e229(0x1cf) + _0x20e229(0x1f7),
    'baseURL': _0x20e229(0x1e8) + _0x20e229(0x220) + _0x20e229(0x21e) + _0x20e229(0x204),
    'login': _0x20e229(0x1e6) + _0x20e229(0x1e9) + _0x20e229(0x1ce),
    'getUserRolePermissions': _0x20e229(0x1e6) + _0x20e229(0x1e9) + _0x20e229(0x1e7) + _0x20e229(0x230) + _0x20e229(0x1d5),
    'transactionReport': _0x20e229(0x1e6) + _0x20e229(0x1e9) + _0x20e229(0x216) + _0x20e229(0x20b),
    'authToken': _0x20e229(0x20c) + _0x20e229(0x1ff),
    'authTokenApi': _0x20e229(0x1dd),
    'graphQlApi': _0x20e229(0x1da),
    'getPaymentGatewayUrl': _0x20e229(0x21d) + _0x20e229(0x1db) + _0x20e229(0x1e1) + _0x20e229(0x1ed),
    'getStagePaymentGatewayUrl': _0x20e229(0x1d2) + _0x20e229(0x211) + _0x20e229(0x1cc) + _0x20e229(0x1d8),
    'getPaymentGatewayProdUrl': _0x20e229(0x1ef) + _0x20e229(0x1f2) + _0x20e229(0x1d7),
    'userProfleDetailsApi': _0x20e229(0x227) + _0x20e229(0x222),
    'bankDataApi': _0x20e229(0x227) + _0x20e229(0x22d) + _0x20e229(0x238),
    'bankList': _0x20e229(0x227) + _0x20e229(0x1fa) + _0x20e229(0x1df),
    'userprofile': _0x20e229(0x227) + _0x20e229(0x1f9),
    'uploadDoc': _0x20e229(0x227) + _0x20e229(0x1f9),
    'getDoc': _0x20e229(0x227) + _0x20e229(0x1e3) + 'ch',
    'fkyUserToken': _0x20e229(0x208) + _0x20e229(0x235),
    'checkUserFly': _0x20e229(0x1d3) + _0x20e229(0x1e5),
    'FlyRefferalCode': _0x20e229(0x1cd) + _0x20e229(0x214),
    'user_segment': _0x20e229(0x208) + _0x20e229(0x1f6),
    'verifyReferersCode': _0x20e229(0x226) + _0x20e229(0x1ec),
    'fetchPortfolio': _0x20e229(0x20e) + _0x20e229(0x206) + _0x20e229(0x1d6),
    'bankIfsccode': _0x20e229(0x227) + _0x20e229(0x1f8) + _0x20e229(0x1c8),
    'logout': _0x20e229(0x224) + _0x20e229(0x21b),
    'payOrder': _0x20e229(0x1e4) + _0x20e229(0x218),
    'createInvestment': _0x20e229(0x20e) + _0x20e229(0x1eb) + _0x20e229(0x1d1),
    'txnHistory': _0x20e229(0x228) + _0x20e229(0x1de),
    'paymentStatus': _0x20e229(0x1e4) + _0x20e229(0x202) + _0x20e229(0x233),
    'faqDataGet': _0x20e229(0x1f3),
    'autoInvest': _0x20e229(0x1c4) + _0x20e229(0x221) + _0x20e229(0x1d0),
    'AppsFlyer': _0x20e229(0x213) + _0x20e229(0x200),
    'GetStateList': _0x20e229(0x227) + _0x20e229(0x1ee) + _0x20e229(0x1f1),
    'GetCityList': _0x20e229(0x227) + _0x20e229(0x1ee) + _0x20e229(0x1f1),
    'GetBranchIfsc': _0x20e229(0x227) + _0x20e229(0x1dc) + _0x20e229(0x225),
    'updateProfile': _0x20e229(0x227) + _0x20e229(0x203) + _0x20e229(0x217),
    'logglyProdKey': _0x20e229(0x1f5) + _0x20e229(0x20a) + _0x20e229(0x1e0) + _0x20e229(0x229),
    'logglyDevKey': _0x20e229(0x21f) + _0x20e229(0x205) + _0x20e229(0x1ca) + _0x20e229(0x21c),
    'DeactivateAccount': _0x20e229(0x1ea) + _0x20e229(0x20d),
    'PdfCertificate': _0x20e229(0x234) + _0x20e229(0x1fd) + '/',
    'GetPreWithdrawal': _0x20e229(0x1fb) + _0x20e229(0x219) + _0x20e229(0x215),
    'fetchRelationDetails': _0x20e229(0x227) + _0x20e229(0x20f) + _0x20e229(0x236),
    'CycConsentApiUrl': _0x20e229(0x227) + _0x20e229(0x1cb),
    'CustomerNetWorthUrl': _0x20e229(0x227) + _0x20e229(0x1c6) + _0x20e229(0x1c7),
    'NetWorthDocUrl': _0x20e229(0x227) + _0x20e229(0x1fe) + _0x20e229(0x1f4),
    'NetWorthGetDocUrl': _0x20e229(0x227) + _0x20e229(0x1fe) + _0x20e229(0x1c9) + 'h'
};
export default ApiConstants;