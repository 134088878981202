import Strings from '../Constants/Strings'
import CASHeValidations from '../Utils/CasheValidator'


export const validate = values => {
  const errors = {}
  const  allkeys = Object.keys(values)
  for ( var index in allkeys) {//if any data null we stop the api calling
    const keyname =  allkeys[index];
    if (!CASHeValidations.validateAllTF(values[keyname])) {
      errors[keyname] = "Enter vaild details"
    }
  }

  // console.log(values)
  if (!values.LOGIN_MOBILE_NUMBER) {
    errors.LOGIN_MOBILE_NUMBER = Strings.Field_Error_Msgs.input_mobile
  } else if (!CASHeValidations.validateMobileNumber(values.LOGIN_MOBILE_NUMBER)) {
    errors.LOGIN_MOBILE_NUMBER = Strings.Field_Error_Msgs.input_mobile
  }

  if (!values.USER_MOBILE_NUMBER) {
    errors.USER_MOBILE_NUMBER = Strings.Field_Error_Msgs.input_mobile
  } else if (!CASHeValidations.validateMobileNumber(values.USER_MOBILE_NUMBER)) {
    errors.USER_MOBILE_NUMBER = Strings.Field_Error_Msgs.input_mobile
  }
  // profile info validations

  if (!values.EMAIL_ID) {
    errors.EMAIL_ID = "Enter valid Email id."
  } else if (!CASHeValidations.validateEmail(values.EMAIL_ID)) {
    errors.EMAIL_ID = "Enter valid Email id."
  }
  if (!values.NAME_ID) {
    errors.NAME_ID = "Enter valid Name."
  } else if (!CASHeValidations.validateName(values.NAME_ID)) {
    errors.NAME_ID = "Enter valid NAME"
  }
  if (!values.FULL_NAME) {
    errors.FULL_NAME = "Enter valid Name."
  } else if (!CASHeValidations.validateName(values.FULL_NAME)) {
    errors.FULL_NAME = "Enter valid NAME"
  }
  if (!values.LAST_NAME) {
    errors.LAST_NAME = "Enter valid Name."
  } else if (!CASHeValidations.validateName(values.LAST_NAME)) {
    errors.LAST_NAME = "Enter valid NAME"
  }
  if (!values.PAN_ID) {
    errors.PAN_ID = "Enter valid PAN."
  } else if (!CASHeValidations.validatePAN(values.PAN_ID)) {
    errors.PAN_ID = "Enter valid PAN"
  }
  if (!values.CURRENTADDRESS_ID) {
    errors.CURRENTADDRESS_ID = "Enter valid Address."
  } else if (!CASHeValidations.validateMerchantAddress(values.CURRENTADDRESS_ID)) {
    errors.CURRENTADDRESS_ID = "Enter valid Address"
  }
  if (!values.PINCODE_ID) {
    errors.PINCODE_ID = "Enter valid Pincode."
  } else if (!CASHeValidations.validatePincode(values.PINCODE_ID)) {
    errors.PINCODE_ID = "Enter valid Pincode"
  }
  if (!values.DOB_ID) {
    errors.DOB_ID = "Enter valid Date."
  } else if (!CASHeValidations.validateDOB(values.DOB_ID)) {
    errors.DOB_ID = "Enter valid Date"
  }
  if (!values.ACCOUNT_HOLDER_NAME_ID) {
    errors.ACCOUNT_HOLDER_NAME_ID = "Enter Valid Account Holder Name."
  } else if (!CASHeValidations.validateName(values.ACCOUNT_HOLDER_NAME_ID)) {
    errors.ACCOUNT_HOLDER_NAME_ID = "Enter Valid Account Holder Name"
  }
  
  if (!values.ACCOUNT_NO_ID) {
    errors.ACCOUNT_NO_ID = "Enter Valid Account No. "
  } else if (!CASHeValidations.validateBankAccountNumber(values.ACCOUNT_NO_ID)) {
    errors.ACCOUNT_NO_ID = "Enter Valid Account No."
  }

  if (!values.REENTER_ACCOUNT_NO_ID) {
    errors.REENTER_ACCOUNT_NO_ID = "Re-Enter Account No. "
  } else if (!CASHeValidations.validateReenterBankAccountNumber(values.ACCOUNT_NO_ID,values.REENTER_ACCOUNT_NO_ID)) {
    errors.REENTER_ACCOUNT_NO_ID = "Re-Enter Valid Account No."
  }

  if (!values.IFSC_CODE_ID) {
    errors.IFSC_CODE_ID = "Enter Valid IFSC Code. "
  } else if (!CASHeValidations.validateBankIFSCNumber(values.IFSC_CODE_ID)) {
    errors.IFSC_CODE_ID = "Enter Valid IFSC Code."
  }

  if (!values.ACCOUNT_TYPE_ID) {
    errors.ACCOUNT_TYPE_ID = "Select your account type."
  } else if (!CASHeValidations.validateAccountType(values.ACCOUNT_TYPE_ID)) {
    errors.ACCOUNT_TYPE_ID = "Select your account type."
  }

    if (!values.BANK_NAME_ID) {
    errors.BANK_NAME_ID = "Select your bank."
  } else if (!CASHeValidations.validateAccountType(values.BANK_NAME_ID)) {
    errors.BANK_NAME_ID = "Select your bank."
  }

  if(!values.GENDER){
    errors.GENDER = "Please select gender."
  } else if (!CASHeValidations.validateGender(values.GENDER)) {
    errors.GENDER = "Please select gender."
  }

  if(!values.EMPLOYMENT_TYPE_ID){
    errors.EMPLOYMENT_TYPE_ID = "Please select Employment type."
  } else if (!CASHeValidations.validateEmploymenType(values.EMPLOYMENT_TYPE_ID)) {
    errors.EMPLOYMENT_TYPE_ID ="Please select Employment type."
  }


  if(!values.ANNUAL_INCOME_ID){
    errors.ANNUAL_INCOME_ID = "Please select Employment type."
  } else if (!CASHeValidations.validateAnnualIncome(values.ANNUAL_INCOME_ID)) {
    errors.ANNUAL_INCOME_ID ="Please select Employment type."
  }
  if (!values.Bank_Name) {
    errors.Bank_Name = "Enter Valid Bank  Name."
  } else if (!CASHeValidations.validateName(values.Bank_Name)) {
    errors.Bank_Name = "Enter Valid Bank Name"
  }
  if (!values.State_Name) {
    errors.State_Name = "Enter Valid Bank State Name."
  } 
  // else if (!CASHeValidations.validateName(values.State_Name)) {
  //   errors.State_Name = "Enter Valid Bank State Name"
  // }
  if (!values.City_Name) {
    errors.City_Name = "Enter Valid City Name."
  } 
  // else if (!CASHeValidations.validateName(values.City_Name)) {
  //   errors.City_Name = "Enter Valid Bank City Name"
  // }
  if (!values.Branch_Name) {
    errors.Branch_Name = "Enter Valid Bank Branch Name."
  }
  //  else if (!CASHeValidations.validateAccountType(values.Branch_Name)) {
  //   errors.Branch_Name = "Enter Valid Bank Branch Name"
  // }



  if (!values.NOMINEE_NAME) {
    errors.NOMINEE_NAME = "Enter valid Name"
  } else if (!CASHeValidations.validateName(values.NOMINEE_NAME)) {
    errors.NOMINEE_NAME = "Enter valid Name"
  }
  if (!values.NOMINEE_NUMBER) {
    errors.NOMINEE_NUMBER = "Enter valid Phone Number"
  } else if (!CASHeValidations.validateMobileNumber(values.NOMINEE_NUMBER)) {
    errors.NOMINEE_NUMBER = "Enter valid Phone Number "
  }
  if (!values.NOMINEE_EMAIL) {
    errors.NOMINEE_EMAIL = "Enter valid E-mail id"
  } else if (!CASHeValidations.validateEmail(values.NOMINEE_EMAIL)) {
    errors.NOMINEE_EMAIL = "Enter valid E-mail id"
  }
  if (!values.NOMINEE_DOB) {
    errors.NOMINEE_DOB = "Enter valid Date"
  } else if (!CASHeValidations.validateDOB(values.NOMINEE_DOB)) {
    errors.NOMINEE_DOB = "Enter valid Date"
  }
  if (!values.NOMINEE_RELATION) {
    errors.NOMINEE_RELATION = "Select your Relation"
  } else if (!CASHeValidations.validateRelatioType(values.NOMINEE_RELATION)) {
    errors.NOMINEE_RELATION = "Select your Relation"
  }


  // if (!values.BANK_NAME_ID) {
  //   errors.BANK_NAME_ID = "Enter Valid Bank Name. "
  // } else if (!CASHeValidations.validateBankName(values.BANK_NAME_ID)) {
  //   errors.BANK_NAME_ID = "Enter Valid Bank Name."
  // }
  // if (!values.ACCOUNT_TYPE_ID) {
  //   errors.ACCOUNT_TYPE_ID = "Enter Valid Account Type. "
  // } else if (!CASHeValidations.validateName(values.ACCOUNT_TYPE_ID)) {
  //   errors.ACCOUNT_TYPE_ID = "Enter Valid Account Type"
  // }



  return errors
}




