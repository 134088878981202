import { display } from '@mui/system';
import React, { Component } from 'react'
import style from  "../Style/Portal/steper.css";

const Stepper = (props) => {
    return (
         <div className="container__item" >
             <div className="stepper" style={{ borderColor: props?.borderColor }}>   </div> 
      </div>
    );
  };

  export default Stepper;