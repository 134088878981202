import React from "react";
import HeaderComponent from "./Components/OnboardingComponents/Onboarding_Header";
const PowerByRC = React.lazy(() => import("./Components/OnboardingComponents/PowerByRC"));
const Footer = React.lazy(() => import("./Components/OnboardingComponents/Footer"));
const LoginBottomButton = React.lazy(()=> import('./Components/OnboardingComponents/LoginBottomButton'));
const CommonModal = React.lazy(() => import('./Components/OnboardingComponents/CommonModal'))

export const renderHeader=({title,goBack,showLogo=true,showTitle=true,currentPage,index,visitedPageIndex,visitedPage,showBackIcon,showNavtab=true,iscustomHeader=false,reqShadow=false,rightLogo,showBackText=false,showTitleCenter=false,showLogoRight=false,headerIconImage,headerRightImgWidth,headerRightImgHeight,imageClickNavigation,rightText,showRightText,rightTextEvent,rightTextColor,backgroundColor,showBackTextColor,zIndex})=>{
    return <HeaderComponent title={title} showBackIcon={showBackIcon} goBack={goBack} showLogo={showLogo}  showTitle={showTitle} currentPage={currentPage} index={index} visitedPageIndex={visitedPageIndex} visitedPage={visitedPage} showNavtab={showNavtab} iscustomHeader={iscustomHeader} reqShadow={reqShadow} rightLogo={rightLogo} showLogoRight={showLogoRight} showBackText={showBackText} showTitleCenter={showTitleCenter} headerIconImage={headerIconImage} headerRightImgWidth={headerRightImgWidth} headerRightImgHeight={headerRightImgHeight} imageClickNavigation={imageClickNavigation}
    rightText={rightText}
    zIndex={zIndex}
    showRightText={showRightText}
    rightTextEvent={rightTextEvent}
    rightTextColor={rightTextColor}
    backgroundColor={backgroundColor}
    showBackTextColor={showBackTextColor}
    />
}

export const renderBottomLine=()=>{
  return       <div style={{width:"100%",background: '#F7F7F7',height:4,marginBottom:8,marginTop:2}}></div>

}

export const renderRupeeCircleFooter=()=>{
    return <PowerByRC/>
}

export const renderLoginBottomButton=()=>{
    return <LoginBottomButton/>
}

export const renderFooter = (
  {
    disabledLeftBtn,
    TopText,
    showLeftBtn,
    iswhitebtn,
  leftBtnHeight,
  leftBtnWidth,
  leftBtnBorderColor,
  leftBtnBgColor,
  showRightBtn,
  rightBtnHeight,
  rightBtnWidth,
  rightBtnBgColor,
  rightBtnBorder,
  textColor,
  title,
  showRightBtnArrow,
  rightBtnGradientColor,
  leftBtnGradientColor,
  disabled,
  onLeftBtnClicked,
  showInvestMoreIcon,
  zIndex,
  showAddUserIcon,
  gap,
  onRightBtnClicked,ShowLeftBtnArrow,ShowLeftBtnText,leftBtntextColor,leftBtnTxtFontFamily,leftBtnTextFontsize,leftBtnText,rightBtnTextFontFamily,rightBtnFontSize,footerStyles,customLeftBtnStyle,customRightBtnStyle}
) => {
  return (
    <Footer
    customLeftBtnStyle={customLeftBtnStyle}
    customRightBtnStyle={customRightBtnStyle}
    TopText={TopText}
    zIndex={zIndex}
    disabledLeftBtn={disabledLeftBtn}
    iswhitebtn={iswhitebtn}
      disabled={disabled}
      leftBtnGradientColor={leftBtnGradientColor}
      rightBtnGradientColor={rightBtnGradientColor}
      showLeftBtn={showLeftBtn}
      leftBtnHeight={leftBtnHeight}
      leftBtnWidth={leftBtnWidth}
      leftBtnBorderColor={leftBtnBorderColor}
      leftBtnBgColor={leftBtnBgColor}
      showRightBtn={showRightBtn}
      rightBtnHeight={rightBtnHeight}
      rightBtnWidth={rightBtnWidth}
      rightBtnBgColor={rightBtnBgColor}
      rightBtnBorder={rightBtnBorder}
      textColor={textColor}
      
      title={title}
      showRightBtnArrow={showRightBtnArrow}
      onLeftBtnClicked={onLeftBtnClicked}
      onRightBtnClicked={onRightBtnClicked}
      ShowLeftBtnArrow={ShowLeftBtnArrow}
      ShowLeftBtnText={ShowLeftBtnText}
      leftBtntextColor={leftBtntextColor}
      leftBtnTxtFontFamily={leftBtnTxtFontFamily}
      leftBtnTextFontsize={leftBtnTextFontsize}
      leftBtnText={leftBtnText}
      rightBtnTextFontFamily={rightBtnTextFontFamily}
      rightBtnFontSize={rightBtnFontSize}
      footerStyles={footerStyles}
      showInvestMoreIcon={showInvestMoreIcon}
      gap={gap}
      showAddUserIcon={showAddUserIcon}
      // disabled={true}
      

    />
  );
};

export const renderCommonModal =({open,onClose,headerStyles,headerText,headerTextStyles,headerImageStyles,headerImage,ShowHeaderText,ShowHeaderImg})=>{
  return <CommonModal  open ={open} onClose={onClose} headerStyles={headerStyles} headerText={headerText} headerTextStyles={headerTextStyles} headerImage={headerImage} headerImageStyles={headerImageStyles} ShowHeaderText={ShowHeaderText} ShowHeaderImg={ShowHeaderImg}/>
}




const fileToImageurl=async(file)=>{
  let fileReader=new FileReader();
  fileReader.onload=()=>{
      // this.setState({imgUrl:fileReader.result})
  };
  fileReader.readAsDataURL(file)
};
