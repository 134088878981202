import Dashboard_Icon from "./DashboardIcon.svg";
import FAQs_Icon from './FAQsIcon.svg';
import FundMgmt_Icon from "./FundMgmtIcon.svg";
import Transactions_Icon from './TransactionsIcon.svg';
import Users_Icon from './UsersIcon.svg';
import Portal_left_logo from './Portal_left_logo.svg'
import tableOptions from './tableOptions.svg'
import bellIcon from "./bellIcon.svg"
import NoTableToDisplay from "./NoTableToDisplay.svg"
import authorisation from "./authorisation.svg"
import whatsappIcon from "./whatsappIcon.svg";
import NoTxnTableToDisplay from "./NoTxnTableToDisplay.svg"
import belImage from "./belImage.svg"
import pending from "./Pending.png"
import success from "./Success.png"
import failed from "./Failed.png"
import whatsappImagePortal from "./whatsappImagePortal.svg"
import netWorthUpload from './netWorthUpload.svg'

const PortalImages = {
  Dashboard_Icon,
  FAQs_Icon,
  FundMgmt_Icon,
  Transactions_Icon,
  Users_Icon,
  Portal_left_logo,
  tableOptions,
  bellIcon,
  NoTableToDisplay,
  NoTxnTableToDisplay,
  authorisation,
  whatsappIcon,
  belImage,
  pending,
  success,
  failed,
  whatsappImagePortal,
  netWorthUpload
}


export default PortalImages;
