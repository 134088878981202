import React, { Component, useEffect,useState } from 'react'
import * as BaseScreen from "../../BaseScreen";
import Images from "../../Images/Images"
import Colors from "../../Constants/Colors";
import clasess from '../../Style/DashboardStyle.module.css'
import Style from "../../Style/LoginStyle.module.css";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from '../../Components/OnboardingComponents/Button';
import {fetchUserdata} from '../../Utils/ApiControl'
import { connect, useDispatch, useSelector } from "react-redux";
import { renderFields } from '../../REDUXFORM/ReduxFrom';
import { reduxForm } from 'redux-form';
import ReduxValues from '../../Store/ReduxValues';
import { FormItem } from '../../REDUXFORM/InputField';
import Strings from '../../Constants/Strings';
import { validate } from '../../REDUXFORM/Validate';
import { otp_trigger, forgot_password_otp_verify,fetchProfile } from '../../Store/Actions/RestAPiActions';
import { isPOPOpen, isLoading } from '../../Store/Actions/GlobalActions'
import CancelIcon from '@mui/icons-material/Cancel';
import { portelRouteName, routeName } from '../../Routers/Router';
import OtpInput from 'react-otp-input';
import ForgetPassword2 from "./ForgetPassword2";



let FormTypes = Strings.FormTypes

const ForgetPassword1 = (props) => {

  const navigate = useNavigate();
  const formState = useSelector((state) => state.formState);
  const globalState = useSelector(state => state.globalState)

  const dispatch = useDispatch();
  const [isOtpButtonClicked, setisOtpButtonClicked] = useState(false);
  const [enteredMobNumber,setenteredMobNumber]=useState('');
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOTPMessage, setErrorOTPMessage] = useState("");
  const [showCrossICon, setshowCrossICon] = useState(false);
  const [isgetOTPBtnDisable,setisgetOTPBtnDisable] = useState(true)
  const [showCrossIConOTP, setshowCrossIConOTP] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(true);
  const [isSendOTPBtnDisable,setisSendOTPBtnDisable]=useState(true)
  const [isSendOTPButtonClicked,setisSendOTPButtonClicked]=useState(false);
  // const [showErossIConOTP, seterrCrossOtp] = useState(false);
  const restApiState = useSelector(state => state.restApiState)
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(false);

  const ClipLoader = (flag) => {
    dispatch(isLoading(flag));
  };
  

  useEffect(() => {
    const handleOtpPress = (e) => {
      if (e.key === 'Enter' && code.length === 4 && code !== "") {
        goToOnboardingStepsPage(e);
      }
    };

    document.addEventListener('keydown', handleOtpPress);

    return () => {
      document.removeEventListener('keydown', handleOtpPress);
    };
  }, [code]);

  const fetchLoginDataHandler = () => {

    triggerOTPCode({ "mobile_no": enteredMobNumber });
    setCode('');
    setErrorOTPMessage('');
    setshowCrossIConOTP('');
  }

  const goToOnboardingStepsPage = (e) => {
    e.preventDefault(); // Prevent form submission
    let request = {
      "mobile_no": enteredMobNumber,
      "otp": code
    };
    if(code.length===4){
      verifyOTPCode(request);
    }
 
  }

  function triggerOTPCode(payload) {
    ClipLoader(true);
    dispatch(otp_trigger({
      request: payload,
      callback: (res) => {
        if (res && (res?.mobile_no || res?.mobile)) {
          setisOtpButtonClicked(true);
          setisgetOTPBtnDisable(true);
          setisSendOTPButtonClicked(true);
          setisSendOTPBtnDisable(true);
          ClipLoader(false);
        }
        if (res && res.isErrorOccure) {
          alert(res.status);
          ClipLoader(false);
        }
      }
    }));
  }

  
  function verifyOTPCode(payload) {
    ClipLoader(true)
    dispatch(forgot_password_otp_verify({
      request: payload,
      callback: (res) => {
        if(res && res.access_token) {
        console.log("Entered Mobile Number and OTP", res);
        setLoginResponse(res);
        setShowForgotPassword(true)
        } else {
          ClipLoader(false)
        }
      }
    }));
  }
 


const mobileNumberChangeHandler=(e)=>{
  console.log(e.target.value)
  let new_Number = e.target.value;

  setenteredMobNumber(new_Number)
  if (!new_Number.match("^[6-9][0-9]{9}$")) {
    setshowCrossICon(true)
    setErrorMessage("Enter 10 digit mobile number.");
    setisgetOTPBtnDisable(true)
    setisSendOTPBtnDisable(true)


  } else {
    setshowCrossICon(false)
    setErrorMessage("");
    setisgetOTPBtnDisable(false)
    setisSendOTPBtnDisable(false)

  }
}

const otpHandler=(OTP)=>{

  // console.log(e.target.value,'OTP Value')
  let new_OTP = OTP;
  setCode(new_OTP)

  if (!new_OTP.match("^[0-9]{4}$")) {
    setshowCrossIConOTP(true)
    setErrorOTPMessage("Enter 4 digit OTP.");
    setisgetOTPBtnDisable(true)
    setisSendOTPBtnDisable(true)


  } else {
    setshowCrossIConOTP(false)
    setErrorOTPMessage("");
    setisgetOTPBtnDisable(false)
    setisSendOTPBtnDisable(false)

  }
}

const editButton = () => {
  setisOtpButtonClicked(false)
  setisSendOTPButtonClicked(false)
  setCode('')
}


const renderOTPField=()=>{
  return (
         <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>             
           <OtpInput
             value={code}
             inputType="tel"
             onChange={otpHandler}
             numInputs={4}
             autoFocus
             renderSeparator={<span style={{margin:16}}></span>}
             renderInput={(props) => <input {...props} />}
            //  containerStyle={{border:'2px solid green'}}
             inputStyle={{width:50,height:50,border:showCrossIConOTP?'1px solid red':'1px solid black',borderRadius:4}}
           />
         </div>
  )
}

const handleKeyPress = (event) => {

  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
  if (event.key === 'Enter' && errorMessage == "" && enteredMobNumber!="") {
    fetchLoginDataHandler();
  }
 
};


//Code for Desktop View
const renderDesktopComponent = () => {
  return (
    <div className='divWidth'>
      <div style={{ display: 'flex', height: '100vh' }}>
        <div style={{ width: '30%', backgroundImage: `url(${Images.backgroundOnboarding})`, display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100%' }}>
              <img style={{ position: 'fixed', top: 10, left: 10, right: 100, width: '10%' }} src={Images.karatHeaderLogoForLogin} alt="Karat Header Logo" />
        </div>
        <div style={{ width: '70%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding:10 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', maxWidth: '600px' }}>
              {!isOtpButtonClicked  && (
                <div style={{width:400}} className="full-width-container">
                  <form  onSubmit={goToOnboardingStepsPage}>
                    <div className={Style.WelcomeParent}>
                    {/* <img style={{ padding: '8px', width: '100%' }} src={Images.frame} alt="Frame" /> */}
                    <div className={Style.SignUpText}>
                      <h4 style={{ fontSize: '28px', lineHeight: '42px', textAlign: 'center', color: '#333333', margin: '0' ,fontFamily: 'Gilroy-Bold', fontWeight: 700}}>Forgot Password</h4>
                      <p style={{ fontFamily: 'Gilroy', fontWeight: 'normal', fontSize: '17px', lineHeight: '21px', textAlign: 'center', color: '#666666', marginTop: '5px' ,fontFamily: 'Gilroy-Medium'}}>{isLoginClicked ? "Please enter your registered mobile number." : "Signup to 13 Karat" }</p>
                    </div>
                  </div>
                  <div style={{ width: '100%', marginBottom: 24, textAlign: 'start' }}>
                    <p style={{ fontWeight:500, fontFamily: 'Gilroy-Medium', fontSize: '14px', color: '#333333',fontFamily: 'Gilroy-Medium',marginBottom:1}}>Mobile Number</p>
                    <input onKeyPress={handleKeyPress} type="tel" className={Style.mobInput} value={enteredMobNumber} disabled={isOtpButtonClicked} style={{ fontFamily: 'Gilroy-Regular',width: '100%', height: '44px', padding: '5px', borderRadius: '3px', border: '1px solid #666666', textAlign: 'start', maxWidth: '540px' ,fontFamily: 'Gilroy-Regular'}} onChange={mobileNumberChangeHandler} maxLength="10" pattern="\d{10}" placeholder="Enter Mobile Number" />
                    <div style={{ textAlign: 'left' }}>
                    {showCrossICon ? <CancelIcon style={{ color: 'red', width: 20, height: 20 }} /> : null}
                    <span style={{ fontSize: '13px', color: '#f44336',fontFamily: 'Gilroy-Regular' }}> {errorMessage} </span>
                  </div>
                  </div>
                  
                  <div style={{ textAlign: 'center', marginBottom: 40 }}>  
                  <Button type='submit' variant="contained" style={isgetOTPBtnDisable ? { boxShadow: "none", textTransform: 'none', width: '100%', maxWidth: '540px', backgroundColor: '#ccc', color: '#FFFFFF', fontSize: 17, fontFamily: 'Gilroy-SemiBold' } : { boxShadow: "none", textTransform: 'none', width: '100%', maxWidth: '540px', backgroundColor: '#2D2F51', color: '#FFFFFF', fontSize: 17, fontFamily: 'Gilroy-SemiBold' }} 
                  onClick={fetchLoginDataHandler} title={isLoginClicked? "Send OTP" : "Get OTP"} disabled={isgetOTPBtnDisable}><strong>{isLoginClicked? "Send OTP" : "Get OTP"}</strong></Button>
                  </div>
                  </form>
                
                </div>
              )}
              {isOtpButtonClicked && (
                <div>
                  <h2 style={{ fontFamily: 'Gilroy-Bold', fontWeight: '700', fontSize: '28px', lineHeight: '42px', color: '#333333', marginBottom: '10px' }}>Enter OTP</h2>
                  <p style={{ fontFamily: 'Gilroy-Regular', fontWeight: '400', fontSize: '17px', lineHeight: '26px', color: '#666666', margin: '0' }}>An OTP has been sent to +91 {restApiState?.mobileNo?.mobile_no}</p>
                  <div style={{ marginTop: '20px' }}>
                    {renderOTPField()}
                    <div style={{ textAlign: 'left' ,margin:'2vh'}}>
                      {showCrossIConOTP &&
                        <CancelIcon style={{ color: 'red', width: 20, height: 20,marginLeft:'18px'}} />
                      }
                      <span style={{ fontSize: '13px', color: '#f44336'}}> {errorOTPMessage} </span>
                    </div>
                      <div style={{ marginTop: '10px' }}>
                      <Button variant="contained" style={isgetOTPBtnDisable ? {boxShadow:"none", textTransform: 'none',width:400 , backgroundColor:'#ccc',color:'#FFFFFF',fontSize:17,fontFamily:'Gilroy-SemiBold'} : {boxShadow:"none", textTransform: 'none',width:400,backgroundColor:'#2D2F51',color:'#FFFFFF',fontSize:17,fontFamily:'Gilroy-SemiBold'}} onClick={ goToOnboardingStepsPage} title={isOtpButtonClicked ? 'Submit' : 'Get OTP'} disabled={isgetOTPBtnDisable}></Button>
                      <div style={{ marginTop: '10px', textAlign: 'center' }}>
                      <span style={{ fontFamily: 'Gilroy-Regular', fontWeight: '400', fontSize: '17px', color: '#333' }}>Haven't received it? </span>
                      <span style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '17px',fontWeight: '600', color: '#3A40A1', cursor: 'pointer' }} onClick={fetchLoginDataHandler}>Resend OTP</span>
                    </div>
                  </div>
                </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

return (
  <>
    {!showForgotPassword ? renderDesktopComponent() : <ForgetPassword2 
    loginResponse ={loginResponse}
    />}
  </>
);
}

export default ForgetPassword1;