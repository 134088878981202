import new13kLogo from "./New13kLogo.svg";
import karatHeaderLogo from './header_logo.svg';
import WelcomeLatestText from "./WelcomeLatestText.svg";
import ic_login_bg from './ic_login_bg.png';
import lendbox from "./lendbox.svg"
import karatHeaderLogoForLogin from './Portal_left_logo.svg';
import icon from './Icon.svg';
import illustration from './Illustration.svg';
import papers from './Papers.svg';
import frame from './Frame.svg';
import NewLoaderImage from "./NewLoader.gif";
import WhatsappImg from "./whatsapp.svg"
import IconDashboard from "./IconDashboard.png"
import IllustrationDashboard from "./IllustrationDashboard.png"
import EsignFailed from "./Esign_Failed.png"
import HappyOnboarding from "./Happy_Onboarding.svg"
import buttonEdit from "./buttonEdit.svg"
import dashboardProfileImg from './dashboardProfileImg.png';
import EditLogo from './Editlogo.png';
import ExclLogo from './Excl.png'
import closeLogo from './close.png'
import UnderMaintaince from "./UnderMaintaince.gif"

  // kyc doc images 
import profileSave from './profileSave.jpeg'
import AddharSuccessNew from './AddharSuccessNew.png'
import IconsInfo from "./IconsInfo.svg"
import uploadIcon from './uploadIcon.png';
import profile from './profile.png';
import profile_female from './profile_female.png';
import ProfileBg from "./ProfileBg.png";
import UserProfile from "./UserProfile.png"
import pancardPopup from './pancardPopup.png'
import investmentSuccess from "./investmentSuccess.svg";
import IllustrationEsign from "./Illustration_Esign.png";
import addUserIcon from "./addUserIcon.svg"
// new error DesignPopUp
import ErrorDesignPopUp from './SomethingWentWrong.png'
import SuccessPanCardNew from "./SuccessPanCardNew.png"
import PanCardRegistered from "./PanCardRegistered.png"
import AadharAlreadyRegistered from "./AadharAlreadyRegistered.png"


// Bank info page image
import HDFC_LOGO from './HDFC_LOGO.png';
import ICICI_LOGO from './ICICI_LOGO.png';
import AXIS_LOGO from './AXIS_LOGO.png';
import SBI_LOGO from './SBI_LOGO.png';

// profile page images
import ProfileIconfirst from "./ProfileIconfirst.svg";
import DocumentNewIconLatest from "./DocumentNewIconLatest.svg";
import BankNewIcon from "./BankNewIcon.svg";
import ProfileIconTransactionSecond from "./ProfileIconTransactionSecond.svg";
import PrifileIconHelpSupportThird from "./PrifileIconHelpSupportThird.svg";
import PrifileIconAbout13Kforth from "./PrifileIconAbout13Kforth.svg";
import logOutImg from './LogOutImg.png';
import SuccessNewWithdraw from "./SuccessNewWithdraw.png";
import whatsappIcon from "./Portal_Images/whatsappIcon.svg";
import DocInvalidImage from "./Portal_Images/DocInvalidImage.png";
import Content from "./Portal_Images/Content.png"
import bellIcon from "./Portal_Images/bellIcon.svg"
import editProfile from "./Edit.png"
import liveFrame from "./Frame.png"
import TxnNotFound from "./TxnNotFound.png";
import KaratLogo from "./13KaratLogo_Header.png";
import bulbOn from './bulbOn.png'
import backgroundOnboarding from './backgroundOnboarding.svg'

// inconvenience pop up image
import inconvenience from "./inconvenience.png"
import inconvenienceAlert from "./inconvenienceAlert.png"
import Illustration from "./Illustration.png"
//docupload info images
import uploadPanInfo from "./uploadPanInfo.png"
import uploadPanInfosvg from './uploadPanInfosvg.svg'
import uploadAadharInfosvg from './uploadAadharInfosvg.svg'

// Nominee Registration images
import UserPlus from "./Portal_Images/UserPlus.png";
import UserPlusWhite from "./Portal_Images/UserPlusWhite.png";
import userplusyellow from "./Portal_Images//userplusyellow.png"
import LeftHeadArrow from "./Portal_Images/LeftHeadArrow.png";
import NomineeConfirm from "./Portal_Images/NomineeConfirm.png";
import DeactivateAccount from "./Portal_Images/DeactivateAccount.png";





const Images = {
  uploadAadharInfosvg,
  uploadPanInfosvg,
  uploadCommonInfosvg:uploadPanInfosvg,
  uploadPanInfo,
  bulbOn,
  KaratLogo,
  new13kLogo,
  WelcomeLatestText,
  ic_login_bg,
  lendbox,
  karatHeaderLogo,
  icon,
  illustration,
  papers,
  frame,
  NewLoaderImage,
  WhatsappImg,
  IllustrationDashboard,
  IconDashboard,
  karatHeaderLogoForLogin,
  EsignFailed,
  IllustrationEsign,
  HappyOnboarding,
  dashboardProfileImg,
  EditLogo,
  ExclLogo,
  closeLogo,
  UnderMaintaince,
  
  // kyc doc images 
  UserProfile,
  profile_female,
  ProfileBg,
  profile,
   profileSave,
   IconsInfo,
   uploadIcon,
   pancardPopup,
   investmentSuccess,
   buttonEdit,
     //errorpop design image
  ErrorDesignPopUp,
  AddharSuccessNew,
  SuccessPanCardNew,
  PanCardRegistered,
  AadharAlreadyRegistered,
  addUserIcon,
  // BankPage images
  HDFC_LOGO,
  ICICI_LOGO,
  AXIS_LOGO,
  SBI_LOGO,

  // profile page images
  ProfileIconfirst,
  DocumentNewIconLatest,
  BankNewIcon,
  ProfileIconTransactionSecond,
  PrifileIconHelpSupportThird,
  PrifileIconAbout13Kforth,
  logOutImg,
  SuccessNewWithdraw,
  editProfile,



  whatsappIcon,
  DocInvalidImage,
  Content,
  bellIcon,
  liveFrame,

  TxnNotFound,
  backgroundOnboarding,
  inconvenience,
  inconvenienceAlert,
  Illustration,

  // Nominee Registration images

  UserPlus,
  UserPlusWhite,
  userplusyellow,
  LeftHeadArrow,
  NomineeConfirm,
  DeactivateAccount,
}


export default Images;
