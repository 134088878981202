import React, { Component } from 'react'
import Style from '../../Style/Style.module.css'
import { useSelector, useDispatch } from 'react-redux'
import ImagesPath from '../../Images/Images'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as BaseScreen from '../../BaseScreen';
// import { isPOPOpen ,setIRCTCmin,setIRCTCsec} from '../Store/Actions/GlobalActions'

const HeaderBar =(props)=>  {
    const globalState = useSelector(state => state.globalState)
    const restApiState = useSelector(state => state.restApiState)

    const dispatch = useDispatch()
    return (
        // <div className={`container-fluid ${Style.Headermain}`} style={{ boxShadow:props.reqShadow ?'-2px -6px 10px 6px   rgb(206, 206, 206)':'0px 2px 0px 0px rgb(206, 206, 206)', height:props.headerHeight?props.headerHeight: 72, zIndex: 99999,width:"100%", background:props.backgroundColor?props.backgroundColor:"#FFF"}}>
         <div className={`container-fluid ${Style.Headermain}`} style={{ boxShadow:props.reqShadow ?props.reqShadow:'-2px -6px 10px 6px   rgb(206, 206, 206)', height:props.headerHeight?props.headerHeight: 72, Index:props.zIndex?props.zIndex: 99999,width:"100%", background:props.backgroundColor?props.backgroundColor:"#FFF"}}>


        <div style={{ width: '100%', height: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color:props.showBackTextColor?props.showBackTextColor:'#333'}} >

            {props.showBackIcon && <div className={Style.backIcon} style={{ marginTop:  0 }}>
                <ArrowBackIosIcon style={{ fontSize: 24 }} onClick={props.goBack} />
                {props.showBackText && <span style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '14px', cursor: 'pointer' }} onClick={props.goBack}>Back</span>}
            </div>}

            {props.showTitle && <div style={props.showTitleCenter ? { position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%,-50%)', fontFamily: 'Gilroy-SemiBold', fontSize: '17px' } : { marginRight: 8, fontFamily: 'Gilroy-SemiBold',}}>{props.title}</div>}

            {/* {props.showRightText && restApiState.fetchProfileResponse && restApiState.fetchProfileResponse.is_new_user === false && <span onClick={props.rightTextEvent?props.rightTextEvent:()=>{}} style={{ textAlign:'end' ,color:props.rightTextColor?props.rightTextColor:'black', fontFamily: 'Gilroy-SemiBold', fontSize: '16px'}}>{props.rightText}</span>} */}

            {props.showLogo && <div style={props.showLogoRight ? { position: 'absolute', top: '50%', right: '0%', transform: 'translate(-50%,-50%)' } : { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }} >
                <img src={props.headerIconImage} style={{ width: props.headerRightImgWidth, height:props.headerRightImgHeight}} onClick={props.imageClickNavigation} />
            </div>}

            {props.rightLogo && <div >
                {props.rightLogo}
            </div>}

        </div>

    </div>
    )
  }
export default HeaderBar;
