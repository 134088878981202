import React, { useState, useEffect, useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import { Global } from "@emotion/react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate, useLocation } from 'react-router-dom';
import PortalImages from "../../Images/Portal_Images/PortalImages";
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
// import { setIsLoggedIn, resetData } from '../ReduxStore/Actions/restApiActions'
import { reset, change } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { setShowSideTabDrawer,isPOPOpen, isLoading, setIsUserProfileCompleted, setShowCurrentTabName, setAddNewUserHandler, setStepCount, setFieldDisabled, setbreadcrumbsList,ResetCommonFilterData, setIsCurrentSelectedUserDataForModal, setAadharNumber } from '../../Store/Actions/GlobalActions'

import Colors from '../../Constants/Colors'
import Stack from "@mui/material/Stack";
import { tab } from '@testing-library/user-event/dist/tab';
import Styles from '../../Style/Portal/global.module.css'
import HeaderStyles from '../../Style/Portal/header.module.css'

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import { styled, useTheme } from '@mui/material/styles';
import CASHeUtils from '../../Utils/CASHeUtils';
import Values from '../../Constants/Values';
import { portelRouteName, routeName } from '../../Routers/Router'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DescriptionIcon from '@mui/icons-material/Description';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import { logoutUser } from '../../Store/Actions/RestAPiActions';
import ReduxValues from '../../Store/ReduxValues';



const drawerWidth = 270;
const TabIds = Values.sideBarTabsID;

export const TabNamesText = {
    Dashboard: "Dashboard",
    Users: "Users",
    Transaction: "Transactions",
    FundManagement: 'Fund Management',
    Notifications: "Notifications",
    FAQs: "FAQs"
}
const SideBar = (props) => {
    const navigate = useNavigate();
    const globalState = useSelector(state => state.globalState);
    const dispatch = useDispatch();
    const ClipLoader = (flag) => {
         dispatch(isLoading(flag));
    };

    const restApiState = useSelector(state => state.restApiState);
    const [reportButtonClicked, setReportButtonClicked] = useState(false)
    const [getWidthOfScreen, setgetWidthOfScreen] = useState(window.innerWidth)
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const tabs = [
        {
            id: TabIds.Dashboard,
            tabName: 'Dashboard',
            tabImage: <GridViewSharpIcon />
        },
        {
            id: TabIds.Users,
            tabName: 'Users',
            tabImage: <PeopleSharpIcon />
        },

        {
            id: TabIds.Transactions,
            tabName: 'Transactions',
            tabImage: <ReceiptIcon />
        },
        // {
        //     id: TabIds.FundManagement,
        //     tabName: 'Fund Management',
        //     tabImage: <DescriptionIcon />
        // },
        {
            id: TabIds.Notifications,
            tabName: 'Notifications',
            tabImage: <NotificationsIcon />
        },
        {
            id: TabIds.Faqs,
            tabName: 'FAQs',
            tabImage: <HelpOutlinedIcon />
        }
    ]


    const onChangeHandler = (id, tabName) => {
        // if (id != TabIds.Reports && id != TabIds.Operations) {
        localStorage.setItem("currentTabName", tabName)
        dispatch(setShowCurrentTabName(tabName))
        // }
        dispatch(setIsUserProfileCompleted(false))

        if (id !== TabIds.Users) {
            dispatch(setAddNewUserHandler(false))
        }
        if(tabName != globalState?.currentTabName){
            dispatch(ResetCommonFilterData())
        }
        switch (id) {
            case TabIds.Dashboard:
                localStorage.setItem("currentRouteName", routeName.dashboard)
                // dispatch(reset('userOnboardForm'));
                navigate(routeName.dashboard)
                return
            case TabIds.Users:
                // if (globalState.showSubTabDrawer == true) {
                //     dispatch(setShowSideTabDrawer(!globalState.showSubTabDrawer))
                // }
                // dispatch(setShowOperationSubTabName(!globalState.showOperationTab))
                localStorage.setItem("currentRouteName", routeName.users)
                dispatch(setAddNewUserHandler(false))
                navigate(routeName.users)
                return
            case TabIds.Transactions:
                localStorage.setItem("currentRouteName", routeName.transactions)
                // dispatch(reset('userOnboardForm'));
                navigate(routeName.transactions)
                return

            case TabIds.FundManagement:
                localStorage.setItem("currentRouteName", routeName.aboutus)
                navigate(routeName.Fundmanagement)
                return

            case TabIds.Faqs:
                localStorage.setItem("currentRouteName", routeName.faq)
                navigate(routeName.faq)
                return

            case TabIds.Notifications:
                localStorage.setItem("currentRouteName", routeName.notifications)
                navigate(routeName.notifications)
                return
            // case TabIds.PaymentLinks:
            //     localStorage.setItem("currentRouteName", routeName.PaymentLinks)
            //     navigate(routeName.PaymentLinks)
            //     return
            // case TabIds.Logout:
            //     clearCacheData()
            //     localStorage.clear()
            //     dispatch(setIsLoggedIn(false))
            //     dispatch(resetData())
            //     navigate(routeName.login)
            //     window.location.reload()
            //     return
            default:
                return navigate(routeName.notFound)
            // navigate(routeName.notFound)
        }
    }

    useEffect(() => {
        const initTab = globalState.currentTabName.toLocaleLowerCase();
        if (initTab.toLocaleLowerCase() === tabs[0].tabName.toLocaleLowerCase()) {
            navigate(portelRouteName.dashboard);
        }
    }, []);



    const renderTabs = () => {

        return (
            tabs && tabs.map((eachTab) => {
                const { id, tabName, tabImage } = eachTab;
                return (
                    <List  PaperProps={{ elevation: 0 }} key={id} sx={{ width: 250, overflowY: 'hidden' }}   >
                        <ListItem  PaperProps={{ elevation: 0 }} disablePadding className={Styles.TabStyle} style={{ backgroundColor: globalState.currentTabName && globalState.currentTabName.toLocaleLowerCase() === tabName.toLocaleLowerCase() ? '#F0F1FF' : 'transparent', borderRadius: 10 }}>
                            <ListItemButton  PaperProps={{ elevation: 0 }} onClick={() => onChangeHandler(id, tabName)}>
                                <ListItemIcon  PaperProps={{ elevation: 0 }} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', }}
                                    style={{ color: globalState.currentTabName && globalState.currentTabName.toLocaleLowerCase() === tabName.toLocaleLowerCase() ? Colors.portalPrimary : '#999999' }}>
                                    {tabImage}
                                </ListItemIcon>
                                <ListItemText  PaperProps={{ elevation: 0 }} className="maintabsName1" >
                                    <span style={{
                                        fontSize: 17,
                                        fontWeight: globalState.currentTabName && globalState.currentTabName.toLocaleLowerCase() === tabName.toLocaleLowerCase() ? 600 : 400,
                                        fontFamily: globalState.currentTabName && globalState.currentTabName.toLocaleLowerCase() === tabName.toLocaleLowerCase() ? 'Gilroy-SemiBold' : 'Gilroy-Regular',
                                        color: globalState.currentTabName && globalState.currentTabName.toLocaleLowerCase() === tabName.toLocaleLowerCase() ? Colors.portalPrimary : '#999999',
                                    }}
                                        className="maintabsName"
                                    >{tabName}</span>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                )
            })
        )
    }


    const drawer = (

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', height: "100%", width: 229 }} className='container-fluid' >
            <div style={{ left: 0, top: 0, backgroundColor: 'white', zIndex: 1, }}>

                <div style={{ width: 200, display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 6, marginLeft: 20, height: 58 }}>
                    <img src={PortalImages.Portal_left_logo} style={{ height: "35px", width: '75%', marginBottom: 6, cursor: "pointer" }} onClick={() => navigate(routeName.dashboard)} />
                </div>
                <Box className='Parent' style={{ marginTop: 25, marginLeft: 10 }} >
                    {renderTabs()}
                </Box>
            </div>
            {/* <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: "column", width: "100%" }}>
                <div style={{ fontSize: "14px", fontFamily: "Gilroy-Regular", textAlign: "left", color: '#333', padding: "6px 0px" }}>Invite</div>
                <Divider />
                <a style={{ fontSize: "14px", fontFamily: "Gilroy-Regular", textAlign: "left", color: "#016AE0", textDecoration: "none", padding: "6px 0px", width: "fit-content", cursor: "pointer" }} target="_blank" href={"https://wa.me/918657457296"} >https://wa.me/918657457296</a>
            </div> */}
        </div>
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const renderTabName = () => {
        let lstabName = localStorage.getItem("currentTabName")
        if (globalState?.currentTabName.toLocaleLowerCase() === TabNamesText.Dashboard.toLocaleLowerCase()) {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginTop: 5 }}>
                        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '17px', fontWeight: '600px', color: "#333333" }}>
                            <span> Hey {restApiState?.fetchProfileResponse?.name}</span>
                        </div>
                        <p style={{ fontFamily: 'Gilroy-Regular', fontSize: '14px', fontWeight: '400px', color: "#666666" }}>Welcome</p>
                    </div>
                </>
            )
        }
        if (lstabName && globalState?.currentTabName.toLocaleLowerCase() !== TabNamesText.Dashboard.toLocaleLowerCase()) {
            return lstabName
        } else {
            return globalState.currentTabName
        }
    }

    const addNewUserhandler = () => {
        dispatch({
            type: ReduxValues.RestApiActions.PORTAL_UPLOAD_DOC,
          });
        dispatch({
            type: ReduxValues.RestApiActions.RESET_FETCH_USER_DOC,
         });
         dispatch({
            type: ReduxValues.RestApiActions.RESET_USER_DOC,
         });
        dispatch(setIsCurrentSelectedUserDataForModal(null))
        dispatch(setbreadcrumbsList([{title:"Users",action:"users"}]))
        dispatch(setAadharNumber(""))
        dispatch(setAddNewUserHandler(true))
        dispatch(setStepCount(1));
        dispatch(setFieldDisabled(false))
        if(window.location.pathname?.toLocaleLowerCase() === routeName.UserProfile.toLocaleLowerCase() && globalState.currentTabName === "Users"){
            navigate(routeName.users)
        }
        navigate('/portel/Users')
    }
    const NavigatetoAmount = () => {
        navigate('/portel/InvestmentScreen')
    }
    const NavigateProfile = () => {
        setAnchorEl(null);
        navigate('/portel/IFAUserProfile')
    }
    const NavigateToFaq = () => {
        setAnchorEl(null);
        navigate('/portel/FAQ')
    }


    // const handleLogout = () => {
    //     localStorage.removeItem('authData');
    //     sessionStorage.clear();
    //     navigate('/');
    //     // window.location.reload();
    //   };
    const handleLogout = () => {
        ClipLoader(true)
        dispatch(logoutUser({
            callback: (res) => {
                if (res && !res.isErrorOccure) {
                    ClipLoader(false)
                    console.log("logging out user:", res)
                    localStorage.removeItem('authData')
                    sessionStorage.clear()
                    localStorage.clear()
                    setTimeout(() => {
                        ClipLoader(false)
                        // navigate('/')
                        // localStorage.clear()
                        // sessionStorage.clear()
                        // window.location.reload()
                        navigate('/', { state: { navigatePath: '/', isfromLogout: true } })
                    }, 1000);
                    // }
                } else if (res && res.isErrorOccure) {
                    // localStorage.removeItem('authData')
                    // sessionStorage.clear()
                    ClipLoader(false)
                    dispatch(isPOPOpen({
                        msg: 'Logout failed!',
                        cancleBtntxt: "OK",
                        isOpen: true,
                        title: "Error"
                    }))
                }
            }
        }))

    }

    return (
        <Box style={{boxShadow:'none'}} PaperProps={{ elevation: 0 }} sx={{ display: 'flex' }}>
            {/* <CssBaseline  PaperProps={{ elevation: 0 }} /> */}

            <AppBar  style={{boxShadow:'0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)'}} PaperProps={{ elevation: 0 }} position="fixed" open={props.open}
                sx={{
                    width: CASHeUtils.isMobileDevice() ? { sm: `calc(100% - ${drawerWidth}px)` } : (props.open) ? { sm: `calc(100% - ${drawerWidth}px)` } : "100%",
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: 'white',
                    elevation: 0,
                }}
            
            >
                <Toolbar  PaperProps={{ elevation: 0 }}  >



                    <span style={{ fontSize: 21, fontWeight: 600, fontFamily: 'Gilroy-SemiBold', alignSelf: 'center', color: '#333333' }} className={(props.open) ? HeaderStyles.titleAnimation : HeaderStyles.reverseTitleAnimation}>{renderTabName()}</span>
                    <Box  PaperProps={{ elevation: 0 }} sx={{ flexGrow: 1,elevation: 0, }} />
                    {/* <button style={{ outline: "none", color: Colors.secondaryprimary, fontSize: 12, fontFamily: 'Gilroy-SemiBold', background: "none", border: 'none' }} onClick={NavigatetoAmount}> <AddCardIcon style={{ color: Colors.secondaryprimary, fontSize: 18, }} /> View Profile</button> */}
                    {globalState?.currentTabName.toLocaleLowerCase() === TabNamesText.Users.toLocaleLowerCase() && !(globalState?.isAddNewUser || false) && <button style={{ outline: "none", color: Colors.secondaryprimary, fontSize: 12, fontFamily: 'Gilroy-SemiBold', background: "none", border: 'none' }} onClick={addNewUserhandler}> <AddCircleOutlineIcon style={{ color: Colors.secondaryprimary, fontSize: 18, }} /> Add new user</button>}
                    {restApiState?.fetchProfileResponse && <IconButton onClick={handleClick}>
                        <Avatar sx={{ width: '32px', height: '32px', bgcolor: '#F0F1FF', color: '#363978', margin: '0px 10px' }}>{restApiState?.fetchProfileResponse?.name?.charAt(0).toUpperCase()}</Avatar>
                    </IconButton>
                    }
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        PaperProps={{ elevation: 1 }}  
                        onClose={handleClose}
                        className="custom-menu"
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        // style={{boxShadow:'none'}}
                    >
                        <MenuItem className="custom-menu-item" onClick={() => { NavigateProfile() }}>
                            <ListItemIcon>
                                <PersonIcon style={{ color: "#333", padding: "2px", marginRight: "3px" }} />
                            </ListItemIcon>
                            <ListItemText>
                                Account
                            </ListItemText>

                        </MenuItem>
                        <Divider variant="middle" component="li"/>

                        <MenuItem className="custom-menu-item" href="https://wa.me/918657457296" target="_blank" component="a" onClick={handleClose}>
                            <ListItemIcon>
                                <HeadsetMicIcon style={{ color: "#333", padding: "2px", marginRight: "3px" }} />
                            </ListItemIcon>
                            <ListItemText>
                                Support
                            </ListItemText>
                        </MenuItem>
                        <Divider variant="middle" component="li"/>

                        <MenuItem className="custom-menu-item" onClick={() => { handleLogout() }}>
                            <ListItemIcon>
                                <LogoutIcon style={{ color: "#333", padding: "2px", marginRight: "3px" }} />
                            </ListItemIcon>
                            <ListItemText>
                                Logout
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                    {/* <Box

                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: (props.open) ? 'none' : 'flex' }}
                    >
                        <MenuSharpIcon sx={{ color: '#555555', height: 30, width: 30 }} onClick={props.sideMenuHandler} />
                    </Box> */}

                </Toolbar>
            </AppBar>


            <Drawer
                variant="persistent"
                anchor="left"
                open={props.open}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    width: CASHeUtils.isMobileDevice() ? drawerWidth : props.open ? drawerWidth : 0,
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Box
                sx={{ overflow: 'hidden', flexGrow: 1, marginTop: '44px', maxWidth: (props.open) ? { sm: `calc(100% - ${drawerWidth}px)` } : { sm: `calc(100% - ${0}px)` } }}
            >
                {props.children}
            </Box>
        </Box>
    )

}

export default SideBar