import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ForgetPassword1 from '../Pages/Onboarding/ForgetPassword1';

const Onboarding_Login = React.lazy(() => import('../Pages/Onboarding/Onboarding_Login'));
const Portel_Login = React.lazy(() => import('../Pages/Portel/Portel_Login'));
const Onboarding_welcome = React.lazy(()=> import('../Pages/Onboarding/Onboarding_welcome'));
const ProfileInfo = React.lazy(() => import('../Pages/Onboarding/profileInfo.js'));
const Onboarding_Steps = React.lazy(()=> import('../Pages/Onboarding/Onboarding_Steps'));
const Onboarding_Dashboard = React.lazy(()=> import('../Pages/Onboarding/Onboarding_Dashboard'));
const KycDocumentUpload = React.lazy(()=> import('../Pages/Onboarding/KycDocumentUpload'));
const BankInfo =  React.lazy(()=> import('../Pages/Onboarding/BankInfo'));
const ESignSuccess = React.lazy(()=> import('../Components/OnboardingComponents/ESignSuccess'));
const Agreement = React.lazy(()=> import('../Pages/Onboarding/Agreement'));
const LoanAgreementViewer = React.lazy(()=> import('../Pages/Onboarding/LoanAgreementViewer'));
const Profile = React.lazy(()=> import('../Pages/Onboarding/Profile'));
const AboutthirteenK = React.lazy(()=> import('../Pages/Onboarding/AboutthirteenK'));
const ProfileSuccess = React.lazy(()=> import('../Pages/Onboarding/profileSuccess'));
const FaqPage = React.lazy(() => import('../Pages/Onboarding/Faq'));
const FaqDetails = React.lazy(() => import('../Pages/Onboarding/FaqDetails'));
const Portal_Dashboard = React.lazy(() => import('../Pages/Portel/Portal_Dashboard'));
const Transactions = React.lazy(() => import('../Pages/Portel/Transactions'));
const Users = React.lazy(() => import('../Pages/Portel/Users'));
const Notifications = React.lazy(() => import('../Pages/Portel/Notifications'));
const FAQ = React.lazy(() => import('../Pages/Portel/FAQ'));
const ParentInvestment =React.lazy(()=>import('../Components/PortelComponents/InvestmentComponents/ParentInvestment'));
const FundManagement=React.lazy(()=>import('../Pages/Portel/Fundmanagement'));
const UserProfile=React.lazy(()=>import('../Pages/Portel/UserInvestmentPages/UserProfile'));
const IFAUserProfile=React.lazy(()=>import('../Pages/Portel/IFAUserProfile'));
const EditBankDetails2=React.lazy(()=>import('../Pages/Portel/BankUserForm'));
const DocumentFormPage=React.lazy(()=>import('../Pages/Portel/DocumentForm'));
const BankOwnerFormPage=React.lazy(()=>import('../Pages/Portel/BankOwnerForm'));
const SetPassword=React.lazy(()=>import('../Pages/Onboarding/SetupPassword'));
const ChangePassword=React.lazy(()=>import('../Pages/Onboarding/ChangePassword'));
const ForgetPassword=React.lazy(()=>import('../Pages/Onboarding/ForgetPassword1'));
const UnderMaintenance=React.lazy(()=>import('../Pages/Portel/UnderMaintaince'));










export const routeName={
    login:'/',
    dashboard:'/portel/Dashboard',
    transactions:"/portel/Transactions",
    users:"/portel/Users",
    notifications: "/portel/Notifications",
    faq: "/portel/FAQ",
    ParentInvestment:"/portel/InvestmentScreen",
    Fundmanagement:"/portel/FundManagement",
    UserProfile: '/portel/UserProfile',
    KycDocumentUpload :'/onboarding/KycDocumentUpload',
    notFound:"404",
    SetPassword:'/onboarding/set_password',
    ForgetPassword:'/onboarding/forgot_password',
  }

export const portelRouteName={
  login:'/',
  dashboard:'/portel/Dashboard',
  transactions:"/portel/Transactions",
  users:"/portel/Users",
  notifications: "/portel/Notifications",
  faq: "/portel/FAQ",
  ParentInvestment:"/portel/InvestmentScreen",
  Fundmanagement:"/portel/FundManagement",
  UserProfile: '/portel/UserProfile',
  IFAUserProfile:'/portel/IFAUserProfile',
  EditBankDetails:"/portel/EditBankDetail",
  DocumentFormPage:"/portel/DocumentFormPage",
  BankOwnerFormPage:"/portel/BankOwnerFormPage",
  ChangePassword:"/portel/change_password",
  // transactionReport:"/TransactionReport",
  // settlementReport:"/SettlementReport",
  // refundReport:"/RefundReport",
  // userManagement:"/UserManagement",
  // aboutus:'/aboutus',
  // qrReports:'/qrReports',
  // PaymentLinks:'/PaymentLinks',
  notFound:"404"
}
  
const Routers = () => {

    const renderOnboardingRoute=()=>{
        return <>
              {/* <Route path='/' element={< Onboarding_welcome />}></Route> */}
              <Route path='/' element={< Onboarding_Login />}></Route>
              <Route path={routeName.SetPassword} element={< SetPassword />}></Route>
              <Route path={routeName.ForgetPassword} element={< ForgetPassword />}></Route>
              <Route path='/onboarding/welcome' element={< Onboarding_welcome />}></Route>
              <Route path='onboarding/profileInfo' element={< ProfileInfo />}></Route>
              <Route path='/onboarding/steps' element={< Onboarding_Steps />}></Route>
              <Route path='/onboarding/dashboard' element={< Onboarding_Dashboard />}></Route>
              <Route path={routeName.KycDocumentUpload} element={< KycDocumentUpload />}></Route>
              <Route path='/onboarding/BankInfo' element={< BankInfo />}></Route>
              <Route path='/onboarding/ESignSuccess' element={< ESignSuccess />}></Route>
              <Route path='/onboarding/Agreement' element={< Agreement />}></Route>
              <Route path='/onboarding/loanAgreementViewer' element={< LoanAgreementViewer />}></Route>
              <Route path='/onboarding/Profile' element={< Profile />}></Route>
              <Route path="/onboarding/AboutthirteenK" element={<AboutthirteenK />}></Route>
              <Route path="/onboarding/ProfileSuccess" element={<ProfileSuccess />}></Route>
              <Route path='/onboarding/FaqPage' element={< FaqPage />}></Route>
              <Route path='/onboarding/FaqDetails' element={< FaqDetails />}></Route>
              <Route path='/UnderMaintaince' element={< UnderMaintenance />}></Route>
             

              
             </>
    }

    const renderportelRoute=()=>{
        return <>
                <Route path={portelRouteName.dashboard} element={< Portal_Dashboard />}></Route>
                <Route path={portelRouteName.ChangePassword} element={< ChangePassword />}></Route>
                <Route path={portelRouteName.transactions} element={< Transactions />}></Route>
                <Route path={portelRouteName.users} element={< Users />}></Route>
                <Route path={portelRouteName.notifications} element={< Notifications />}></Route>
                <Route path={portelRouteName.faq} element={< FAQ />}></Route>
                <Route path={portelRouteName.ParentInvestment} element={< ParentInvestment />}></Route>
                <Route path={portelRouteName.Fundmanagement} element={< FundManagement />}></Route>
                <Route path={portelRouteName.UserProfile} element={< UserProfile />}></Route>
                <Route path={portelRouteName.IFAUserProfile} element={< IFAUserProfile />}></Route>
                <Route path={portelRouteName.EditBankDetails} element={< EditBankDetails2 />}></Route>
                <Route path={portelRouteName.DocumentFormPage} element={< DocumentFormPage />}></Route>
                <Route path={portelRouteName.BankOwnerFormPage} element={< BankOwnerFormPage />}></Route>
                
                
                
                
                
                
               </>
    }

  return (
      <Routes>
          {renderOnboardingRoute()}
          {renderportelRoute()}
        
      </Routes>
  )
}

export default Routers
