import React, { useEffect, useState } from 'react';
import Images from "../../Images/Images";
import {
  CssBaseline,
  ThemeProvider,
  Typography,
  Container,
  createMuiTheme,
  Box,
  Grid,
  makeStyles,
  Button,
  Link,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import { fetchProfile, UserUpdatePasswordLogin } from '../../Store/Actions/RestAPiActions';
import { isLoading, isPOPOpen } from "../../Store/Actions/GlobalActions";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import DangerousSharpIcon from '@mui/icons-material/DangerousSharp';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#556cd6"
    },
    secondary: {
      main: "#19857b"
    },
    error: {
      main: "#f44336"
    },
    background: {
      default: "#fff"
    }
  }
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1)
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ForgetPassword2(props) {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    special: false,
  });

  const ClipLoader = (flag) => {
    dispatch(isLoading(flag));
  };


  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(password)) {
      setPasswordError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
      return false;
    }
    setPasswordError('');
    return true;
    // const length = password.length >= 8;
    // const uppercase = /[A-Z]/.test(password);
    // const lowercase = /[a-z]/.test(password);
    // const special = /[@$!%*?&]/.test(password);
    
    // setPasswordValidation({ length, uppercase, lowercase, special });
    // return length && uppercase && lowercase && special;
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (validatePassword(newPassword)) {
      setPasswordError('');
    }
    if (newPassword === confirmPassword) {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (password === newConfirmPassword) {
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isPasswordValid = validatePassword(password);

    if (confirmPassword.trim().length === 0) {
      setConfirmPasswordError('Enter confirm password');
    }else if ( password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
    }

    if (!isPasswordValid) {
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }

    setPasswordError('');
    setConfirmPasswordError('');
    ClipLoader(true);
    const req = {
      type: "forget",
      password: password,
    };
    dispatch(UserUpdatePasswordLogin({
      request: req,
      token:`Bearer ${props.loginResponse.access_token}`,
      callback: (res) => {
        ClipLoader(false);
        if (res && res?.success) {
          dispatch(isPOPOpen({
            isOpen: true,
            btntxt: "OK",
            msg: 'Your new password has been set successfully.',
            successHandler: successHandler,
            customImg: Images.SuccessNewWithdraw,
            // title:"Forgot Password",
            showCrossIcon:false,
          }))
         
        } else if (res && res.isErrorOccure) {
          dispatch(isPOPOpen({
            msg: "Something went wrong",
            cancleBtntxt: "OK",
            isOpen: true,
            title: "Error"
          }));
        }
      }
    }));
  };
  const successHandler = () => {
    navigate('/');
  }

  return (
    <>
      <div className='divWidth'>
        <div style={{ display: 'flex', height: '100vh', alignItems:"center",}}>
          <div style={{ width: '30%', backgroundImage: `url(${Images.backgroundOnboarding})`, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img style={{ position: 'fixed', top: 10, left: 10, right: 100, width: '10%' }} src={Images.karatHeaderLogoForLogin} alt="Karat Header Logo" />
          </div>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              {/* <CssBaseline /> */}
              <div style={{marginTop:"0.2rem"}} className={classes.paper}>
                <Typography style={{ fontFamily: "Gilroy-Bold" }} component="h1" variant="h5">
                  Setup New Password
                </Typography>
                <Box style={{ padding: "8px 8px" }}>You can now login using Mobile Number & Password or OTP.</Box>
                <div style={{ width:"100%" }}>
                <div style={{textAlign:"left",fontWeight: 500,fontSize:"14px",fontFamily:"Gilroy-Regular",padding:"0rem 0rem 0.2rem 0rem"}}>In order to protect your account, make sure your password:</div>
                <div style={{textAlign:"left",fontWeight: 500,fontSize:"12px",fontFamily:"Gilroy-Regular",padding:"0rem 0rem 0.2rem 0rem"}}>• Is longer than 8 characters</div>
                <div style={{textAlign:"left",fontWeight: 500,fontSize:"12px",fontFamily:"Gilroy-Regular",padding:"0rem 0rem 0.2rem 0rem"}}>• Has atleast one uppercase and one lowercase character</div>
                <div style={{textAlign:"left",fontWeight: 500,fontSize:"12px",fontFamily:"Gilroy-Regular",padding:"0rem 0rem 0.2rem 0rem"}}>• Has atleast one number</div>
                <div style={{textAlign:"left",fontWeight: 500,fontSize:"12px",fontFamily:"Gilroy-Regular",padding:"0rem 0rem 0.2rem 0rem"}}>• Contains a symbol '@$!%*?&'</div>
                </div>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <div style={{fontWeight: 500,fontSize:"14px",textAlign:"left",fontFamily:"Gilroy-Regular",paddingBottom:"0.2rem"}}>Password</div>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        hiddenLabel
                        name="password"
                        // label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={handlePasswordChange}
                        error={!!passwordError}
                        helperText={passwordError}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handlePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <div style={{fontWeight: 500,fontSize:"14px",textAlign:"left",fontFamily:"Gilroy-Regular",paddingBottom:"0.2rem"}}>Confirm Password</div>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirmPassword"
                        // label="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        hiddenLabel
                        onChange={handleConfirmPasswordChange}
                        error={!!confirmPasswordError}
                        helperText={confirmPasswordError}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle confirm password visibility"
                                onClick={handleConfirmPasswordVisibility}
                                edge="end"
                              >
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ display: "flex" }}>
                    <Button
                      style={{ marginRight: "8px", color: "#2D2F51", border: "1px solid #2D2F51", borderRadius: "4px", background: "#fff",textTransform: 'capitalize' }}
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      onClick={() => navigate('/')}
                    >
                      Do it later
                    </Button>
                    <Button
                      style={{ marginRight: "8px", color: "#fff", borderRadius: "4px", background: "#2D2F51" ,textTransform: 'capitalize'}}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Submit
                    </Button>
                  </Box>
             
        
                  {/* <Typography variant="body2" style={{ fontFamily: "Gilroy-Regular", display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", marginTop: "1rem" }}>
                    <div>
                      {passwordValidation.length ? <CheckCircleOutlineSharpIcon style={{ color: "#0c880c", marginRight: "4px" }} /> : <DangerousSharpIcon style={{ color: "red" }} />}
                      Min 8 characters long,
                    </div>
                    <div>
                      {passwordValidation.uppercase ? <CheckCircleOutlineSharpIcon style={{ color: "#0c880c", marginRight: "4px" }} /> : <DangerousSharpIcon style={{ color: "red" }} />}
                      Upper case letter,
                    </div>
                    <div>
                      {passwordValidation.lowercase ? <CheckCircleOutlineSharpIcon style={{ color: "#0c880c", marginRight: "4px" }} /> : <DangerousSharpIcon style={{ color: "red" }} />}
                      Lower case letter,
                    </div>
                    <div>
                      {passwordValidation.special ? <CheckCircleOutlineSharpIcon style={{ color: "#0c880c", marginRight: "4px" }} /> : <DangerousSharpIcon style={{ color: "red" }} />}
                      one or more Special character
                    </div>
                  </Typography> */}
          </form>
        </div>
        </Container>
        </ThemeProvider>
        </div>
      </div>
    </>
  );
}
