import ReduxValues from "../ReduxValues";
let actionsStrings = ReduxValues.reduxActions


const initialState = {
   handleSubmit: null,
   showError: null,
   formResetHandler: null,
   formError: {
      LOGIN_MOBILE_NUMBER: {
         msg: '',
         isvalid: true
      },

      USER_MOBILE_NUMBER : {
         msg: '',
         isvalid: true
      },

      OTP: {
         msg: '',
         isvalid: true
      },


      EMAIL_ID: {
         msg: '',
         isvalid: true
      },
      PAN_ID: {
         msg: '',
         isvalid: true
      },
      NAME_ID: {
         msg: '',
         isvalid: true
      },
      FULL_NAME: {
         msg: '',
         isvalid: true
      },
      LAST_NAME: {
         msg: '',
         isvalid: true
      },
      CURRENTADDRESS_ID: {
         msg: '',
         isvalid: true
      },
      PINCODE_ID: {
         msg: '',
         isvalid: true
      },
      DOB_ID: {
         msg: '',
         isvalid: true
      },
      EMPLOYMENT_TYPE_ID: {
         msg: '',
         isvalid: true
      },
      GENDER: {
         msg: '',
         isvalid: true
      },
      ACCOUNT_HOLDER_NAME_ID: {
         msg: '',
         isvalid: true
      },
      ACCOUNT_NO_ID: {
         msg: '',
         isvalid: true
      },
      REENTER_ACCOUNT_NO_ID: {
         msg: '',
         isvalid: true
      },
      IFSC_CODE_ID: {
         msg: '',
         isvalid: true
      },
      BANK_NAME_ID: {
         msg: '',
         isvalid: true
      },
      ACCOUNT_TYPE_ID: {
         msg: '',
         isvalid: true
      },
      Bank_Name: {
         msg: '',
         isvalid: true
      },
      Bank_State: {
         msg: '',
         isvalid: true
      },
      Bank_City: {
         msg: '',
         isvalid: true
      },
      Bank_Branch_Name: {
         msg: '',
         isvalid: true
      },
      NOMINEE_NAME : {
         msg: '',
         isvalid: true
      },
      NOMINEE_NUMBER : {
         msg: '',
         isvalid: true
      },
      NOMINEE_EMAIL : {
         msg: '',
         isvalid: true
      },
      NOMINEE_DOB : {
         msg: '',
         isvalid: true
      },
      NOMINEE_RELATION : {
         msg: '',
         isvalid: true
      },




   },//END


   profileInfoForm: {},
   BankInfoForm: {},
   BankIfscForm: {},
   NomineeInfoForm: {},
   userPersonalDetailsForm: {},
   NewUser:{},
   userBankInfoForm:{}

   // data: {
   //    NAME_ID: '2000'
   // },
}

const Reducer = (oldstate = initialState, actions) => {
   if ('bankDataAPi' === actions.type) {
      console.log('bankDataAPi >>>', oldstate, actions)
   }
   switch (actions.type) {
      case 'SET_RESET_FROM': return {
         ...oldstate,
         formResetHandler: actions.fun

      }
      case actionsStrings.SET_FORM_SUBMIT_FUNCTION: return {
         ...oldstate,
         handleSubmit: actions.handleSubmit
      }
      case actionsStrings.SET_FORM_SHOW_ERROR_FUNCTION: return {
         ...oldstate,
         showError: actions.showError
      }
      case "profileDataAPi": return {
         ...oldstate,
         profileDataAPi: actions.payload
      }
      case "profileInfoFields": return {

         ...oldstate,
         profileInfoForm: actions.payload
      }
      case "userPersonalDetailsFields": return {

         ...oldstate,
         userPersonalDetailsForm: actions.payload
      }
      case "userPersonalDetailsFields": return {

         ...oldstate,
         NewUser: actions.payload
      }
      case "bankDataAPi": return {
         ...oldstate,
         bankDataAPi: actions.payload
      }
      case "portalBankDataAPi": return {
         ...oldstate,
         portalBankDataAPi: actions.payload
      }
      case "bankInfoFields": return {
         ...oldstate,
         bankInfoForm: actions.payload
      }
      case "UserBankInfoFields": return {
         ...oldstate,
         userBankInfoForm: actions.payload
      }
      case "BankIfscFields": return {
         ...oldstate,
         BankIfscForm: actions.payload
      }
   
      case actionsStrings.SET_FROM_ERROR:
         let reqerrorObj = {}
         for (let ele in oldstate.formError) {
            if (ele == actions.formFieldName) {
               reqerrorObj[ele] = { msg: actions.msg, isvalid: actions.isvalid }
            } else {
               reqerrorObj[ele] = { msg: oldstate.formError[ele].msg, isvalid: oldstate.formError[ele].isvalid }
            }
         }
         return {
            ...oldstate,
            formError: reqerrorObj
         }

      default: return oldstate
   }
}

export default Reducer