import { MobileOnlyView } from 'react-device-detect';
import Strings from '../../Constants/Strings';
import ReduxValues from "../../Store/ReduxValues";
import moment from "moment";
const localStorageAuthData = JSON.parse(localStorage.getItem("authData"));

export const defaultFilterrequest={
    count: 20,
    page: 1,
    txn_type: "",
    // start_date: "14-06-2023",
    start_date: "",
    end_date: '',
    product_type: "",
    // trnx_status:["SUCCESS","PROCESSING","FAILURE"],
    trnx_status:[1,2,3],
    sort_by:'',
    search_field:'',


  }

  export const defaultUserListFilterrequest={
    // date:"2021-01-12",
    // status:["profile_pending","doc_pending","kyc_success"],
    status:[],
    page:1,
    per_page:5,
    start_date:  "",
    end_date: '',
    sort_by:'',
    search_field:'',
}

const intialState = {
    isLoggedIn: false,
    authData: null,
    errorResponse: {
        errorUrl: null,
        errorText: null,
        isErrorOccure: false
    },
    graphqlResponse:null,
    createProfileResponse:null,
    fetchProfileResponse:null,
    createBankAccountResponse:null,
    fetchBankAccountResponse:null,
    fetchBankAccountResponsePortal:null,
    uploadDocResponse:null,
    fetchDocResponse:{
        pan: null,
        aadhaarUnified: null,
        aadhaarFrontSide: null,
        aadhaarBackSide: null,
        profile: null,
        upload_GST_Certificate: null,
        NetWorthCertificate:null
    },
    fetchUserDocResponse:{
        pan: null,
        aadhaarUnified: null,
        aadhaarFrontSide: null,
        aadhaarBackSide: null,
        profile: null,
        NetWorthCertificate:null
    },    
    fetchPortfolioResponse:null,
    fetchBankListResponse:null,
    fetchStateListResponse:null,
    fetchCityListResposnse:null,
    bankdetailsOnIFSCResponse:null,
    logoutResponse:null,
    payOrderResponse:null,
    createInvestmentResponse:null,
    fetchTxnHistoryReponse:null,
    totalTxnHistory:[],
    totalTxnHistoryFilter:defaultFilterrequest,

    userListHistory:{ "users": [],
    "total_pages": 1,
    "current_page": 1
    },
    userListHistoryFilter:defaultUserListFilterrequest,

    paymentStatusResponse:null,
    faqDataResponse:null,
    autoInvestResponse:null,
    AppsFlyer:null,
    fetchDeactivateResponse:null,
    pdfResponse:null,
    pdfNetWorthResponse:null,
    preWithdrawResponse:null,
    fetchRelationListResponse:null,
    userNetWorthResponse:null,
    fetchPdfDocResponse:null,
    mobileNo:null,
    esingRequestResponse: null,
    newUserResponse: null,
    portalUserProfileResponse:null,
    portalUploadDocResponse:null,
    notificationData:null,
    UpcomingMaturityData:null,
    fetchPortalUserProfileResponse: null,
    fetchPortalBankListResponse: null
}


const RestApiReducer = (oldstate = intialState, actions) => {
    // console.log("=>>>>", ReduxValues.RestApiActions.profileDataAPi)

    switch (actions.type) {

        case ReduxValues.RestApiActions.MOBILE_NUMBER:
            return{
                ...oldstate,
                mobileNo: actions.payload
            }
      
        case ReduxValues.RestApiActions.PDFCERIFICATE:
            return{
                ...oldstate,
                pdfResponse:actions.payload
            }
            case ReduxValues.RestApiActions.NETWORTHCERTIFICATE:
                return{
                    ...oldstate,
                    pdfNetWorthResponse:actions.payload
                }
            
            case ReduxValues.RestApiActions.PREWITHDRAWAL:
                return{
                    ...oldstate,
                    preWithdrawResponse:actions.payload
                }

        case ReduxValues.RestApiActions.AUTO_INVEST:
            return{
                ...oldstate,
                autoInvestResponse:actions.payload
            }
        case ReduxValues.RestApiActions.GET_PAYMENT_STATUS:
            return{
                ...oldstate,
                paymentStatusResponse:actions.payload
            }
    
        case ReduxValues.RestApiActions.FETCH_DOC:
                let docType=""
                let reqData={
                    pan: null,
                    aadhaarUnified: null,
                    aadhaarFrontSide: null,
                    aadhaarBackSide: null,
                    profile: null,
                    upload_GST_Certificate:null
                }
                
            return{
                ...oldstate,
                fetchDocResponse:{
                    pan: actions.payload.docType === Strings.docType.pan?actions.payload.res:oldstate.fetchDocResponse.pan,
                    aadhaarUnified: actions.payload.docType === Strings.docType.aadhaarUnified?actions.payload.res:oldstate.fetchDocResponse.aadhaarUnified,
                    aadhaarFrontSide: actions.payload.docType === Strings.docType.aadhaarFrontSide?actions.payload.res:oldstate.fetchDocResponse.aadhaarFrontSide,
                    aadhaarBackSide: actions.payload.docType === Strings.docType.aadhaarBackSide?actions.payload.res:oldstate.fetchDocResponse.aadhaarBackSide,
                    profile: actions.payload.docType === Strings.docType.profile?actions.payload.res:oldstate.fetchDocResponse.profile,
                    upload_GST_Certificate: actions.payload.docType === Strings.docType.upload_GST_Certificate?actions.payload.res:oldstate.fetchDocResponse.upload_GST_Certificate,
                }
            }

            case ReduxValues.RestApiActions.FETCH_USER_DOC:
         
            return{
                ...oldstate,
                fetchUserDocResponse:{
                    pan: actions.payload.docType === Strings.docType.pan?actions.payload.res:oldstate.fetchUserDocResponse.pan,
                    aadhaarUnified: actions.payload.docType === Strings.docType.aadhaarUnified?actions.payload.res:oldstate.fetchUserDocResponse.aadhaarUnified,
                    aadhaarFrontSide: actions.payload.docType === Strings.docType.aadhaarFrontSide?actions.payload.res:oldstate.fetchUserDocResponse.aadhaarFrontSide,
                    aadhaarBackSide: actions.payload.docType === Strings.docType.aadhaarBackSide?actions.payload.res:oldstate.fetchUserDocResponse.aadhaarBackSide,
                    profile: actions.payload.docType === Strings.docType.profile?actions.payload.res:oldstate.fetchUserDocResponse.profile,
                    NetWorthCertificate: actions.payload.docType === Strings.docType.upload_NetWorth_Certificate?actions.payload.res:oldstate.fetchUserDocResponse.NetWorthCertificate,
                }
            }
            case ReduxValues.RestApiActions.RESET_FETCH_USER_DOC:
         
            return{
                ...oldstate,
                fetchUserDocResponse:{
                    pan: null,
                    aadhaarUnified: null,
                    aadhaarFrontSide: null,
                    aadhaarBackSide: null,
                    profile: null,
                    NetWorthCertificate:null
                },
                fetchPortalUserProfileResponse:null
            }    
        case  ReduxValues.RestApiActions.FETCH_TXN_DATA:
            return{
                ...oldstate,
                fetchTxnHistoryReponse:actions.payload,
                totalTxnHistory:actions.isPagination?[...oldstate.totalTxnHistory, ...actions.payload.transactions]:oldstate.totalTxnHistory
            }
        case ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA:
            return{
                ...oldstate,
                totalTxnHistory:actions.payload,
            }
            case ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA_FILTER:
                return{
                    ...oldstate,
                    totalTxnHistoryFilter:actions.payload,
                }
                case  ReduxValues.RestApiActions.FETCH_USER_DATA:
                    
                    return{
                        ...oldstate,
                        userListHistory:actions.payload,
                        // userListHistory:actions.isPagination?[...oldstate.totalTxnHistory, ...actions.payload.transactions]:oldstate.totalTxnHistory
                    }
                    case ReduxValues.RestApiActions.SET_TOTAL_USER_DATA_FILTER:
                        return{
                            ...oldstate,
                            userListHistoryFilter:{...oldstate.userListHistoryFilter, ...actions.payload},
                        }


            case  ReduxValues.RestApiActions.FAQ_DATA:
                return{
                    ...oldstate,
                    faqDataResponse:actions.payload
                }
                case  ReduxValues.RestApiActions.UPCOMINGMATURITY:
                    return{
                        ...oldstate,
                        UpcomingMaturityData:actions.payload
                    }
                case  ReduxValues.RestApiActions.AppsFlyer:
                    return{
                        ...oldstate,
                        AppsFlyer:actions.payload
                    }
    
        case ReduxValues.RestApiActions.CREATE_INVESTMENT:
            return{
                ...oldstate,
                createInvestmentResponse:actions.payload
            }

        case ReduxValues.RestApiActions.PAY_ORDER:
            return{
                ...oldstate,
                payOrderResponse: actions.payload
            }

        case ReduxValues.RestApiActions.LOGOUT_USER:
            return{
                ...oldstate,
                logoutResponse: actions.payload
            }

        case ReduxValues.RestApiActions.FETCH_BANK_DETAILS_ON_IFSC_CODE:
            return{
                ...oldstate,
                bankdetailsOnIFSCResponse: actions.payload
            }
            case ReduxValues.RestApiActions.NOTIFICATIONSDATA:
                return{
                    ...oldstate,
                    notificationData: actions.payload
                }
        case ReduxValues.RestApiActions.FETCH_BANK_LIST:
            return{
                ...oldstate,
                fetchBankListResponse: actions.payload
            }
        case ReduxValues.RestApiActions.FETCH_PORTAL_BANK_LIST:
            return{
                ...oldstate,
                fetchPortalBankListResponse: actions.payload
            }            
            case ReduxValues.RestApiActions.FETCH_RELATION_LIST:
                return{
                    ...oldstate,
                    fetchRelationListResponse: actions.payload
                }
            case ReduxValues.RestApiActions.FETCH_STATE_LIST:
                return{
                    ...oldstate,
                    fetchStateListResponse: actions.payload
                }
                case ReduxValues.RestApiActions.FETCH_CITY_LIST:
                    return{
                        ...oldstate,
                        fetchCityListResponse: actions.payload
                    }
                    case ReduxValues.RestApiActions.FETCH_GETCERTIFICATE:
                        return{
                            ...oldstate,
                            fetchPdfDocResponse: actions.payload
                            
                        }
                case ReduxValues.RestApiActions.FETCH_BRANCH_DATA:
                        return{
                            ...oldstate,
                            fetchBranchListResponse: actions.payload
                    }

                    
                
        case ReduxValues.RestApiActions.FETCH_PORTFOLIO:
            return{
                ...oldstate,
                fetchPortfolioResponse: actions.payload
            }
        case ReduxValues.RestApiActions.UPLOAD_DOC:
            return {
                ...oldstate,
                uploadDocResponse: actions.payload
            }

        case ReduxValues.RestApiActions.PORTAL_UPLOAD_DOC:
            return {
                ...oldstate,
                portalUploadDocResponse: actions.payload
            }            
        case ReduxValues.RestApiActions.FETCH_BANK_ACCOUNT:
            return {
                ...oldstate,
                fetchBankAccountResponse: actions.payload
            }
            case ReduxValues.RestApiActions.FETCH_BANK_ACCOUNTPORTAL:
                return {
                    ...oldstate,
                    fetchBankAccountResponsePortal: actions.payload
                }

        case ReduxValues.RestApiActions.CREATE_BANK_ACCOUNT:
            return {
                ...oldstate,
                createBankAccountResponse: actions.payload
            }

        case ReduxValues.RestApiActions.FETCH_PROFILE:
            return {
                ...oldstate,
                fetchProfileResponse: actions.payload
            }

        case ReduxValues.RestApiActions.SET_USER_GET_PROFILE_DATA:
            return {
                ...oldstate,
                fetchPortalUserProfileResponse: actions.payload
            }            
        case ReduxValues.RestApiActions.PORTAL_USER_PROFILE:
            return {
                ...oldstate,
                portalUserProfileResponse: actions.payload
            }
        case ReduxValues.RestApiActions.NEW_USER:
            return {
                ...oldstate,
                newUserResponse: actions.payload
            }
    

            case ReduxValues.RestApiActions.CREATE_PROFILE:
                return {
                    ...oldstate,
                    createProfileResponse: actions.payload
                }
        case ReduxValues.RestApiActions.GRAPH_QA_DATA:
            return {
                ...oldstate,
                graphqlResponse: actions.payload
            }
        case ReduxValues.RestApiActions.FETCH_LOGIN_DATA:
            return {
                ...oldstate,
                authData: actions.payload
            }
        case ReduxValues.RestApiActions.USER_ROLE_NAME:
            return {
                ...oldstate,
                userRoleName: actions.payload
            }
        case ReduxValues.RestApiActions.setIsLoggedIn:
            return {
                ...oldstate,
                isLoggedIn: actions.flag
            }
        case ReduxValues.RestApiActions.getUserRolePermissions:
            return {
                ...oldstate,
                userRolePermissionsResponse: actions.payload
            }
        case ReduxValues.RestApiActions.profileDataAPi:
            // console.log("reduser profile api", ReduxValues.RestApiActions.profileDataAPi)
            return {
                ...oldstate,
                profileDataAPi: actions.payload
            }
        case ReduxValues.RestApiActions.BankDataApi:
            // console.log("reduser Bank api", ReduxValues.RestApiActions.BankDataApi)
            return {
                ...oldstate,
                BankDataApi: actions.payload
            }
        case ReduxValues.RestApiActions.portalBankDataAPi:
            // console.log("reduser Bank api", ReduxValues.RestApiActions.BankDataApi)
            return {
                ...oldstate,
                portalBankDataAPi: actions.payload
            }    
            case ReduxValues.RestApiActions.REFFERALCODE:
            return {
                ...oldstate,
                REFFERALCODE: actions.payload
            }
            case ReduxValues.RestApiActions.USERSEGMENT:
                return {
                    ...oldstate,
                    USERSEGMENT: actions.payload
                }
                case ReduxValues.RestApiActions.DEACTIVATEACCOUNT:
                    return{
                        ...oldstate,
                        fetchDeactivateResponse: actions.payload
                    }
                    case ReduxValues.RestApiActions.USERNETWORTH:
                        return{
                            ...oldstate,
                            userNetWorthResponse: actions.payload
                        }
                        case ReduxValues.RestApiActions.CREATE_ESIGN_REQUEST:
                            return {
                                ...oldstate,
                                esingRequestResponse: actions.payload
                            }
            
        case ReduxValues.RestApiActions.ERROR_OCCURE: return {
            ...oldstate,
            errorResponse: actions.isErrorOccure ? {
                errorUrl: actions.errorUrl,
                errorText: actions.errorText,
                isErrorOccure: actions.isErrorOccure
            } : {
                errorUrl: null,
                errorText: null,
                isErrorOccure: actions.isErrorOccure
            },
        }

        // eslint-disable-next-line no-duplicate-case
        case ReduxValues.RestApiActions.setIsLoggedIn:
            return {
                ...oldstate,
                isLoggedIn: true
            }

        case ReduxValues.RestApiActions.RESET_DATA:
            return {
                ...oldstate,
                isLoggedIn: false,
                loginResponse: null,
                userRolePermissionsResponse: null,
                userRoleName: null,


                errorResponse: {
                    errorUrl: null,
                    errorText: null,
                    isErrorOccure: false
                },
            }


        default: return oldstate
    }
}

export default RestApiReducer;