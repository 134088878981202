const Strings = {
    AllFilterArray:["DATE","product Type","Status","Transactions Type"],
    AllFilters:{
        DATE:"DATE",
        productType:"product Type",
        Status:"Status",
        TransactionsType:"Transactions Type"
    },
    paginationConfig:{
        startDate:"2023-06-14",
    },
    paymentStatusText : {
        1: "Success",
        2: "Failure",
        3: "Pending"
    },
    errorMsg:{
        wentwrong:'Something went wrong!',
        error_500:'Internal server error'
    },
    txn_status_filter:{
        SUCCESS:1,
        PROCESSING:3,
        FAILURE:2,
        ALL:[1,2,3]
    },
    txn_status_string:{
        Success: 1,
        Pending: 3,
        Failure: 2,
        ALL:[1,2,3]
    },
    paymentStatusMsg:{
            PAYMENT_SUCCESS:"PAYMENT_SUCCESS",
            PAYMENT_ERROR:"PAYMENT_ERROR",
            PAYMENT_PENDING:"PAYMENT_PENDING",
            PAYMENT_DECLINED:"PAYMENT_DECLINED",
            TIMED_OUT:"TIMED_OUT",
            PAYMENT_INITIATED:"PAYMENT_INITIATED",

    },
    
    paymentStatus:{
        SUCCESS : 1,
        FAILURE : 2,
        PROCESSING : 3
    },
    Field_Error_Msgs: {
        input_mobile: 'Enter valid mobile number'
    },
    investmentAmount:{
        maxAmt:1000000,
        minAmt:500,
    },
    kycStatus:{
        PENDING: 1,
        SUCCESS :2,
        FAILURE : 3
    },
    productType:{
        _13K:1,
        _12K:2,
        _10K:3,
    },
    // docType:{
    //     pan:1,
    //     aadhaarUnified:2,
    //     aadhaarFrontSide:3,
    //     aadhaarBackSide:4,
    //     profile:5,
    //     upload_GST_Certificate:6,
    // },
    docType:{
        pan: 1,
        aadhaarUnified: 2,
        aadhaarFrontSide: 3,
        aadhaarBackSide: 4,
        upload_GST_Certificate: 5,
        upload_NetWorth_Certificate: 6,
        COMPANY_AADHAAR_UNIFIED: 7,
        COMPANY_AADHAR_FRONTSIDE: 8,
        COMPANY_AADHAR_BACKSIDE: 9,
        PERSONAL_PAN: 10
        },

    investmentProductType:{
        _13K:3,
        _12K:2,
        _10K:1,
    },
    FormTypes: {
        SECTION: 1,
        INPUT: 2,
        NUMBER_INPUT: 3,
        DATE_INPUT: 4,
        SELECTIVE_INPUT: 5,
        SEARCH_INPUT: 6,
        ADDRESS_INPUT: 7,
        CUSTOM_ACTION_INPUT: 8,
        CUSTOM_RENDER: 9,
        MENU_LIST_INPUT: 10,
        REFERENCE_INPUT: 11,
        NUMBER_PICKER_INPUT: 12,
        OUTLINE_INPUT: 13,
        OUTLINE_NUMBER_INPUT: 14,
        OUTLINE_CUSTOM_WIDTH_INPUT: 15,
        OUTLINE_CAPTCHA_INPUT: 16,
        DATE_PICK_INPUT: 17,
        FILE: 18,
        LIST_INPUT_RENDER: 19,
        RADIO_INPUT: 20,
        BANK_NAME_MENU_LIST_INPUT:21,
        MENU_LIST_INPUT_DEFAULT:22,
        default_BANK_NAME_MENU_LIST_INPUT:23,
        OTP_INPUT: 24

    },
    highlighted_text_type: {
        new: 'New',
        good: 'Good',
        poor: 'Poor',
        average: 'Average',
        success: 'Success',
        processing: 'Processing',
        failed: 'Failed'
    },
    continue_with: "0% Margin, 100% Fair ",
    earn13: "Earn @ 13% ",
    borrow13: "Borrow @13%",
    safeSecure: "Safe & Secure",

    documentUpload: {
        pan: 'PAN',
        GSTCertificate:'GST Certificate',
        upload_pan: 'Tap & Upload PAN Card',
        aadhaar: 'Aadhaar',
        upload_aadhaar: ' Tap & Upload Aadhaar Card',
        upload_GST_Certificate:'Tap & Upload GST Certificate',
        singleDoc: 'Single Document',
        mulDoc: 'Multiple Document',
        front: 'Front',
        back: 'Back',
        next: 'Next',
        ConfirmIfsc:'Confirm IFSC',
        continue:'Continue',
        submit: 'Submit',
        NetWorthCertificate:'Net Worth Certificate',

    },
    paymentSuccess: {
        continue: 'Continue',
        congrats: 'Congratulations!',
        investment: 'Investment Successful',
        title: 'Payment',
        successtxt: 'Your investment will start generating interest within 2 working days.',
        successtxtAgreement:'Your E-Sign is Successful',
        failuretxtAgreement:'E-Sign Failed',
        bottomText:'You are all set up and ready to go!',
        failedtxt: "We're sorry we couldn't process your transaction, If your bank account is debited it will be refunded. Please reach out to us at ",
        processingText:'We are processing your transaction, do not press back or close the window.',
        oops: 'Oops !',
        investmentFailed: 'Investment Failed',
        failureDesc: 'Your entered amount could not be invested in your account',
        bottomFailureText: ()=>{
            return <p>
                There was an issue due to which your E-Sign could not be successful. Please reach out to <a style={{ textDecoration: "none",display:"inline" }} href="mailto:partnersupport@13karat.in"> partnersupport@13karat.in </a> for any support.
            </p>
        } 
        
    },
    referAndEarn: {
        earnUpto: 'Earn Upto ',
        YouWillGet: 'You Will Get',
        Friendwillget: 'Friend will get',
        youEarn_text_1: 'You earn upto 10% of your friends earnings',
        youEarn_text_2: 'Monthly cashback credit',
        friendEarn_text_1: 'You earn upto 10% of your friends earnings',
        friendEarn_text_2: 'Daily earnings, 0% TDS',
        invitelink_username: 'invitelink_username',
        Invite: 'Invite',
        referRewards:'Rewards',
        


    },
    Users: {
        get_OTP: 'Get OTP',
       get_Authentication:'Get Authentication',
       download: 'Download'
    }


}



export default Strings;
