import ReduxValues from '../ReduxValues'

let actionsStrings=ReduxValues.reduxActions

export const setFormSubmitFun=(handleSubmit)=>{
    return{
        type:actionsStrings.SET_FORM_SUBMIT_FUNCTION,
        handleSubmit
    }
}

export const setFromErrorHandler=(showError)=>{
    return{
        type:actionsStrings.SET_FORM_SHOW_ERROR_FUNCTION,
        showError
    }
}

export const setFormError=({formFieldName,msg,isvalid})=>{
    return{
      type:actionsStrings.SET_FROM_ERROR,
      formFieldName,
      msg,
      isvalid
    }
} 


export const setResetFormHandler=(fun)=>{
    return{
        type:actionsStrings.SET_RESET_FROM,
        fun
    }

}