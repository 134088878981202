import React, { useEffect, useState } from "react";
import CASHeUtils from "../../Utils/CASHeUtils";
import { Global } from "@emotion/react";
import { Translate } from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import { border, borderRadius } from "@mui/system";
import { styled } from "@mui/material/styles";
import Images from "../../Images/Images";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useDispatch, useSelector } from 'react-redux'
import { isLoading } from '../../Store/Actions/GlobalActions'
// import { logoutUser } from '../Store/Actions/restApiActions'
import { useNavigate } from "react-router-dom";

let authData = JSON.parse(localStorage.getItem('authData'))
const drawerBleeding = 2;


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid white',
    borderRadius: "10px 10px 0px 0px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    minHeight: 150,
    outline: 'none',
    width: 440,
    margin: theme.spacing(0, 2, 0),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column'

  },
}));


const POPUPModel = (props) => {
  const amountDetails = JSON.parse(sessionStorage.getItem('amountDetails'));
  const showBorder = props?.showBorder !== undefined ? props.showBorder : true;
  const showShadow = props?.showShadow !== undefined ? props.showShadow : true;

  const classes = useStyles();
  const dispatch = useDispatch();
  const globalState = useSelector(state => state.globalState)
  const navigate =  useNavigate()
  const [showHeaderLogo, setShowHeaderLogo]=useState(true)
  const [closeBtn,setCloseBtn] = useState(true)
 

  

  useEffect(()=>{
    if(props.showHeaderLogo===false || props.showHeaderLogo===true){
      setShowHeaderLogo(props.showHeaderLogo)
    }
  },[])

  useEffect(()=>{
    if(props.closeBtn===false || props.closeBtn===true){
      setCloseBtn(props.closeBtn)
    }
  },[])

 
  const ClipLoader = (flag) => {
    dispatch(isLoading(flag))
  }



const closeModal=()=>{
  props.onClose()

}
useEffect(()=>{
  if(props.showHeaderLogo===false || props.showHeaderLogo===true){
    setShowHeaderLogo(props.showHeaderLogo)
  }
},[])




  console.log(globalState)

  const RenderContent=()=>{

    return <div  style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',backgroundColor:'white',width:props.customHeaderWidth?props.customHeaderWidth:440,maxWidth:props.customHeaderMaxWidth?props.customHeaderMaxWidth:440,outline:'none',borderRadius:props.showRadius === false ?'0px':"10px",fontFamily:"Gilroy-SemiBold",margin:"auto"}}>
     <div  style={{position:'relative',height:props.customHeaderHeight?props.customHeaderHeight: 56,width:'100%',maxWidth:"450px", display:'flex',justifyContent:'space-between',alignItems:'center',fontFamily:"Gilroy-SemiBold",boxShadow:(  showShadow ? "-2px -6px 10px 6px #cecece":" "), borderBottom: (showBorder ? "1px solid #00000040" : "0px solid #00000040"),padding:"0px 16px",...props.CustomStyle}} >
             <div>
             {showHeaderLogo &&
            <img
            src={Images.karatHeaderLogo}
            alt="Modal image"
            style={{width:43,height:25, ...props.headerLogoStyles,border:`1px solid transparent`,padding:4,borderRadius:2}}
          />
          }

            {props.ShowHeaderText && (
              <span style={{ ...props.headerTextStyles}}>
                {props.headerText}
              </span>
            )}

{showHeaderLogo && 
            <img
            src={Images.karatHeaderLogo}
            alt="Modal image"
            style={{width:43,height:25, ...props.headerLogoStyles,border:`1px solid transparent`,padding:4,borderRadius:2}}
          />
          } 
             </div>
             {closeBtn &&
            <CloseIcon className="icon-style" onClick={closeModal} style={{color:props.crossBtnColor ? props.crossBtnColor:'Black'}} />}
      </div>

      <div style={{width:'100%'}}>
      {props.children}
      {props.innerChildData}
      </div>
   


  </div>
  }
  return (
    <React.Fragment>
   

       <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          
          {RenderContent()}
   
        </Fade>
      </Modal>
    </React.Fragment>
  );
}


export default POPUPModel;

