import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { isPOPOpen, isLoading} from '../../Store/Actions/GlobalActions'

import LogoutIcon from '@mui/icons-material/Logout';
import { logoutUser } from '../../Store/Actions/RestAPiActions';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

const LogoutSupportComponent = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const restApiState = useSelector(state => state.restApiState);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const ClipLoader = (flag) => {
         dispatch(isLoading(flag));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleLogout = () => {
        ClipLoader(true)
        dispatch(logoutUser({
            callback: (res) => {
                if (res && !res.isErrorOccure) {
                    console.log("logging out user:", res)
                    localStorage.removeItem('authData')
                    sessionStorage.clear()
                    ClipLoader(false)
                    setTimeout(() => {
                    ClipLoader(false)
                    navigate('/')
                    }, 1000);
                    // }
                } else if (res && res.isErrorOccure) {
                    ClipLoader(false)
                    dispatch(isPOPOpen({
                        msg: 'Logout failed!',
                        cancleBtntxt: "OK",
                        isOpen: true,
                        title: "Error"
                    }))
                }
            }
        }))

    }

    return (
        <>
        <div style={{display:'flex', justifyContent:'end', width:window.innerWidth-750}}>
                    <IconButton onClick={handleClick}>
                        <Avatar sx={{ width: '32px', height: '32px', bgcolor: '#F0F1FF', color: '#363978', margin: '0px 10px' }}>{restApiState?.fetchProfileResponse?.name?.charAt(0).toUpperCase()}</Avatar>
                    </IconButton>
                    
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        PaperProps={{ elevation: 1 }}  
                        onClose={handleClose}
                        className="custom-menu"
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        // style={{boxShadow:'none'}}
                    >
                        {/* <MenuItem className="custom-menu-item" onClick={() => {} }>
                            <ListItemIcon>
                                <HeadsetMicIcon style={{ color: "#333", padding: "2px", marginRight: "3px" }} />
                            </ListItemIcon>
                            <ListItemText>
                                Support
                            </ListItemText>
                        </MenuItem> 
                        <Divider variant="middle" component="li"/>*/}

                        <MenuItem className="custom-menu-item" onClick={() => { handleLogout() }}>
                            <ListItemIcon>
                                <LogoutIcon style={{ color: "#333", padding: "2px", marginRight: "3px" }} />
                            </ListItemIcon>
                            <ListItemText>
                                Logout
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                    </div>    
</>
//         <div style={{marginTop:10,display:'flex',flexDirection:'row',justifyContent:'end',alignItems:'right', cursor:'pointer'}}>
//         <LogoutIcon style={{ color: "#333", padding: "2px", marginRight: "3px" }} onClick={handleLogout} />
//   </div> 
    )

}

export default LogoutSupportComponent