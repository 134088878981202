// import NumberFormat from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import BuildProperties from '../BuildProperties'
import CryptoJS from 'crypto-js';
import {isMobile, isChrome, deviceDetect, isSafari, isIOS} from 'react-device-detect';


export const  DateTimeHandler={
    getminuteDifference:(m1,m2)=>{
        if(m1===m2){
            return 0;
        }
        return m1>m2?m1-m2:m2-m1
    },
    
     getDateTime:(timestamp)=>{
        //  if(typeof timestamp=="string"){
        //     timestamp=CASHeUtils.removeNonASCII(timestamp)
        //     timestamp=JSON.parse(timestamp)
        //  }
        if(timestamp){
            return new Date(timestamp *1000)
        }else{
            return new Date()
        }
    }
}

let ls = require('local-storage');

 
const CASHeUtils = {

     isArray(data){
        return Array.isArray(data);
      },
      
       getEmailid(emailId){
        let reqEmailId=''
        if(emailId && CASHeUtils.isArray(emailId) && emailId?.length>0){
          reqEmailId = emailId[0]
        }else if(emailId && CASHeUtils.isArray(emailId)&& emailId?.length == 0){
            reqEmailId = null
        }else if(emailId){
          reqEmailId = emailId
        }else{
          reqEmailId=''
        }
        return reqEmailId
      },
      
       getAddress(address){
        let reqAddress=''
        if(address && CASHeUtils.isArray(address) && address?.length>0){
          reqAddress =address[0].address
        }else if(address && CASHeUtils.isArray(address)&& address?.length == 0){
            reqAddress = null
        }else if(address && address?.length>0){
          reqAddress = address
        }else{
          reqAddress=''
        }
        return reqAddress
      },
      
      
       getPincode(address){
        let reqPincode=''
        if(address && CASHeUtils.isArray(address) && address?.length>0){
          reqPincode =address[0].pincode
        }else if(address && CASHeUtils.isArray(address)&& address?.length == 0){
           reqPincode = null
        }else if(address?.length>0){
          reqPincode = address
        }else{
          reqPincode=''
        }
        return reqPincode
      },

      
       getNameOfCustomerFromVpa(vpadata){
        if( vpadata && CASHeUtils.isArray(vpadata) && vpadata?.length>0){
          return vpadata[0]['vpaName']?vpadata[0]['vpaName']:null
        }else{
          return null
        }
      
      },





    roundDownValue(value){
        return Math.floor(value/1000)*1000
    },
    isMobileDevice () {
        return isMobile;
    },
    isIOSDevice () {
        return isIOS;
    },

    isSafari () {
        return isSafari
    },
    isIOS(){
        return isIOS
    },

    getBooleanPreference(key, onSuccess) {
        this.getObjectFromPreference(key, value => {
            if(typeof value === typeof true)
                onSuccess(value);
            else
                onSuccess(0);
        });
    },
    saveBooleanPreferences(key, value) {
        this.saveObjectToPreferences(key, value);
    },

    getObjectFromPreference(key, onSuccess) {
        this.getValueFromPreferences(key, value => onSuccess(JSON.parse(value)));
    },

    saveObjectToPreferences(key, value) {
        this.saveValueToPreferences(key, JSON.stringify(value));
    },

    getValueFromPreferences(key, onSuccess) {
        let value = ls.get(key);
        onSuccess(value);
    },

    saveValueToPreferences(key, value) {
        ls.set(key, value);
    },

    generateAutoCheckSum(data) {	
        var hash = CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex).toUpperCase();
        console.log('checksum',hash);
       return hash
   },


    getEncryptedText: (text, isHEX=true,) => {
    //   console.log("data to send to api",text)
        const key = CryptoJS.enc.Latin1.parse("+KbPeShVkYp3s6v9");
        const iv = CryptoJS.enc.Latin1.parse("6v9y$B&E)H@McQfT");
      
        let encrypted;
        let result;
      
        if (isHEX) {
          encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          });
          result = CryptoJS.enc.Base64.parse(encrypted.toString());
          console.log('Base64 to HEX:', result.toString(CryptoJS.enc.Hex));
        } else {
          encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
          });
          result = encrypted.toString();
        } 
        // console.log('Data to encrypt:', encrypted);
        return result.toString();
      },


getDecryptedText: (encryptedText, getSDKHashKey, ivFromAPI, isHEX=true,) => {
    // console.log('Data to decript:', encryptedText);
  
    let key = CryptoJS.enc.Latin1.parse("+KbPeShVkYp3s6v9");
    let iv = CryptoJS.enc.Latin1.parse("6v9y$B&E)H@McQfT");
  
    let decrypted = '';
  
    if (isHEX) {
      const encText = CryptoJS.enc.Hex.parse(encryptedText);
      const bytes = encText.toString(CryptoJS.enc.Base64);
      decrypted = CryptoJS.AES.decrypt(bytes, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    } else {
      decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, padding: CryptoJS.pad.ZeroPadding });
    }
    // Convert decrypted data to a string and remove extra spaces and signs
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  },
  

    removeNonASCII:(str)=>{
        return str.replaceAll('\x00','')
    },

    getCurrentDateUTCTimeStamp() {
        return moment.utc().valueOf();
    },

    getDateStringFromTimeStamp(timeStamp, format) {
        
        return moment.utc(timeStamp).format((format != null ? format : "DD/MM/YYYY"));
    },

    getDateToString(date) {
        return moment.utc(date).local().format('DD-MMM-YYYY')
    },
    
    getDaysDiff(start_date, end_date, date_format = 'DD/MM/YYYY') {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        };
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days');
    },
    removeAllWhiteSpaces:(value)=>{
        return value.replace(/\s/g,'')
    },
    overrideSpace:(value)=>{
        return value.replace(/ /g, '+');

    },
    parseString:(str)=>{
        return str.replace(/"/g,"")
    },
    removeBreakLines:(strValue)=>{
        return strValue.replace(/\s+/g, ' ').trim()
    },

    numberFormat: (value,appendValue="") => {
        return <NumericFormat value={value} displayType={'text'} thousandSeparator={true} prefix={`${appendValue}₹ `} />;
    },

    getTimeDifferenceInMinute:(timestamp)=>{
        if(timestamp){
            var oldtime = DateTimeHandler.getDateTime(timestamp); 
                var currenttime = DateTimeHandler.getDateTime(); 
                console.log('old=>', oldtime.toLocaleString(), 'new=>',currenttime.toLocaleString())
                if (currenttime < oldtime) {
                    currenttime.setDate(currenttime.getDate() + 1);
                }
                var diff = currenttime - oldtime;
                var msec = diff;
                var mm = Math.floor(msec / 1000 / 60);
                msec -= mm * 1000 * 60;
                return mm;
        }else{
            return null
        }
    },
   
    validateDateAndTime:(timestamp)=>{
            if(timestamp){
                var oldtime = DateTimeHandler.getDateTime(timestamp); 
                var currenttime = DateTimeHandler.getDateTime(); 
                console.log('oldTime=>', oldtime.toLocaleString(), 'newTime=>',currenttime.toLocaleString())
                if (currenttime < oldtime) {
                    currenttime.setDate(currenttime.getDate() + 1);
                }
                var diff = currenttime - oldtime;
                var msec = diff;
                var mm = Math.floor(msec / 1000 / 60);
                msec -= mm * 1000 * 60;
                console.log('time difference in minutes',mm,'M')
                if(currenttime>=oldtime && mm<=3){
                        return true
                }else{
                    return false
                }
            }else{
                return false
            }
    },

    isValidSmartEsingUrl:(timestamp)=>{
        if(timestamp){
            var oldtime = DateTimeHandler.getDateTime(timestamp); 
            var currenttime = DateTimeHandler.getDateTime(); 
            console.log(oldtime,currenttime)
            if(oldtime>currenttime){
                return true
            }else{
                return false
            }
        }

    },

    validateExpireTime:(timestamp)=>{
        if(timestamp){
            var oldtime = DateTimeHandler.getDateTime(timestamp); 
            var currenttime = DateTimeHandler.getDateTime(); 
            console.log('incomingtime=>', oldtime.toLocaleString(), 'currenttime=>',currenttime.toLocaleString())
            if ( oldtime >= currenttime) {
                return true
            }else{
                return false
            }
        }else{
            return false
        }

    },

    getTimeStamp:(myDate,format)=>{
        // var myDate = "26-02-2012";
        let newmyDate = myDate.split("-");
        var newDate;
        if(format){
            if(format==='dd/mm/yyyy'){
                newDate = new Date( newmyDate[2], newmyDate[1] - 1, newmyDate[0]);
           }else if(format==='yyyy/mm/dd'){
                newDate = new Date( newmyDate[0], newmyDate[1] - 1, newmyDate[2]);
           }else{
                newDate = new Date( newmyDate[2], newmyDate[1] - 1, newmyDate[0]);
           }
        }else{
            newDate = new Date( newmyDate[2], newmyDate[1] - 1, newmyDate[0]);
        }
       
        console.log(newDate.getTime());
        return newDate.getTime()
    },
    
}



export default CASHeUtils
