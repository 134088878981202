const ApiConstants = {
    
        portalProfileDetailsApi: "/ifa/user/user-detail",
    
     localServerURL: 'http://localhost:3000',
    //Unsecure URL

    // localServerURL: "http://13karat-qa.cashe.co.in",
    // qaURL: 'http://13karat-qa.cashe.co.in',
    // stageURL: 'http://13karat-stage.cashe.co.in',
    // prodURL: 'http://13karat-prod.cashe.co.in',

    //Secure URL
    
    //localServerURL: "https://qabe.13karat.in",
    qaURL: 'https://qabe.13karat.in',
    stageURL: 'https://stagebe.13karat.in',
    prodURL: 'https://prodbe.13karat.in',
    preProdURL: 'https://preprodbe.13karat.in',
    qaHostUrl: 'https://qa-agent.dashboard.cashe.co.in/',
    localHostUrl: 'localhost:3000',
    baseURL: "https://qaepdashboard.cashe.co.in",
 
    triggerOTP: "/user/native-otp-trigger",
    whatsappAuthentication: "/ifa/user/auth/",
    verifyOTP: "/user/native-otp-verify",
    userProfileDetailsApi: "/ifa/user-detail",
    uploadDoc:"/ifa/doc/",
    portalUploadDoc:"/ifa/user/doc/",
    poralNetWorthAPI:"/ifa/user/networth-doc/",
    getDoc:"/ifa/doc-fetch",
    getUserDoc:"/ifa/user/doc-fetch",
    getNetWorthUserDoc:"/ifa/user/networth-doc-fetch",
    bankList: "/ifa/banks-list",
    portalbankList: '/userprofile/banks-list',
    bankDataApi: "/ifa/bank-account",
    portalBankDataApi: "/ifa/user/bank-account",
    createEsignRequest: "/ifa/esign",
    esignDownload: "/ifa/util?type=esign-pdf",
    logout:'/user/logout/',
    faqDataGet:'/ifa/faq',
    bankIfsccode:'/userprofile/bank-ifsc-code',
    GetStateList:"/userprofile/state-city-list",
    GetCityList:"/userprofile/state-city-list",
    GetBranchIfsc:"/userprofile/bank-branch-ifsc",
    txnHistory:"/ifa/transactions/txn-history/",
    FatchUserTxnHistory:"/ifa/user/user-list/",
    UserNotifications:"/ifa/user/notifications",
    UpcomingMaturity:"/ifa/transactions/upcoming-maturity/",
    payOrder:"/ifa/transactions/pay-order/",
    createInvestment:'/ifa/investment/investment-request/',
    PdfCertificate:'/ifa/util/media-download/',
    ReinvestmentPreference:'/ifa/investment/reinvestment-preference',
    bankAutofetchPortal:"/ifa/user/bank-account",
    perviousTransactions:"/ifa/transactions/pervious-transactions/",
    CustomerNetWorthUrl:"/ifa/user/invest-stats",
    // nominee api url
    fetchRelationDetails:"/ifa/user/nominee-details",

    CancelPayment:"/ifa/transactions/payment-cancellation/",
    // password login api url
    passwordLogin:"/user/password-login",
    updatePassword:'/user/update-password',
    
}


export default ApiConstants
