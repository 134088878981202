import ApiConstants from "./ApiConstant";

const Values = {
    PAGINATION_PER_PAGE_COUNT:20,
    MOBILE_NUMBER_LENGTH: 10,
    PAN_NUMBER_LENGTH: 10,
    WORK_EXPERIENCE_LENGTH: 2,
    AADHAAR_NUMBER_LENGTH: 12,
    NET_SALARY_MAX_LENGTH: 6,
    SEARCH_INPUT_MAX_LENGTH: 128,
    OFFICIAL_ADDRESS: 3,
    ADDRESS_LENGTH: 512,
    PIN_CODE_LENGTH: 6,
    ACCOUNT_NUMBER_MAX_LENGTH: 18,
    ACCOUNT_NUMBER_MIN_LENGTH: 5,
    IFSC_CODE_MAX_LENGTH: 11,
    RUPEE_SYMBOL: '\u20B9 ',
    eligibleCities: [],

    PROFILE_PROOF_ID: 1,
    PAN_CARD_PROOF_ID: 4,
    AADHAAR_PROOF_ID: 12,
    EMPLOYEE_BADGE_PROOF_ID: 8,
    SALARY_SLIP_PROOF_ID: 3,
    BANK_STATEMENT_PROOF_ID: 5,
    RESIDENTIAL_PROOF_ID: 2,
    OFFER_APPOINTMENT_LETTER_PROOF_ID: 32,
    SMANDATE_ID: 14,
    LANDLINE_NUMBER_LENGTH: 11,

    VOTERS_ID_MIN_LENGTH: 5,
    VOTERS_ID_MAX_LENGTH: 20,
    DRIVING_LICENCE_MIN_LENGTH: 5,
    DRIVING_LICENCE_MAX_LENGTH: 20,

    profile: 'profile',
    smandate: 'sMandate',
    bankstatement: 'bankStatement',
    address_Txt: 'address',
    payslip: 'payslip',
    empbadge: 'empBadge',
    aadharcard: 'aadharcard',
    pancard: 'pancard',
    offeraAppointmentletter: 'OfferOrAppointmentLetter',

    UTILITY_BILL: 'Utility Bill',
    TELEPHONE_BILL: 'Telephone Bill',
    PASSPORT: 'Passport',
    VOTERS_ID: 'Voter ID',
    DRIVING_LICENSE: 'Driving License',
    RENTAL_AGREEMENT: 'Rental Agreement',
    AADHAAR_CARD: 'Aadhar Card',
    COMPANY_HR_LETTER: 'Company HR Letter',
    HOUSE_PURCHASE_AGREEMENT_LETTER: 'House Purchase Agreement Letter',

    UTILITY_BILL_PROOF_ID: 2,
    TELEPHONE_BILL_PROOF_ID: 3,
    PASSPORT_PROOF_ID: 4,
    VOTERS_ID_PROOF_ID: 5,
    DRIVING_LICENSE_PROOF_ID: 6,
    RENTAL_AGREEMENT_PROOF_ID: 7,
    AADHAAR_CURRENT_ADDRESS_PROOF: 1,
    COMPANY_HR_LETTER_PROOF_ID: 8,
    HOUSE_PURCHASE_AGREEMENT_LETTER_PROOF_ID: 9,
    DOB_MIN_YEARS_LIMIT: 18,
    DOB_MAX_YEARS_LIMIT:80,
    DOJ_MAX_YEARS_LIMIT:40,

    pendingApproval: 'Pending Approval',
    verificationFailed: 'Verification failed',
    rejected: "Rejected",
    application_type: "GPAY_APP",
    credit_line_max_limit: "Rs 2,00,000",

    aadhaar_reason: 'Customer opted mobile number not linked',

    captcha_not_generated: "Captcha not generated",
    UIDAI_reported_mobile_no_not_linked: "UIDAI reported mobile number not linked",
    logglyProdKey: 'e0235414-466a-48f3-a52a-10581b58b60a',
    logglyDevKey: '0baa2073-3a2c-4214-8198-8cbb417cc057',

    customer_status_ids: {
        NEW: 1,
        DATA_RECEIVED: 2,
        VERIFICATION_IN_PROGRESS: 3,
        PARTIALLY_VERIFIED: 4,
        VERIFICATION_FAILED: 5,
        CREDIT_CHECK_PENDING: 6,
        CREDIT_APPROVED: 7,
        CREDIT_DECLINED: 8,
        CASH_REQUESTED: 9,
        DEFAULTED: 10,
        PERMANENT_BLOCK: 11,
        DATA_RECEIVED_P1: 12,
        DATA_RECEIVED_P2: 13,
        DATA_RECEIVED_P3: 14,
        PAYMENT_OVERDUE: 15,
        TEMPORARY_BLOCK: 16,
        DATA_RECEIVED_P0: 17,
        DEFAULT_BLOCK: 18,
    },

    customer_tag_ids: {
        OPS_ON_HOLD: 1,
        MIGRATION_IN_PROGRESS: 3,
        GROWTH_ON_HOLD: 5,
        PERFIOS_REPORT_REQUIRED: 8,
        EMANDATE_REQUIRED: 13,
        SMANDATE_REQUIRED: 18,
        DISBURSEMENT_ON_HOLD: 24,
        LOAN_AGREEMENT_REQUIRED : 27,
        INSURANCE_CONSENT_REQUIRED: 28,
        NON_COMPLIANCE: 30,
        MSWIPE_AUTO_APPROVED: 31,
        MSWIPE_MERCHANT: 34,
        OFFICIAL_EMAIL_VERIFICATION_PENDING: 39,
        VKYC_REQUIRED: 44,
        LOAN_CONSENT_REQUIRED: 45,
        PMANDATE_REQUIRED: 55,
        PMANDATE_INPROGRESS: 56,
        EKYC_REQUIRED_VOTERID: 78,
        EKYC_REQUIRED_DRIVING_LICENSE: 79,
        CKYC_OBTAINED: 80
    },

    shadowStyle: {
        shadowOffset: {width: 1, height: 1},
        shadowColor: 'black',
        shadowOpacity: 0.3,
        elevation: 4
    },

    loan_types: {
        CASHe_15: 2,
        CASHe_30: 4,
        CASHe_30_Plus_30: 8,
        CASHe_60: 17,
        CASHe_90: 5,
        CASHe_90_Plus_30: 7,
        CASHe_180: 6,
        CASHe_270: 18,
        CASHe_1_Year: 9,
        CASHe_MSwipe: 10,
        CASHe_MSwipe_Merchant: 13,
        CASHe_ShopX_MCard: 14,
        CASHe_Yaantra_90: 11,
        CASHe_Yaantra_180: 12,
        CASHe_Home_Credit_62: 20,
        CASHe_Home_Credit_90: 16,
        CASHe_Home_Credit_180: 15,
        CASHe_Home_Credit_270: 21,
        CASHe_Home_Credit_360: 19,
        CASHe_GP_90: 32
    },

    loan_categories: {
        cashe_loan: 1,
        loan_lead: 2,
        base_emi_loan: 3,
        emi_card_loan: 4,
        hci_loan: 5,
        credit_line: 7
    },

    params: {
        credit_details: 'CREDIT_DETAILS',
        POPUP_TYPE: 'POPUP_TYPE',
    },

    social_data: {
        SOCIAL_DATA_IDENTITY_DETAILS: 'gpayIdentityDetails'
    },

    error_types: {
        NO_ERROR: 0,
        NETWORK: 1,
        CONNECTION_TIME_OUT: 2,
        HTTP_ERROR_UNAUTHORIZED: 3,
        HTTP_ERROR: 4,
        EXCEPTION_IN_CODE: 5
    },

    toast_types: {
       SUCCESS: 'success',
       ERROR: 'error',
       WARNING: 'warning',
       INFO: 'info'
    },

    emadateTimer: {
        interval: 30000,
        timeOut: 300000
    },

    PopupType: {
        PERFIOS: 1,
        RAZOR_PAY: 2,
        S_MANDATE: 3,
        P_MANDATE: 4,
    },

  
    casheHashKeyQA: "1C3M6DE6B5B0503A1C3M6DE6B5B0503A",
    casheIVRKeyQA: "1C3M6DE6B5B05B3A1C3M6DE6B5B0503A",
    // casheHashKeyPROD: ApiConstants.CASHE_HASH_KEY_PROD,
    // casheIVRKeyPROD: ApiConstants.CASHE_IVR_PROD,
    // privacyPolicyBhanix: ApiConstants.privacyPolicyBhanix,

  


    PREFERENCES: {
        SHOW_CREDIT_LINE_APPROVED_SCREEN: 'SHOW_CREDIT_LINE_APPROVED_SCREEN',
        SHOW_WHATSAPP_CONSENT: 'SHOW_WHATSAPP_CONSENT',

    },

    customerType: {
        RETAIL: 1
    },
    TncUpdateDateValue:{
    Date:"2023-12-13T13:00:38.504267",
    },

    sideBarTabsID:{
        Dashboard: 1,
        Users:2,
        Transactions: 3,
        FundManagement: 4,
        Faqs: 5,
        Notifications: 6
       
}
    
    
    
};

export default Values;
