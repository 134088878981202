
// export default (state= {},actions)=>{
//     switch(actions.type){
//         default:return state;
//     }
// }
import CASHeUtils from '../../Utils/CASHeUtils'

import ReduxValues from "../../Store/ReduxValues";

const intialState={
  showSideDrawer:CASHeUtils.isMobileDevice()?false: true,
  currentTabName:"Dashboard",
  searchMobileNumber:null,
  searchName:null,
  SelectedSortOption:"",
  SelectedOption:"",
  selectpageno:1,

    selectedCompanyData:null,
    bankName:'',
    gstNumber:'',
    gstDoc:null,
    esignStatus:'',
    aadharDoc:null,
    popUpModel: {
      isOpen: false,
      msg: null,
      btntxt: null,
      cancleBtntxt: null,
      successHandler: null,
      failureHandler: null,
      showRedirection: false,
      delay: 3000,
      navigatetohome:false,
      shouldLogout:false,
      customImg:null,
      isLoading: false,
     
      
    },
    netWorthDocument:null,
    isDigital_NEFT_RTGS_Cheque_Payment:false,
    isAddNewUser:false,
    setInvestmentValue:false,
    setInvestmentAmountValue:"",
    isUserProfileCompleted: false,
    isUserDocCompleted: false,
    isUserBankCompleted: false,
    isStepCountStatus:1,
    currentSelectedTxnDataForModal:null,
    currentSelectedUserDataForModal: null,
    fieldDisabled: false,
    paymentCancelled: false,
    commonFilterData:{start_date:null,end_date:null,status:null,prodyctType:null,txnType:null},
}


const GlobalStateReducer=(oldstate=intialState,actions)=>{
    switch(actions.type){
  
      case  ReduxValues.RestApiActions.setSearchMobileNumber:return{
        ...oldstate,
        searchMobileNumber:actions?.payload
      }
      case ReduxValues.RestApiActions.setSearchName:return{
        ...oldstate,
        searchName:actions?.payload
      }
      case ReduxValues.RestApiActions.setcurrentpage: 
      return {
        ...oldstate,
        selectpageno: actions?.payload
      }
      case ReduxValues.RestApiActions.setSelectedOption: 
      return {
        ...oldstate,
        SelectedOption: actions?.payload
      }
      case ReduxValues.RestApiActions.setSelectedSortOption: 
      return {
        ...oldstate,
        SelectedSortOption: actions?.payload
      }
    
      case  ReduxValues.RestApiActions.setFilterData:return{
        ...oldstate,
        commonFilterData:{
          start_date:actions.payload?.start_date|| oldstate.commonFilterData.start_date,
          end_date:actions.payload?.end_date|| oldstate.commonFilterData.end_date,
          status:actions.payload?.status|| oldstate.commonFilterData.status,
          prodyctType:actions.payload?.prodyctType|| oldstate.commonFilterData.prodyctType,
          txnType:actions.payload?.txnType|| oldstate.commonFilterData.txnType,
        }

      }
      case  ReduxValues.RestApiActions.ResetCommonFilterData:return{
        ...oldstate,
        commonFilterData:{
          start_date:null,
          end_date:null,
          status:null,
          prodyctType:null,
          txnType:null,
        },
        searchMobileNumber:null,
        searchName:null,      
      }
      case  ReduxValues.RestApiActions.setIsCurrentSelectedTxnDataForModal:return{
        ...oldstate,
        currentSelectedTxnDataForModal:actions?.payload
      }
      case  ReduxValues.RestApiActions.setIsCurrentSelectedUserDataForModal:return{
        ...oldstate,
        currentSelectedUserDataForModal:actions?.payload
      }
      case  ReduxValues.RestApiActions.setFieldDisabled:return{
        ...oldstate,
        fieldDisabled:actions?.payload
      }
      case  ReduxValues.RestApiActions.setAddNewUserHandler:return{
        ...oldstate,
        isAddNewUser:actions?.payload
      }
      case  ReduxValues.RestApiActions.setIsUserProfileCompleted:return{
        ...oldstate,
        isUserProfileCompleted:actions?.payload
      }
      case  ReduxValues.RestApiActions.setIsUserDocCompleted:return{
        ...oldstate,
        isUserDocCompleted:actions?.payload
      }
      case  ReduxValues.RestApiActions.setIsUserBankCompleted:return{
        ...oldstate,
        isUserBankCompleted:actions?.payload
      }
      case  ReduxValues.RestApiActions.isStepCountStatus:return{
        ...oldstate,
        isStepCountStatus:actions?.payload
      }
      case ReduxValues.RestApiActions.isDigital_NEFT_RTGS_Cheque_Payment:return{
        ...oldstate,
        isDigital_NEFT_RTGS_Cheque_Payment:actions?.payload
      }
      case ReduxValues.RestApiActions.GST_DOC:return {
          ...oldstate,
          gstDoc: actions?.gstDoc 
        }
        case ReduxValues.RestApiActions.netWorthDocument:return {
          ...oldstate,
          netWorthDocument: actions?.netWorthDocument 
        }
      case ReduxValues.RestApiActions.SET_BANK_NAME_VALUE:
        return{
          ...oldstate,
          bankName: actions.bankName
        }
        case ReduxValues.reduxActions.selectedCompanyData:return{
            ...oldstate,
            selectedCompanyData:actions.data
        }

        case ReduxValues.RestApiActions.ESIGN_STATUS:
          return{
            ...oldstate,
            esignStatus: actions.esignStatus
        }

        case ReduxValues.RestApiActions.SET_AADHAR_NUMBER:
            return {
              ...oldstate,
              aadharNumber: actions.aadharNumber
            }

        case ReduxValues.RestApiActions.SET_USER_AADHAR_NUMBER:
          return {
            ...oldstate,
            userAadharNumber: actions.userAadharNumber
          }    
            case ReduxValues.RestApiActions.SET_GST_NUMBER:
                return {
                  ...oldstate,
                  gstNumber: actions.gstNumber
                }
            case ReduxValues.RestApiActions.PAN_DOC:

            return {
              ...oldstate,
              panDoc: actions.panDoc ? actions.panDoc : oldstate.panDoc
            }
          
          case  ReduxValues.RestApiActions.PROFILE_DOC:
            return{
              ...oldstate,
              profileDoc: actions.profileDoc?actions.profileDoc:oldstate.profileDoc
          }

          case ReduxValues.globalActions.SHOW_SIDE_BAR:
            return {
                ...oldstate,
                showSideDrawer: actions.flag

            }

            case ReduxValues.globalActions.SHOW_CURRENT_TAB_NAME:

            return {
               ...oldstate,
               currentTabName:actions.payload
   
            }
            case ReduxValues.globalActions.SHOW_BREADCRUMBS_LIST:
            return {
               ...oldstate,
               breadcrumbsList:actions.payload
            }
            case ReduxValues.RestApiActions.FILTER_PAYLOAD:
              return{
                ...oldstate,
                // filterPayload: actions.payload?actions.payload:oldstate.filterPayload
                filterPayload:actions.payload
              }
              case ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA_FILTER:
                return{
                  ...oldstate,
                  // filterPayload: actions.payload?actions.payload:oldstate.filterPayload
                  filterPayload:actions.payload
                }
                case ReduxValues.RestApiActions.SETINVESTMENTVALUE:
                  // console.log("reduser profile api", ReduxValues.RestApiActions.profileDataAPi)
                  return {
                      ...oldstate,
                      setInvestmentValue: actions.payload
                  }
                  case ReduxValues.RestApiActions.SETINVESTMENTAMOUNTVALUE:
                    // console.log("reduser profile api", ReduxValues.RestApiActions.profileDataAPi)
                    return {
                        ...oldstate,
                        setInvestmentAmountValue: actions.payload
                    }
                    case ReduxValues.RestApiActions.CANCELPAYMENT:
                      // console.log("reduser profile api", ReduxValues.RestApiActions.profileDataAPi)
                      return {
                          ...oldstate,
                          paymentCancelled: actions.payload
                      }      
                       
          case ReduxValues.RestApiActions.RESET_USER_DOC:
            return {
              ...oldstate,
              aadharDoc: null,
              gstNumber:'',
              gstDoc:null,
              panDoc:null,
              aadharNumber:'',
              userAadharNumber:'',
              netWorthDocument:null
            }  
          case ReduxValues.RestApiActions.AADHAR_DOC:
              return{
                    ...oldstate,
                    aadharDoc:{
                        front:actions.aadharDocFront || actions.aadharDocFront === null?actions.aadharDocFront:oldstate?.aadharDoc?.front || null,
                        back:actions.aadharDocBack || actions.aadharDocBack === null?actions.aadharDocBack:oldstate?.aadharDoc?.back || null,
                    }
                }     
                case 'SET_IS_LOADING': return {
                  ...oldstate,
                  isLoading: actions.isLoading
                }
                case 'SET_IS_POP_OPEN': return {
                  ...oldstate,
                  popUpModel: {
                    isOpen: actions.isOpen ? actions.isOpen : false,
                    msg: actions.msg ? actions.msg : null,
                    btntxt: actions.btntxt ? actions.btntxt : null,
                    cancleBtntxt: actions.cancleBtntxt ? actions.cancleBtntxt : null,
                    successHandler: actions.successHandler ? actions.successHandler : null,
                    failureHandler: actions.failureHandler ? actions.failureHandler : null,
                    showRedirection: actions.showRedirection ? actions.showRedirection : false,
                    navigatetohome:actions.navigatetohome ? actions.navigatetohome : false,
                    delay: actions.delay,
                    shouldLogout:actions.shouldLogout?actions.shouldLogout:false,
                    customImg:actions.customImg,
                    title:actions.title || "",
                    PopUpFooterText:actions.PopUpFooterText||"",
                    ReachOutFooterText:actions.ReachOutFooterText ? actions.ReachOutFooterText : false,
                  }
                }
             
          // case ReduxValues.RestApiActions.GST_DOC:
          //   return {
          //     ...oldstate,
          //     gstDoc: actions.gstDoc ? actions.gstDoc : oldstate.gstDoc
          //   }
                
        default:return oldstate
    }
    
}


export default GlobalStateReducer;