

const mobileRegExp = new RegExp("^[6-9][0-9]{9}$");
const banckAccountRegexp = new RegExp("^[0-9]+")
const mobileOTPRegExp = new RegExp('[0-9]{4}');
const emiAmountRegExp = new RegExp("^[0-9]+");
const emiTenureRegExp = new RegExp('^[0-9]+');
const nameRegExp = new RegExp("^[A-Za-z\\s]+");
//const nameRegExp = new RegExp('/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/ ///^[a-zA-Z ]+$/');
const addressRegExp = new RegExp('^[a-zA-Z0-9-,. #&]{10,512}$');
const GSTNRegExp = new RegExp('^[A-Za-z0-9]*$');

const serialNumberExp = new RegExp('[A-Za-z\-]{5}[0-9]')
const emailRegExp = new RegExp("[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}" +
    "\\@" +
    "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}" +
    "(" +
    "\\." +
    "[a-zA-Z][a-zA-Z\\-]{1,25}" +
    ")+");

const panRegExp = new RegExp('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}');
const upiIDRegExp = new RegExp('[a-zA-Z0-9\.\-]{2,256}\@[a-zA-Z][a-zA-Z]{2,64}')
const dobRegExp = new RegExp('/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/') //valid for DD/MM/YYYY or DD-MM-YYYY
const dobRegExp2 = new RegExp('/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/') //valid for DD/MM/YYYY or DD-MM-YYYY


const numbersRegExp = new RegExp('^[0-9]+$');
const generalRegExpx = new RegExp('^[a-zA-Z0-9,!@#$%^&*()_+:;\"\'<>.?/ -]+$')//('/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/ ///^[a-zA-Z ]+$/');

const pinCodeRegExp = new RegExp('^[1-9][0-9]{5}$');

const checkRegExp = (regExp, value) => {
    value = value.toString();
    let array = regExp.exec(value);
    return array && array[0] === value;
}

const textRegExp = (regExp, value) => {
    const pattern = /^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/
    let flag = pattern.test(value.toString())
    console.log(flag)
    return flag;
}
const checkAmountRegExp = (regExp, value) => {
    let isnum = regExp.test(value)
    return isnum;
}

const getLoanId = (loanType) => {
    switch (loanType) {
        case '90': return 62
        case '180': return 63
        case '270': return 64
        default: return 0
    }
}

const CASHeValidations = {

    isNumber: (str) => {
        return /^[0-9]+$/.test(str)
    },

    isValidAmount: (str) => {
        var re = /^\d+(\.\d{1,2})?$/
        return re.test(str)
    },

    isStringHasNumbers: (val) => {
        let isnum = /^\d+$/.test(val);
        // var regexp = /^\d+\.\d{0,2}$/.test(val);
        return isnum
    },

    validateEMIAmount: (value) => {
        return value && checkAmountRegExp(emiAmountRegExp, value);
    },

    validateEMITenure: (value) => {
        return value && checkRegExp(emiTenureRegExp, value);
    },
    validateMobileNumber: (value) => {
        return value && checkRegExp(mobileRegExp, value);
    },

    isPartnerSelected: (value) => {
        return value && value.toString().length > 0;
    },
    validateMobileOTP: (value) => {
        return value && checkRegExp(mobileOTPRegExp, value);
    },

    validTenure: (value) => {
        let tenure = ['0', '3', '6', '9', '12']
        return tenure.includes(value.toString());
    },
    validLoanId: (loanType) => {
        return getLoanId(loanType.toString())
    },

    validateName: (value) => {
        return value && checkRegExp(nameRegExp, value) && value.length >= 1;
    },

    validateStoreName: (value) => {
        value = value && value.trim();
        return value && value.length >= 5 && value.length < 50;
        // return value && checkRegExp(nameRegExp, value) && value.length>=5 && value.length<50;
    },

    validateQRSerialNumber: (value) => {
        return value && value.toString().length > 0;
    },

    validateGSTNNumber: (value) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (value) {
            return !(/^[A-Za-z0-9]/.test(value) && /\d/.test(value) && !specialChars.test(value))
        } else {
            return true
        }
    },

    validateStorePhoto: (value) => {
        return value && value.length > 0
    },

    validateBankholderName: (value) => {
        value = value && value.trim();
        return value && value.length >= 3;
    },
    validateBankAccountNumber: (value) => {
        return value && checkRegExp(banckAccountRegexp, value) && value.toString().length > 5
    },
    validateReenterBankAccountNumber: (value1, value2) => {
        return value2 && value1 === value2
    },
    validateBankIFSCNumber: (value) => {
        value = value && value.trim();
        return value && value.length === 11;

        // return /^[a-zA-Z0-9\\s]+$/.test(value) && value.length>=4;
    },

    validateAccountType: (value) => {
        value = value && value.trim();
        return value && value.length > 0;

        // return /^[a-zA-Z0-9\\s]+$/.test(value) && value.length>=4;
    },

    validTrackingAgentId: (value) => {
        return value && value.length > 2;
    },
    validTrackingSerialNo: (value) => {
        const pattern = /^([a-zA-Z]{5})$/


        return value && /[a-zA-Z]{5}/.test(value) && /_/.test(value) && /[0-9]+/.test(value)
    },
    validTrackingMobileNo: (value) => {
        return value && value.length > 0;
    },
    validateBankName: (value) => {
        return value && value.length > 0
    },

    validatePincode: (value) => {
        return value && checkRegExp(pinCodeRegExp, value);
    },

    validateMerchantAddress: (value) => {
        value = value && value.trim();
        return value && value.toString().length > 4
    },

    validateEmail: (value) => {
        return value && checkRegExp(emailRegExp, value);
    },
    validatePAN: (value) => {
        let pan_number = sessionStorage.getItem('pan_number');
        let existingData = sessionStorage.getItem('fetchPortalUserProfileResponse')
        let existingPanNumber;
        if(existingData) {
            existingPanNumber = JSON.parse(existingData).pan_number

        }

        if (pan_number) {
            return true
        } else if (existingPanNumber) {
            return true
        } else {    
            return value && checkRegExp(panRegExp, value);
        }
    },
    validateUPIId: (value) => {
        return value && checkRegExp(upiIDRegExp, value);
    },

    getOddMonth: (month) => {
        let reqArr = ['1', '3', '5', '7', '8', '10', '12']
        if (reqArr.includes(month)) {
            return 'odd'
        } else if (month == 2) {
            return 'leap'
        } else {
            return 'even'
        }
    },
    validateDOB: (valueOld) => {
        console.log(valueOld)
        const value = new Date(valueOld);
        // const value=dateObject;
        console.log(value, value.getMonth())
        let dd = new Date()
        let existingData = sessionStorage.getItem('fetchPortalUserProfileResponse')
        let existingDOB = JSON.parse(existingData)?.date_of_birth
        if(existingDOB) {
            return true
        }
        else {
        // return value == 'Invalid Date' ? false : value && value.toLocaleDateString().length > 0 && value.getFullYear() <= dd.getFullYear() && value.getFullYear().toString().length == 4 && value.getMonth() < 12 && (CASHeValidations.getOddMonth((value.getMonth() + 1).toString()) === 'odd' && value.getDate() <= 31 || CASHeValidations.getOddMonth((value.getMonth() + 1).toString()) === 'even' && value.getDate() <= 30 || CASHeValidations.getOddMonth((value.getMonth() + 1).toString()) === 'leap' && value.getDate() <= 29)
        return value == 'Invalid Date' ? false : value && value.toLocaleDateString().length > 0 && value.getFullYear() <= dd.getFullYear() && (value.getFullYear() >= dd.getFullYear() - 100 && value.getFullYear() <= dd.getFullYear() - 18)
        }
    },
    validateAadhaar: (value) => {
        let vv = value && value.replace(" ", '')
        return vv.length <= 13
        // return CASHeValidations.validateForNumbers(vv) && vv.toString().length <= 25 || vv ==='' ;
    },

    // validateGSTNNumber: (value) => {
    //     console.log("validateGSTNNumber",value)
    //     // let gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/
    //     // const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    //     // if (value) {
    //     //     return !(/^[A-Za-z0-9]/.test(value) && /\d/.test(value) && !specialChars.test(value))
    //     // } else {
    //     //     return true
    //     // }
    //     const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    //     if(value){
    //         return  !(/^[A-Za-z0-9]/.test(value) && /\d/.test(value) && !specialChars.test(value))
    //     }else{
    //         return true
    //     }
    // },

    validateForNumbers: (value) => {
        return value && checkRegExp(numbersRegExp, value);
    },

    validateCaptcha: (value) => {
        return value && value.toString().length > 3;
    },

    validateGender: (value) => {
        return value && !!value
    },

    validateEmploymenType: (value) => {
        return value && !!value
    },

    validateAnnualIncome: (value) => {
        return value && !!value
    },
    validateRelatioType: (value) => {
        return value && !!value
    },
    validateAllTF: (value) => {
        return value && checkRegExp(generalRegExpx, value);
    },
}


export default CASHeValidations;
