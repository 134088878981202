
const ReduxValues = {
    authData: {
        PROD_client_id:"D02IATWQ1EptKGbgmg6lHWNHMXYkZ3K54By8dOKC",
        PROD_client_secret:"QijBU9mK4WQK9XizEfS0XUfRTitdE9nEGlH3UYXEINA9J0kfOLtBwAmkHrZBG6Y4ulpQ452Ex4R4JEiyzYn3rfVPaHMHA0CILcNbMPUOblp41BtAPGUKtzwkHJSxuk3K",

        QA_client_id:"Bfz9uFJioQjWEgKHFlCDQLgp69htkVTNWsRS1HXW",
        QA_client_secret:"3FGahNdkGedxw7Heu0uwxPL64TgSEezuaXJQ84h3gM2I4hPBLSPziM2IEeh626bd3TGLLIwAjA3oZxJCAel0MKXo6SHEL92sr8SPRH48m2b1DQ916BZcENFD7rOq2BmT",

        client_id: "IZIZIVmTZpms1xZaHv9H4fiahe1QTOMgwHYgnwUv",
        client_secret: "q80TQKRtenoAIObIczF2Fz5eEZY4MziGhEgi9avSLJ1u9Y0aA0hsAS52zFe35Bh8cY3KgecdCzvlczgpJ3qjqtHsEBzM11MV9RuNAq4eior5QNRHRJHs9HAPdoKrzwh8"
        // QA_client_id:"4LuGoObdcqSJmOsjHmecjmp7jmjGhiupVucXnszm",
        // QA_client_secret:"irYjRKfkcfoyef3c19kIgxGhkAc547JeOEtXFOhXcJInVqjgjeu8q9roFXHLNFE2ttDIzihoPguJhrjLgVBrbjrNQcUtPTeYrEvry8N6iI1FpN7RJOiswInRNdz4ma36",
        // client_id: "X1B0RoimTZTHCnikMxmBDaxeB9eppcLldN4Vkn1A",
        // client_secret: "O1XU4tyYYHYEk2DW8woROFxrlM3bVY0bW2QBnJBT6iqs9BLw1KhhG5nZLlWWtwVUbth9VVV292aCE7CKN4yv5kTHxeK6ILOfuYZ3Ms6dM046tvTEje5VSiLXRRCqri2V"
    
    },

    globalActions: {
        SHOW_SIDE_BAR:"SHOW_SIDE_BAR",
        SHOW_DESKTOP_SIDE_BAR:"SHOW_DESKTOP_SIDE_BAR",
        SHOW_CURRENT_TAB_NAME:'SHOW_CURRENT_TAB_NAME',
        SHOW_BREADCRUMBS_LIST:'SHOW_BREADCRUMBS_LIST',

    },

    phonePayAuthData:{
        client_id:"loA6Y5mYtuYm0XXKfDjirmwKxNoiaw4R5X3J8Aif",
        client_secret:"gsmzPfHp700tH9vzvr2nJpRKSQ4qC4fV4syz977nLLHlBgNe1V4MY6zuiNcoEVqYj33pMLSxug7d8STe6yvWqkMzXIHIenGh7jRyTV8XBeSxTahvgjt0t0ZnDK4Bo7o8",
        QA_client_id:"2fcdrtYLsUWhPVOuyTwL3zol54zUwYzNXKaJjx3C",
        QA_client_secret:"NKDgM0NRbPFY7y1hz0DZkEjBcdgXxTJcfNqwbIb2RAmtruIsnjXX2H9OAjCDW7PWlYwxiUHBjb2PuQkfBzp55ncKaiwP93YJHqiB3Fmm60LaQe9Zc6bPEt2jh8cg03QR"
    },

    customAuthData:{
        prod_id:"cq0lPtGX8nXm26aaoVRLXMUKLjkKM3bv",
        QA_id:"V7SOC6s4nhmlkEqq"
    },

    paymentStatus:{
        SUCCESS : 1,
        FAILURE : 2,
        PROCESSING : 3
    },
  

    inputAction: {
        LOGIN_MOBILE_NUMBER: 'LOGIN_MOBILE_NUMBER',
        ENTER_OTP: 'ENTER_OTP'
    },
    inputFieldtype: {
        email: "email",
        password: "password",
        text: 'text',
        number: 'number',
        date: 'date',
        numberpad: "number-pad",
        file: 'file',
        tel: 'tel',
        pan:'text',
        radio: 'radio'
        // dob: "Date Of Birth",
    },

    inputLabel: {
        MOBILE_NUMBER: 'Mobile Number',
        OTP:'OTP'
    },

    inputPlaceHolder: {
        MOBILE_NUMBER: 'Enter Mobile Number',
        OTP: 'Enter OTP'
    },

    reduxActions: {
        SET_IS_POP_OPEN: 'SET_IS_POP_OPEN',
        SET_RESET_FROM: 'SET_RESET_FROM',
        SET_FORM_SUBMIT_FUNCTION: 'SET_FORM_SUBMIT_FUNCTION',
        SET_FROM_ERROR: 'SET_FROM_ERROR',
        SET_FROM_INPUT_DATA: 'SET_FROM_INPUT_DATA',
        SET_REDUX_FROM_INPUT_DATA: 'SET_REDUX_FROM_INPUT_DATA',
        REMOVE_FROM_ERROR: 'REMOVE_FROM_ERROR',
        SET_URL_PAYLOAD_TYPE: 'SET_URL_PAYLOAD_TYPE',
        RESET_STATE: "RESET_STATE",
        fetchLoginData: "fetchLoginData",
        profileDataAPi: "profileInfoFields",
        BankDataApi: "bankInfoFields",
        BankInfoFieldsApi:"BankInfoFields"
    },

    RestApiActions: {
        setAddNewUserHandler:"setAddNewUserHandler",
        isDigital_NEFT_RTGS_Cheque_Payment:"isDigital_NEFT_RTGS_Cheque_Payment",
        FETCH_LOGIN_DATA: "FETCH_LOGIN_DATA",
        setIsLoggedIn: "setIsLoggedIn",
        profileDataAPi: "profileInfoFields",
        BankDataApi: "bankInfoFields",
        BankInfoFieldsApi:"BankInfoFields",
        GRAPH_QA_DATA:"GRAPH_QA_DATA",
        CREATE_PROFILE:"CREATE_PROFILE",
        FETCH_PROFILE:"FETCH_PROFILE",
        FETCH_PORTFOLIO:"FETCH_PORTFOLIO",
        PDFCERIFICATE:"PDFCERIFICATE",
        NETWORTHCERTIFICATE:"NETWORTHCERTIFICATE",
        PREWITHDRAWAL:"PREWITHDRAWAL",
        UPDATE_PROFILE:"UPDATE_PROFILE",
        FETCH_USER_DOC: "FETCH_USER_DOC",
        RESET_FETCH_USER_DOC: "RESET_FETCH_USER_DOC",
        
        CREATE_BANK_ACCOUNT:"CREATE_BANK_ACCOUNT",
        FETCH_BANK_ACCOUNT:"FETCH_BANK_ACCOUNT",
        FETCH_BANK_ACCOUNTPORTAL:"FETCH_BANK_ACCOUNTPORTAL",
        FETCH_BANK_DETAILS_ON_IFSC_CODE:"FETCH_BANK_DETAILS_ON_IFSC_CODE",
        PAY_ORDER:"PAY_ORDER",
        CREATE_INVESTMENT:"CREATE_INVESTMENT",
        GET_PAYMENT_STATUS :"GET_PAYMENT_STATUS",


        FETCH_BANK_LIST:"FETCH_BANK_LIST",
        FETCH_PORTAL_BANK_LIST:"FETCH_PORTAL_BANK_LIST",
        FETCH_STATE_LIST:"FETCH_STATE_LIST",
        FETCH_CITY_LIST:"FETCH_CITY_LIST",
        FETCH_GETCERTIFICATE:"FETCH_GETCERTIFICATE",
        FETCH_BRANCH_DATA:"FETCH_BRANCH_DATA",
        FETCH_TXN_DATA:"FETCH_TXN_DATA",
        FETCH_USER_DATA:"FETCH_USER_DATA",
        
        AUTO_INVEST:"AUTO_INVEST",
        FETCH_RELATION_LIST:"FETCH_RELATION_LIST",
        SET_TOTAL_TXN_DATA:"SET_TOTAL_TXN_DATA",
        SET_TOTAL_TXN_DATA_FILTER:"SET_TOTAL_TXN_DATA_FILTER",

        FAQ_DATA:"FAQ_DATA",
        AppsFlyer:"AppsFlyer",
        UPLOAD_DOC:"UPLOAD_DOC",
        FETCH_DOC:"FETCH_DOC",
        PORTAL_UPLOAD_DOC:"PORTAL_UPLOAD_DOC",
        PAN_DOC:"PAN_DOC",
        GST_DOC:"GST_DOC",
        netWorthDocument:"netWorthDocument",
        PROFILE_DOC:"PROFILE_DOC",
        AADHAR_DOC:"AADHAR_DOC",
        SET_AADHAR_NUMBER:"SET_AADHAR_NUMBER",
        SET_GST_NUMBER:"SET_GST_NUMBER",
        LOGOUT_USER:'LOGOUT_USER',
        OFF_VIDEO:'OFF_VIDEO',
        FILTER_PAYLOAD:'FILTER_PAYLOAD',
        SET_BANK_NAME_VALUE:'SET_BANK_NAME_VALUE',
        CHARTINVESTMENTBALANCE:'CHARTINVESTMENTBALANCE',
        SETINVESTMENTSUMMARYDATA:"SETINVESTMENTSUMMARYDATA",
        REFFERALCODE:"REFFERALCODE",
        USERSEGMENT:"USERSEGMENT",
        DEACTIVATEACCOUNT:"DEACTIVATEACCOUNT",
        USERNETWORTH:"USERNETWORTH",

        MOBILE_NUMBER:"MOBILE_NUMBER",
        CREATE_ESIGN_REQUEST:"CREATE_ESIGN_REQUEST",
        ESIGN_STATUS:"ESIGN_STATUS",
        NEW_USER:"NEW_USER",
        FILTER_PAYLOAD:'FILTER_PAYLOAD',
        PORTAL_USER_PROFILE: 'PORTAL_USER_PROFILE',
        SET_TOTAL_USER_DATA_FILTER:'SET_TOTAL_USER_DATA_FILTER',
        NOTIFICATIONSDATA:"NOTIFICATIONSDATA",
        UPCOMINGMATURITY:"UPCOMINGMATURITY",
        SET_USER_AADHAR_NUMBER: "SET_USER_AADHAR_NUMBER",
        SETINVESTMENTVALUE:"SETINVESTMENTVALUE",
        SETINVESTMENTAMOUNTVALUE:"SETINVESTMENTAMOUNTVALUE",

        SET_USER_GET_PROFILE_DATA: "SET_USER_GET_PROFILE_DATA",
        setIsUserProfileCompleted: "setIsUserProfileCompleted",
        setIsUserDocCompleted: "setIsUserDocCompleted",
        setIsUserBankCompleted: "setIsUserBankCompleted",
        isStepCountStatus:"isStepCountStatus",
        setIsCurrentSelectedTxnDataForModal:"setIsCurrentSelectedTxnDataForModal",
        setIsCurrentSelectedUserDataForModal:"setIsCurrentSelectedUserDataForModal",
        setFieldDisabled:"setFieldDisabled",
        setFilterData:"setFilterData",
        ResetCommonFilterData:"ResetCommonFilterData",
        setSearchMobileNumber:"setSearchMobileNumber",
        setSearchName:"setSearchName",
        setSelectedSortOption:"setSelectedSortOption",
        setSelectedOption:"setSelectedOption",
        setcurrentpage:'setcurrentpage',
        RESET_USER_DOC:"RESET_USER_DOC",
        CANCELPAYMENT:"CANCELPAYMENT"

    },

    profileInfoFields: {
        NAME_ID: 'NAME_ID',
        EMAIL_ID: 'EMAIL_ID',
        PAN_ID: 'PAN_ID',
        CURRENTADDRESS_ID: 'CURRENTADDRESS_ID',
        PINCODE_ID: 'PINCODE_ID',
        DOB_ID: 'DOB_ID',
        EMPLOYMENT_TYPE_ID: "EMPLOYMENT_TYPE_ID",
        ANNUAL_INCOME_ID: 'ANNUAL_INCOME_ID',
        TYPE_VALUE: "Type",
    },
    userPersonalDetailsFields: {
        FULL_NAME: 'FULL_NAME',
        EMAIL_ID: 'EMAIL_ID',
        PAN_ID: 'PAN_ID',
        CURRENTADDRESS_ID: 'CURRENTADDRESS_ID',
        PINCODE_ID: 'PINCODE_ID',
        DOB_ID: 'DOB_ID',
        EMPLOYMENT_TYPE_ID: "EMPLOYMENT_TYPE_ID",
        ANNUAL_INCOME_ID: 'ANNUAL_INCOME_ID',
        GENDER: "GENDER",
        LAST_NAME: "LAST_NAME"
    },

    profileInfoLabels: {
        EMAIL_ID: 'Email ID',
        NAME_ID: 'Name',
        PAN_ID: 'PAN',
        CURRENTADDRESS_ID: 'Current Address',
        PINCODE_ID: 'PIN Code',
        DOB_ID: 'Date of Birth',
        EMPLOYMENT_TYPE_ID: "Employment Type",
        ANNUAL_INCOME_ID: "Annual Income",
        TYPE_VALUE: "Type"
    },

    userProfileInfoLabels: {
        EMAIL_ID: 'Email ID',
        FULL_NAME: 'Full Name',
        PAN_ID: 'PAN',
        CURRENTADDRESS_ID: 'Current Address',
        PINCODE_ID: 'Pincode',
        DOB_ID: 'Date of Birth',
        EMPLOYMENT_TYPE_ID: "Employment Type",
        ANNUAL_INCOME_ID: "Annual Income",
        GENDER: "Gender",
        LAST_NAME: "Last Name"
    },

    profileInfoPlaceholders: {
        EMAIL_ID: 'Enter Email ID',
        NAME_ID: 'Enter Name',
        PAN_ID: 'Enter PAN',
        CURRENTADDRESS_ID: 'Enter Current Address',
        PINCODE_ID: 'Enter Pincode',
        DOB_ID: 'DD/MM/YYYY',
        EMPLOYMENT_TYPE_ID: "Employment Type",
        ANNUAL_INCOME_ID: "Annual Income",
        TYPE_VALUE: "Type"
    },

    userProfileInfoPlaceholders: {
        EMAIL_ID: 'Enter Email ID',
        FULL_NAME: 'Enter Full Name',
        PAN_ID: 'Enter PAN',
        CURRENTADDRESS_ID: 'Enter Current Address',
        PINCODE_ID: 'Enter Pincode',
        DOB_ID: 'Date of Birth',
        EMPLOYMENT_TYPE_ID: "Employment Type",
        ANNUAL_INCOME_ID: "Annual Income",
        GENDER: "Gender",
        LAST_NAME: "Enter Last Name"
    },

    TYPE_VALUE: {
        Strings: [{
            label: "Individual",
        }, {
            label: "Company",
        },
       
       
        ]
    },
    EMPLOYMENT_TYPE: {
        Strings: [{
            label: "Salaried",
            value: "0"
        }, {
            label: "Non-Salaried",
            value: "1"
        },
        ]
    },
    ANNUAL_INCOME_ID: {
        Strings: [{
            label: "05",
        }, {
            label: "20",
        },
        {
            label: "15",
        },
        {
            label: "15",
        },
        {
            label: "20",
        },
        ]
    },

    bankInfoFields: {
        ACCOUNT_HOLDER_NAME_ID: 'ACCOUNT_HOLDER_NAME_ID',
        ACCOUNT_NO_ID: 'ACCOUNT_NO_ID',
        IFSC_CODE_ID: 'IFSC_CODE_ID',
        BANK_NAME_ID: 'BANK_NAME_ID',
        ACCOUNT_TYPE_ID: 'ACCOUNT_TYPE_ID',
        ACCOUNT_HOLDER_ANNUAL_INCOME_ID: 'ACCOUNT_HOLDER_ANNUAL_INCOME_ID',
        // BRANCH_ID: 'Enter Branch Name',
        // BRANCH_ADDRESS_ID: 'Enter Branch Address',
    

    },
    BankInfoLabels: {
        ACCOUNT_HOLDER_NAME_ID: 'Enter Account Holder Name',
        ACCOUNT_NO_ID: 'Enter Account Number',
        IFSC_CODE_ID: 'Enter IFSC Code',
        BANK_NAME_ID: 'Select Bank Name',
        ACCOUNT_TYPE_ID: 'Select Account Type',
        ACCOUNT_HOLDER_ANNUAL_INCOME_ID: 'Enter Account Holder Annual Income',
        // BRANCH_ID: 'Enter Branch Name',
        // BRANCH_ADDRESS_ID: 'Enter Branch Address',
    },
    BankInfoPlaceholders: {
        ACCOUNT_HOLDER_NAME_ID: 'Enter Account Holder Name',
        ACCOUNT_NO_ID: 'Enter Account Number',
        IFSC_CODE_ID: 'Enter IFSC Code',
        BANK_NAME_ID: 'Enter Bank Name',
        ACCOUNT_TYPE_ID: 'Enter Account Type',
        ACCOUNT_HOLDER_ANNUAL_INCOME_ID: 'Enter Account Holder Annual Income',
        // BRANCH_ID: 'Enter Branch Name',
    },
    UserBankInfoFields: {
        ACCOUNT_HOLDER_NAME_ID: 'ACCOUNT_HOLDER_NAME_ID',
        ACCOUNT_NO_ID: 'ACCOUNT_NO_ID',
        REENTER_ACCOUNT_NO_ID: 'REENTER_ACCOUNT_NO_ID',
        IFSC_CODE_ID: 'IFSC_CODE_ID',
        BANK_NAME_ID: 'BANK_NAME_ID',
        ACCOUNT_TYPE_ID: 'ACCOUNT_TYPE_ID',
        ACCOUNT_HOLDER_ANNUAL_INCOME_ID: 'ACCOUNT_HOLDER_ANNUAL_INCOME_ID',
        // BRANCH_ID: 'Enter Branch Name',
        // BRANCH_ADDRESS_ID: 'Enter Branch Address',
    

    },
    UserBankInfoLabels: {
        ACCOUNT_HOLDER_NAME_ID: 'Enter Account Holder Name',
        ACCOUNT_NO_ID: 'Enter Account Number',
        REENTER_ACCOUNT_NO_ID: 'Re-Enter Account Number',
        IFSC_CODE_ID: 'Enter IFSC Code',
        BANK_NAME_ID: 'Select Bank Name',
        ACCOUNT_TYPE_ID: 'Select Account Type',
        ACCOUNT_HOLDER_ANNUAL_INCOME_ID: 'Enter Account Holder Annual Income',
        // BRANCH_ID: 'Enter Branch Name',
        // BRANCH_ADDRESS_ID: 'Enter Branch Address',
    },
    UserBankInfoPlaceholders: {
        ACCOUNT_HOLDER_NAME_ID: 'Enter Account Holder Name',
        ACCOUNT_NO_ID: 'Enter Account Number',
        REENTER_ACCOUNT_NO_ID: 'Re-Enter Account Number',
        IFSC_CODE_ID: 'Enter IFSC Code',
        BANK_NAME_ID: 'Enter Bank Name',
        ACCOUNT_TYPE_ID: 'Enter Account Type',
        ACCOUNT_HOLDER_ANNUAL_INCOME_ID: 'Enter Account Holder Annual Income',
        // BRANCH_ID: 'Enter Branch Name',
    },
    ACCOUNT_TYPE_VALUE: {
        Strings: [
            {
                label: "Savings",

            }, {
                label: "Current",
            }
        ]
    },
    
    BankIfscFields: {
        Bank_Name: 'Bank_Name',
        Bank_State:"State_Name",
        Bank_City:"City_Name",
        Bank_Branch_Name:"Branch_Name"
    },
    BankInfoIfscLabels: {
        Bank_Name:"Bank Name",
        State_Name:" State Name",
        City_Name:" Enter City Name",
        Branch_Name:" Branch Name",

    },
    BankInfoIfscPlaceholders:{
        Bank_Name:"Enter Bank Name",
        State_Name:"Enter State Name",
        City_Name:" Enter City Name",
        Branch_Name:"Enter Branch Name",

    },
    NomineeInfoFields: {
        NOMINEE_NAME: 'NOMINEE_NAME',
        NOMINEE_NUMBER:'NOMINEE_NUMBER',
        NOMINEE_EMAIL:"NOMINEE_EMAIL",
        NOMINEE_DOB: 'NOMINEE_DOB',
        NOMINEE_RELATION:"NOMINEE_RELATION",
    },
    NomineeInfoLabels: {
        Nominee_Name: 'Name',
        NOMINEE_NUMBER:"Phone Number",
        NOMINEE_EMAIL:"Email",
        NOMINEE_DOB:"DOB",
        NOMINEE_RELATION:"Relation",

    },
    NomineeInfoPlaceholders:{
        Nominee_Name: 'Name',
        NOMINEE_NUMBER:"Phone Number",
        NOMINEE_EMAIL:"Email",
        NOMINEE_DOB:"DD/MM/YYYY",
        NOMINEE_RELATION:"Nominee is your",

    },
    NewUser: {
        USER_MOBILE_NUMBER: 'USER_MOBILE_NUMBER',
    }


}


// export function getOptionsList(selectionType) {
//     let casheReduxValue = ReduxValues.sharedInstance();


//     switch (selectionType) {
//         case SelectionTypes.GENDER:
//             return [{ id: 'm', name: Strings.male }, { id: 'f', name: Strings.female }];

//         default:
//             break;
//     }
// }

export default ReduxValues