 import ApiConstants from "./Constants/ApiConstant.js";
import Values from "./Constants/Values";
import ReduxValues from "./Store/ReduxValues.js";
const appVersion = require('../package.json').version;

export const DeploymentTypes = {
    DEVELOPMENT: 'Development',
    STAGE:'Stage',
    TESTING: 'Testing',
    PRODUCTION: 'Production',
    PRE_PRODUCTION: 'Pre-Production',
    LOCAL:'local',
    UAT:'UAT'
};
const BuildProperties = {

    deploymentType: DeploymentTypes.TESTING,
    cashepaymentgateway_ENV:DeploymentTypes.TESTING,

    version: appVersion,

    isProdEnv() {
        return !!(BuildProperties.deploymentType === DeploymentTypes.PRODUCTION || BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION)
    },

    isDev() {
        return BuildProperties.deploymentType === DeploymentTypes.DEVELOPMENT
    },

    getDeploymentType() {
        return BuildProperties.deploymentType;
    },

    setVersion(versionCode){
        BuildProperties.version = versionCode;
    },

    getVersion () {
        return BuildProperties.version;
    },

    getBaseURL() {
        switch(BuildProperties.deploymentType) {
            case DeploymentTypes.TESTING:
                return ApiConstants.qaURL;
            case DeploymentTypes.STAGE:
                return ApiConstants.stageURL;
            case DeploymentTypes.PRODUCTION:
                return ApiConstants.prodURL;
            case DeploymentTypes.PRE_PRODUCTION:
                return ApiConstants.preProdURL;
            case DeploymentTypes.LOCAL:
                return ApiConstants.localServerURL    
            case DeploymentTypes.UAT:
                return ApiConstants.UAT_URL    
            default:
                return ApiConstants.UAT_URL;
        }
    },

    getPaymentGatewayBaseUrl(){
        if(BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ApiConstants.getPaymentGatewayProdUrl
        }else if(BuildProperties.cashepaymentgateway_ENV === DeploymentTypes.STAGE){
            return ApiConstants.getStagePaymentGatewayUrl;
        }else{
            return ApiConstants.getStagePaymentGatewayUrl; //getPaymentGatewayUrl;
        }
    },

    getHostedUrl(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return "https://ifaportal.13karat.in/"
        }else if(BuildProperties.deploymentType === DeploymentTypes.TESTING){
            // return "https://qa-ifaportal.13karat.in"
            return window.location.origin
        }else if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION){
            return window.location.origin //"https://qa-ifaportal.13karat.in"
        }else if(BuildProperties.deploymentType === DeploymentTypes.LOCAL){
            return "http://localhost:3000/"
        }
    },

    getBakendHostUrl(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ApiConstants.prodURL
        }else if( BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION){
            return ApiConstants.preProdURL
        }else{
            return ApiConstants.localServerURL
        }  
  
    },

    getCustomAuth(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ReduxValues.customAuthData.prod_id
        }else {
            return ReduxValues.customAuthData.QA_id
        }
    },
    
    getClientID(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ReduxValues.authData.PROD_client_id
        }else if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION){
            return ReduxValues.authData.QA_client_id
        }else if(BuildProperties.deploymentType === DeploymentTypes.TESTING){
            return ReduxValues.authData.QA_client_id
        }else if(BuildProperties.deploymentType === DeploymentTypes.LOCAL){
            return ReduxValues.authData.client_id
        }
    },

    getClientSecretID(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ReduxValues.authData.PROD_client_secret
        }else if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION){
            return ReduxValues.authData.QA_client_secret
        }else if(BuildProperties.deploymentType === DeploymentTypes.TESTING){
            return ReduxValues.authData.QA_client_secret
        }else if(BuildProperties.deploymentType === DeploymentTypes.LOCAL){
            return ReduxValues.authData.client_secret
        }
    },


    getPhonePayClientID(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ReduxValues.phonePayAuthData.QA_client_id
        }else if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION){
            return ReduxValues.phonePayAuthData.QA_client_id
        }else if(BuildProperties.deploymentType === DeploymentTypes.TESTING){
            return ReduxValues.phonePayAuthData.QA_client_id
        }else if(BuildProperties.deploymentType === DeploymentTypes.LOCAL){
            return ReduxValues.phonePayAuthData.client_id
        }
    },


    getPhonePayClientSecretID(){
        if( BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            return ReduxValues.phonePayAuthData.QA_client_secret
        }else if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION){
            return ReduxValues.phonePayAuthData.QA_client_secret
        }else if(BuildProperties.deploymentType === DeploymentTypes.TESTING){
            return ReduxValues.phonePayAuthData.QA_client_secret
        }else if(BuildProperties.deploymentType === DeploymentTypes.LOCAL){
            return ReduxValues.phonePayAuthData.client_secret
        }
    },

    getVersionName() {
        let version = BuildProperties.getVersion();
        switch (this.getDeploymentType()) {
            case DeploymentTypes.TESTING:
               return version = 'Version: '+  version + ' QA';
            case DeploymentTypes.DEVELOPMENT:
                return version = 'Version: '+ version + ' APIARY';
            case DeploymentTypes.PRODUCTION:
            case DeploymentTypes.PRE_PRODUCTION:
                return version = 'Version: '+ version;
            default:
                break;
        }
    },

  

    getMaintenanceURL() {
        switch(BuildProperties.deploymentType) {
            case DeploymentTypes.PRODUCTION:
                // return ApiConstants.prodMaintenanceURL + '/getProdMaintenace';
                break
            case DeploymentTypes.TESTING:
                break
            case DeploymentTypes.PRE_PRODUCTION:
                break
                // return ApiConstants.qaMaintenanceURL + '/getUatMaintenace';
            default:
                // return ApiConstants.apiaryURL + '/maintenance';
                break
        }
    },

    getSDKHashKey() {
        if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION || BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
 // return Values.casheHashKeyPROD;
        }else{
            // return Values.casheHashKeyQA;

        }
    },

    getIVRKey() {
        if(BuildProperties.deploymentType === DeploymentTypes.PRE_PRODUCTION || BuildProperties.deploymentType === DeploymentTypes.PRODUCTION){
            // return Values.casheIVRKeyPROD;
        }else{
            // return Values.casheIVRKeyQA;
        }
    },



};

export default BuildProperties;
