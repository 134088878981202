
import ReduxValues from "../ReduxValues";
export const isLoading=(flag=false)=>{
    return{
        type:'SET_IS_LOADING',
        isLoading:flag
    }
}


let actionsStrings=ReduxValues.reduxActions


export const setFilterPayload=(payload)=>{
  sessionStorage.setItem("filterPayload",payload?JSON.stringify(payload):null)
  return{
    type:ReduxValues.RestApiActions.FILTER_PAYLOAD,
    payload
}
}
export const setFilterTransactionData=(payload)=>{
  // sessionStorage.setItem("filterPayload",payload?JSON.stringify(payload):null)
  return{
    type:ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA_FILTER,
    payload
}
}
export const setInvestmentValue=(flag)=>{
  return{
    type: ReduxValues.RestApiActions.SETINVESTMENTVALUE,
    payload:flag,
  }
}
export const setInvestmentAmountValue=(Amount)=>{
  
  return{
    type: ReduxValues.RestApiActions.SETINVESTMENTAMOUNTVALUE,
    payload:Amount,
  }
}

export const paymentCancelled=(data)=>{
  
  return{
    type: ReduxValues.RestApiActions.CANCELPAYMENT,
    payload:data,
  }
}

export const setFormSubmitFun=(handleSubmit)=>{
    return{
        type:actionsStrings.SET_FORM_SUBMIT_FUNCTION,
        handleSubmit
    }
}


export const setFormError=({formFieldName,msg,isvalid})=>{
    return{
      type:actionsStrings.SET_FROM_ERROR,
      formFieldName,
      msg,
      isvalid
    }
} 


export const setSelectedCompanyData=(data)=>{
    return{
        type:ReduxValues.reduxActions.selectedCompanyData,
        data
    }
}

export const setEsignStatus=(esignStatus)=>{
  return{
      type:ReduxValues.RestApiActions.ESIGN_STATUS,
      esignStatus: esignStatus
  }
}
export const setStepCount=(step)=>{
  
  return{
      type:ReduxValues.RestApiActions.isStepCountStatus,
      payload: step,
  }
}


export const setPanDocument=(pandoc)=>{
    return{
      type:ReduxValues.RestApiActions.PAN_DOC,
      panDoc:pandoc,
    }
  }
export const setProfileDocument=(profileDoc)=>{
    return{
      type:ReduxValues.RestApiActions.PROFILE_DOC,
      profileDoc,
    }
  }


export const setAadharDoc=({aadharDocFront,aadharDocBack})=>{
    return{
      type:ReduxValues.RestApiActions.AADHAR_DOC,
      aadharDocFront,
      aadharDocBack
    }
  }
  
  export const setAadharNumber=(aadharNumber)=>{
    return{
      type:ReduxValues.RestApiActions.SET_AADHAR_NUMBER,
      aadharNumber,
    }
  }

  export const setUserAadharNumber=(userAadharNumber)=>{
    return{
      type:ReduxValues.RestApiActions.SET_USER_AADHAR_NUMBER,
      userAadharNumber,
    }
  }
  
  export const setGstNumber=(gstNumber)=>{
    return{
      type:ReduxValues.RestApiActions.SET_GST_NUMBER,
      gstNumber,
    }
  }
  export const setGstDocument=(gstdoc)=>{
    return{
      type:ReduxValues.RestApiActions.GST_DOC,
      gstDoc:gstdoc,
    }
  }

  export const setNetWorthDocument=(netWorthDocument)=>{
    return{
      type:ReduxValues.RestApiActions.netWorthDocument,
      netWorthDocument:netWorthDocument,
    }
  }
  

export const isPOPOpen=({isOpen=false,msg,btntxt,cancleBtntxt,successHandler,failureHandler,showRedirection,delay=5000,navigatetohome,navigateToHomeAndShowRedirection,shouldLogout,customImg,title,PopUpFooterText,showCrossIcon=true})=>{
    return{
        type:actionsStrings.SET_IS_POP_OPEN,
        cancleBtntxt,
        isOpen,
        msg,
        btntxt,
        successHandler,
        failureHandler,
        showRedirection,
        delay,
        navigatetohome,
        navigateToHomeAndShowRedirection,
        shouldLogout,
        customImg,
        title,
        PopUpFooterText,
        showCrossIcon,
    }
}


export const setBankNameValue=(bankName)=>{
  return{
    type:ReduxValues.RestApiActions.SET_BANK_NAME_VALUE,
    bankName
  }
}




//dispatch {type and payload}
export const setShowSideDrawer=(flag)=>{
  return{
      type:ReduxValues.globalActions.SHOW_SIDE_BAR,
      flag
  }
}

export const setShowDesktopSideDrawer=(flag)=>{
  return{
      type:ReduxValues.globalActions.SHOW_DESKTOP_SIDE_BAR,
      flag
  }
}


export const setShowCurrentTabName =(currentTabName)=>{
  return{
      type:ReduxValues.globalActions.SHOW_CURRENT_TAB_NAME,
      payload:currentTabName
  }
}

export const setbreadcrumbsList =(list)=>{
  return{
      type:ReduxValues.globalActions.SHOW_BREADCRUMBS_LIST,
      payload:list
  }
}

export const setShowOperationSubTabName =(flag)=>{
  return{
      type:ReduxValues.globalActions.SHOW_OPERATION_SUBTAB_NAME,
      flag
  }
}
export const setShowSideTabDrawer =(flag)=>{
  return{
      type:ReduxValues.globalActions.SHOW_SUBTAB_DRAWER,
      payload:flag
  }
}
export const setShowActiveTabs =(flag)=>{
  return{
      type:ReduxValues.globalActions.SHOW_ACTIVE_TABS,
      payload:flag
  }
}

export const setShowActiveSubTabs =(flag)=>{
  return{
      type:ReduxValues.globalActions.SHOW_ACTIVE_SUB_TABS,
      payload:flag
  }
}


export const setIsDigital_NEFT_RTGS_Cheque_PaymentMode =(flag)=>{
  return{
      type:ReduxValues.RestApiActions.isDigital_NEFT_RTGS_Cheque_Payment,
      payload:flag
  }
}


export const setAddNewUserHandler=(flag)=>{
  return{
    type:ReduxValues.RestApiActions.setAddNewUserHandler,
    payload:flag
}
}
export const setIsUserProfileCompleted=(flag)=>{
  return{
    type:ReduxValues.RestApiActions.setIsUserProfileCompleted,
    payload:flag
}
}
export const setIsUserDocCompleted=(flag)=>{
  return{
    type:ReduxValues.RestApiActions.setIsUserDocCompleted,
    payload:flag
}
}
export const setIsUserBankCompleted=(flag)=>{
  return{
    type:ReduxValues.RestApiActions.setIsUserBankCompleted,
    payload:flag
}
}

export const setIsCurrentSelectedTxnDataForModal=(data)=>{
  return{
    type:ReduxValues.RestApiActions.setIsCurrentSelectedTxnDataForModal,
    payload:data
}
}

export const setIsCurrentSelectedUserDataForModal=(data)=>{
  return{
    type:ReduxValues.RestApiActions.setIsCurrentSelectedUserDataForModal,
    payload:data
}
}

export const setFieldDisabled=(flag)=>{
  return{
    type:ReduxValues.RestApiActions.setFieldDisabled,
    payload:flag
}
}


export const setCommonFilterData=({start_date=null,end_date=null,status=null,prodyctType=null,txnType=null})=>{
  return{
    type:ReduxValues.RestApiActions.setFilterData,
    payload:{start_date,end_date,status,prodyctType,txnType}
}
}

export const ResetCommonFilterData=()=>{
  return{
    type:ReduxValues.RestApiActions.ResetCommonFilterData,
  } 
}

export const setSearchMobileNumber=(mobNo)=>{
  return{
    type:ReduxValues.RestApiActions.setSearchMobileNumber,
    payload:mobNo
  } 
}

export const setSearchName=(name)=>{
  return{
    type:ReduxValues.RestApiActions.setSearchName,
    payload:name
  } 
}
export const setSelectedSortOption=(sortBy)=>{
  return{
    type:ReduxValues.RestApiActions.setSelectedSortOption,
    payload:sortBy
  } 
}

export const setSelectedOption=(searchField)=>{
  return{
    type:ReduxValues.RestApiActions.setSelectedOption,
    payload:searchField
  } 
}

export const setcurrentpage=(seletedPageNo)=>{
  return {
    type:ReduxValues.RestApiActions.setcurrentpage,
    payload:seletedPageNo
  }
  }