import React  from "react";
// import ClipLoader from "react-spinners/ClipLoader";
// Can be a string as well. Need to ensure each key-value pair ends with ;
import Modal from '@material-ui/core/Modal';
import ImagesPath from '../../Images/Images'

const Spinner=(props)=> {
  return (
    props.loading&&(
        <Modal
        open={props.loading}
        style={{display:'flex',justifyContent:'center',alignItems:'center'}}
        >
    <React.Fragment>
        {/* <ClipLoader color={'#f48120'} loading={props.loading}  size={80} /> */}
        {/* <img src={ImagesPath.karatHeaderLogo} width="30px" height='18px' style={{position:'fixed',top:'50%',left:'50%',transform:'translate(-50%, -50%)',  marginBottom:0}}/> */}
        <img src={ImagesPath.NewLoaderImage} width="120px" height='120px' style={{position:'fixed',top:'50%',left:'50%',transform:'translate(-50%, -50%)',  marginBottom:0, transition:'ease'}}/>

        
    </React.Fragment>
    </Modal>

    )
  );
}

export default Spinner