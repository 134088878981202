import React, { useEffect, Suspense, useState, useMemo } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Routers from './Routers/Router'
import Spinner from './Components/OnboardingComponents/Spinner';
import { useDispatch, useSelector } from 'react-redux'
import ReduxValues from './Store/ReduxValues';
import POPUPModel from './Components/OnboardingComponents/PopUp';
import { isPOPOpen, isLoading } from './Store/Actions/GlobalActions'
import HeaderBar from './Components/PortelComponents/HeaderComponent'
import { useLocation, useNavigate } from 'react-router-dom';
// Use 'auth' to handle authentication
// Example: Sign in a user
import { collection, getDocs, addDoc,onSnapshot } from 'firebase/firestore';
// Use 'auth' to handle authentication

// Example: Sign in a user

import { db ,auth } from './firebase';
import BuildProperties from "./BuildProperties";


function App() {
  const globalState = useSelector(state => state.globalState)
  const restApiState = useSelector(state => state.restApiState)
  let authData = JSON.parse(localStorage.getItem('authData'))
  const dispatch = useDispatch()
  const location= useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const naviateToMainMaintance = () => navigate('/UnderMaintaince');
  const naviateToMainRoute = () => navigate('/');

  const ClipLoader = (flag) => {
    dispatch(isLoading(flag));
  };
  useEffect(() => {
   // const itemsCollection = await getDocs(collection(db, 'maintenance'));
   const itemsCollection = collection(db, 'maintenance');
   // Set up a real-time listener
       const unsubscribe = onSnapshot(itemsCollection, (snapshot) => {
         const dataList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
         setData(dataList);
         const data=(dataList).filter((value)=>{
              return  BuildProperties.deploymentType === "Production"?value.id==="prod":value.id==="qa"
         })
 
         console.log("data",data)
 
         if (data && data.length > 0 ) {
           if (data[0].isMaintenanceOn) {
             naviateToMainMaintance()
           }else if (window.location.pathname == "/UnderMaintaince"){
             naviateToMainRoute()
           }
         }
       // Store data in localStorage
       localStorage.setItem('maintenanceData', JSON.stringify(data));
       });
   // Clean up the subscription on component unmount
       return () => unsubscribe();
 }, []);

  const renderPopUp = () => {
    return (
      <POPUPModel open={globalState.popUpModel.isOpen} popUpData={globalState.popUpModel} />
    )
  }


  useMemo(() => {
    let authData = JSON.parse(localStorage.getItem('authData'))
    if (authData && authData['entity']) {
      dispatch({
        type: ReduxValues.RestApiActions.FETCH_LOGIN_DATA,
        payload: authData['entity'],
      })
    }
  }, [])

  const renderSpinner = (flag) => {
    return <Spinner loading={flag ? flag : globalState.isLoading} />
  }
  return (
    window.location.pathname.includes("portel") ||  location?.state?.isFromPortel ?
      <div className="App" style={{ backgroundSize: 'contain', margin: "auto", scrollBehavior: 'smooth', overflowX: 'hidden', overflowY: 'hidden' }}>
        <HeaderBar>
          <Suspense fallback={renderSpinner(true)}>
            {renderSpinner()}
            {globalState.popUpModel.isOpen && renderPopUp()}
            <Routers />
          </Suspense>
        </HeaderBar>
      </div>
      :
      //Mobile view
      // <div className="App" style={{ width: "430px", backgroundSize: 'contain', margin: "auto", scrollBehavior: 'smooth', overflowX: 'hidden', overflowY: 'hidden' }}>
      //   <Suspense fallback={renderSpinner(true)}>
      //     {renderSpinner()}
      //     {globalState.popUpModel.isOpen && renderPopUp()}
      //   <Routers/>  
      //   </Suspense>
      // </div>  

      //Desktop View
      <div className="App" style={{ width: "100%", backgroundSize: 'contain', margin: "auto", scrollBehavior: 'smooth', overflowX: 'hidden', overflowY: 'hidden' }}>
        <Suspense fallback={renderSpinner(true)}>
          {renderSpinner()}
          {globalState.popUpModel.isOpen && renderPopUp()}
          <Routers />
        </Suspense>
      </div>
  );
}

export default App;
