import * as React from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  //   padding:8,

  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#ea7713',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#ea7713',
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function CustomizedRadios(props) {
  const { radioElements = [], defaultvalue, ...restProps } = props;
  let val = "0";
  if (restProps.currentBtnselected !== undefined) {
    val = restProps.currentBtnselected;
  } else if (defaultvalue !== undefined) {
    val = defaultvalue;
  }
  return (
    <FormControl>
      {/* <FormLabel id="demo-customized-radios" style={{color:'#333333',fontSize:'17px',fontFamily:'Gilroy-Regular',marginTop:12}}>{props.title}</FormLabel> */}
      {restProps.isFromPortal ? <RadioGroup
         row
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        onChange={restProps.onChange}
        value={val}
        {...restProps}
      >

        {radioElements.map((ele, idx) => {
          return <FormControlLabel key={idx} value={ele.value} control={<BpRadio />} label={<span style={props.currentBtnselected === ele.value ? { fontSize: 16, fontFamily: 'Gilroy-Bold' } : { fontSize: 16, fontFamily: 'Gilroy-Regular' }}>{ele.label}</span>} disabled={ele.disabled} />
        })}
      </RadioGroup>
      : <RadioGroup
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        onChange={restProps.onChange}
        value={val}
        {...restProps}
      >

        {radioElements.map((ele, idx) => {
          return <FormControlLabel key={idx} value={ele.value} control={<BpRadio />} label={<span style={props.currentBtnselected === ele.value ? { fontSize: 16, fontFamily: 'Gilroy-Bold' } : { fontSize: 16, fontFamily: 'Gilroy-Regular' }}>{ele.label}</span>} disabled={ele.disabled} />
        })}
      </RadioGroup>}
    </FormControl>
  );
}
