import React, { useEffect, useState, useRef } from 'react'
import { TextField } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import Styles from '../Style/Style.module.css';
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from 'react-redux';
import { InfoOutlined, CancelRounded } from '@material-ui/icons';
// import Styles from  '../Styles/GlobalStyle.css'
import Strings from '../Constants/Strings'
// import { DatePicker ,MobileDatePicker, DesktopDatePicker } from '@mui/lab'
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import Colors from '../Constants/Colors';
import { isPOPOpen, setBankNameValue } from '../Store/Actions/GlobalActions'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField2 from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CASHeValidations from '../Utils/CasheValidator';
import Images from '../Images/Images';
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import dayjs from 'dayjs';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import  DefaultRadioButton from '../Components/OnboardingComponents/RadioButton'
import ReduxValues from "../Store/ReduxValues";



import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';


let FormTypes = Strings.FormTypes

const Defaultstyles = {
  helperTextStyle: {
    marginLeft: '24px',
    marginTop: '5px',
    color: '#314451',
    fontFamily: 'Gilroy-Medium',
    fontSize: '13px',
    textAlign: 'start'
  },
  multilineColor: {
    // backgroundColor:'rgba(47, 115, 218, 0.05)',
    height: '52px',
    borderColor: 'yellow !important'

  },

  dateMultilineColor: {
    height: '52px',
  },

  dateHelperTextStyle: {
    marginLeft: '24px',
    marginTop: '5px',
    fontFamily: 'Gilroy-Medium',
    fontSize: '13px',
    textAlign: 'start',
    color: 'red'
  },

  captchaStyle: {
    // backgroundColor:'rgba(47, 115, 218, 0.05)',
    // width:'%',
    height: '52px',
    borderColor: 'yellow !important'

  },

  dateStyle: {
    // backgroundColor:'rgba(47, 115, 218, 0.05)',
    // width:'%',
    height: '52px',
    borderColor: '#333333 !important',

  },

  dateoutlineerror: {
    borderColor: `red !important`,
    borderWidth: 2,
    // height:44,
    // marginTop:4,
    zIndex: '1',
  },


  label: {
    fontSize: 18,
    color: 'green',
    "&$focusedLabel": {
      color: "white",
    },
    "&$erroredLabel": {
      color: "red"
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  focused: {},

  cssOutlinedInput: {
    '&$outlineerror $notchedOutline': {
      borderColor: `red !important`,
      backgroundColor: 'transparent !important',
      borderWidth: 2,
      // height:44,
      marginTop: 4,
      // zIndex: '1',

    },
    '&$focusedError $notchedOutline': {
      borderColor: `#ea7713 !important`,
      backgroundColor: 'transparent !important',
      borderWidth: 2,
      // height:44,
      marginTop: 4,

      zIndex: '1',
      color: 'black'
    },
    '& $notchedOutline': {
      borderColor: '#333333 !important',
      // height:44,
      marginTop: 4,


      // border:'none'
    },

  },
  outlineerror: {},
  focusedError: {},
  notchedOutline: {},
  colorSecondary: {},
  option: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: 'white',

      borderColor: 'transparent',
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: 'white',
      borderColor: 'transparent',
    },

  },

};


const useStyles = makeStyles({
  paper: {
    border: "1px solid black"
  }
});
const InputTextField = (props) => {
  const btnRef = useRef()
  const { classes } = props;
  const classes2 = useStyles();

  const dispatch = useDispatch()
  const inputState = useSelector(state => state.InputState)

  const form = useSelector(state => state.form)
  const globalState = useSelector(state => state.globalState)
  const restApiState = useSelector(state => state.restApiState)

  const formState = useSelector(state => state.formState)
  const [selectedValue, setSelectedValue] = useState('')

  const [selectedImage, setSelectedImage] = useState(null);
  const [bankValue, setBankValue] = useState('');

  const [selectedListItem, setSelectedListItem] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [dateOpen, setDateOpen] = useState(false);
  const [currentRadioBtnselected, setCurrentRadioBtnSelected] = useState()
  const [currentGenderRadioBtnselected, setCurrentGenderRadioBtnSelected] = useState();



  const selectChangeHandler = (event, inputProp) => {
    console.log(event.target.value)
    setSelectedValue(event.target.value)
  }

  const uploadClickHandler = (e) => {
    console.log(btnRef)
    btnRef.current.click()

  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setSelectedImage(reader.result)
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      setSelectedImage(null)

    };
  }

  const getCurrentSelectedButton = (fieldName) => {
    if (fieldName === ReduxValues.profileInfoFields.GENDER) {
      return currentGenderRadioBtnselected
    } else if (fieldName === ReduxValues.bankInfoFields.ACCOUNT_TYPE_ID) {
      return currentRadioBtnselected
    }else if (fieldName === ReduxValues.userPersonalDetailsFields.GENDER) {
      return currentGenderRadioBtnselected
    }
  }

  useEffect(() => {
    if (restApiState.fetchBankAccountResponse) {
      setCurrentRadioBtnSelected(restApiState.fetchBankAccountResponse.account_type.toString())
    }
  }, [restApiState.fetchBankAccountResponse])
  const renderSelectedValue = (placeholder, fieldName) => {
    return <MenuItem value="" disabled>
      <em style={{ marginLeft: 12 }}>{placeholder}</em>
    </MenuItem>

  }

  let { errorText, type, placeholder, error, input, inputProps, label, touched, warning, formType, inputLimit, showBottomBorder, rightView, defaultvalue, inputValue, isFromPortal, fieldName, defaultprops, disabled, inputRef, ...restProps } = props

  // console.log('field props', props)

  switch (formType) {
    case FormTypes.RADIO_INPUT:

      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100,textAlign:"left", borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{ textAlign:"left",marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 500, color: '#333333', fontFamily: 'Gilroy-Medium' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>
          <DefaultRadioButton
            disabled={disabled?disabled:false}
            isFromPortal= {props.isFromPortal}
            radioElements={props.radioButtonElements}
            title={'Please select your current address.'}
            // disabled={disabled ? disabled : false}
            defaultvalue={defaultvalue}
            onChange={(e, value) => {
              console.log(e, value)
              input.onChange(value && value)
              if (fieldName === ReduxValues.profileInfoFields.GENDER) {
                setCurrentGenderRadioBtnSelected(value)
              } else if (fieldName === ReduxValues.bankInfoFields.ACCOUNT_TYPE_ID) {
                setCurrentRadioBtnSelected(value)
              }
            }}
            currentBtnselected={inputValue ? inputValue : getCurrentSelectedButton(fieldName)}
          />
         <span style={{ position: 'absolute', left: 16, bottom: 1 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> 
             <span style={{ color: 'red' , marginLeft: 1, marginBottom: 8, fontSize: 13, fontWeight: 500 ,textAlign:'start', fontFamily: 'Gilroy-Medium'}}>{error}</span> 
           </>:
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>
        </div>
        
      )

    case FormTypes.OUTLINE_INPUT:

      // case FormTypes.OUTLINE_NUMBER_INPUT: 
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: props.customRightData  ? 120 : 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10,}}>
          <p style={{ textAlign:"left", marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 500, color: '#333333', fontFamily: 'Gilroy-Medium'  }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <TextField
            {...input}
            {...inputProps}
            id="outlined-basic"
            disabled={disabled ? disabled : false}
            ref={inputRef}
            //  onChange={onchangeHandler}
            //  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
            //  value={formState.reduxformInputData[fieldName].value}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            label=""
            variant="outlined"
            fullWidth={true}
            isFromPortal={props.isFromPortal}
            customWidth={props.customWidth}
            className={Styles.textField}
            helperText={errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}
            error={!!(touched && error) ? true : false || !formState.formError[fieldName].isvalid}
            type={type ? type : 'text'}
            placeholder={placeholder}
            FormHelperTextProps={{
              className: classes.helperTextStyle,

            }}
            InputLabelProps={{
              className: classes.label,
              classes: {
              }
            }}

            inputProps={{
              min: 0, maxLength: inputLimit, style: {
                marginLeft: 0,
                // border:(touched&&error)?'1px solid red':'1px solid #333333',borderRadius:8
                // padding:5
              }
            }}

            InputProps={{
              className: classes.multilineColor,
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                error: classes.outlineerror,
                focused: classes.focusedError,
                colorSecondary: classes.colorSecondary
              },
            }}
          />
          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {(touched && error) || (!formState.formError[fieldName].isvalid) ? <CancelRounded style={{ fontSize: 18, color: 'red' }} /> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>

          {props.customRightData  && !error && <div style={{ position: 'absolute', left:8, bottom: 1, top: 82 }} width='18px' height='18px'>
            {props.customRightData()}
          </div>}
          {props.customLeftData && <div style={{ position: 'absolute', Left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {props.customLeftData()}
          </div>}
        </div>
      )

    case FormTypes.NUMBER_INPUT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{textAlign:"left", marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 500, color: '#333333',fontFamily: 'Gilroy-Medium' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <TextField
            {...input}
            {...inputProps}
            disabled={disabled ? disabled : false}
            id="outlined-basic"
            ref={inputRef}
            //  onChange={onchangeHandler}
            //  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
            //  value={formState.reduxformInputData[fieldName].value}

            label=""
            variant="outlined"
            fullWidth={true}
            className={Styles.textField}
            helperText={errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}
            error={!!(touched && error) || !formState.formError[fieldName].isvalid}
            type={type ? type : 'text'}
            placeholder={placeholder}
            FormHelperTextProps={{
              className: classes.helperTextStyle,

            }}

            onInput={(e) => {
              console.log(e)
              if (e.target.value && /^[0-9]/.test(e.target.value) && e.target.value != 'e') {
                e.target.value = e.target.value
              } else {
                e.target.value = ''
              }
            }}

            InputLabelProps={{
              className: classes.label,
              classes: {
              }
            }}

            inputProps={{
              min: 0, maxLength: inputLimit, style: {
                marginLeft: 0,
                // border:(touched&&error)?'1px solid red':'1px solid #333333',borderRadius:8
                // padding:5
              }
            }}


            InputProps={{

              className: classes.multilineColor,
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                error: classes.outlineerror,
                focused: classes.focusedError,
                colorSecondary: classes.colorSecondary
              },
            }}
          />
          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {(touched && error) || (!formState.formError[fieldName].isvalid) ? <CancelRounded style={{ fontSize: 18, color: 'red' }} /> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>
        </div>
      )

    case FormTypes.OUTLINE_NUMBER_INPUT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{textAlign:"left", marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 500, color: '#333333', fontFamily: 'Gilroy-Medium' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <TextField
            {...input}
            {...inputProps}
            disabled={disabled ? disabled : false}
            id="outlined-basic"
            ref={inputRef}
            //  onChange={onchangeHandler}
            //  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
            //  value={formState.reduxformInputData[fieldName].value}
            onWheel={e => e.target.blur()}
            onKeyDown={(e) => {
              if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
                e.preventDefault()
              }
            }}

            onScroll={(e) => {
              e.preventDefault()
            }}


            label=""
            variant="outlined"
            fullWidth={true}
            className={Styles.textField}
            helperText={errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}
            error={!!(touched && error) || !formState.formError[fieldName].isvalid}
            type={type ? type : 'text'}
            placeholder={placeholder}
            FormHelperTextProps={{
              className: classes.helperTextStyle,

            }}
            InputLabelProps={{
              className: classes.label,
              classes: {
              }
            }}

            inputProps={{
              min: 0, maxLength: inputLimit, style: {
                marginLeft: 0,
                // border:(touched&&error)?'1px solid red':'1px solid #333333',borderRadius:8
                // padding:5
              }
            }}

            onInput={(e) => {
              let isNumber = CASHeValidations.isNumber(e.target.value)
              if (isNumber) {
                e.target.value = e.target.value
              } else {
                e.target.value = ''
              }
              // e.target.value = inputLimit ? Math.max(0, parseInt(e.target.value)).toString().slice(0, inputLimit) : e.target.value
            }}

            InputProps={{
              className: classes.multilineColor,
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
                error: classes.outlineerror,
                focused: classes.focusedError,
                colorSecondary: classes.colorSecondary
              },
            }}
          />
          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {(touched && error) || (!formState.formError[fieldName].isvalid) ? <CancelRounded style={{ fontSize: 18, color: 'red' }} /> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>
        </div>
      )
    case FormTypes.LIST_INPUT_RENDER:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{ textAlign:"left",marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <Autocomplete
            disablePortal={true}
            autoHighlight={true}
            id="combo-box-demo"
            options={props.inputList}
            fullWidth={true}
            value={globalState.selectedSerialId}
            onInputChange={(e, value, isResetForm) => {
              props.onchangeHandler(value)
            }}

            onChange={(e, value) => {
              setSelectedListItem((value && value.label) ? value.label : '')
              input.onChange(value && value.label ? value.label : '')
            }}
            renderInput={(params) =>
              <TextField2
                style={{ border: (touched && error) ? '2px solid red' : '1px solid #333333', borderRadius: 5, outline: 'none' }}
                {...params}
                fullWidth={true}
                placeholder={props.placeholder}
              />}
            renderOption={(props, option) => (
              <Box key={option.idx} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                {option.label}
              </Box>
            )}
          />

          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>

        </div>
      )

    case FormTypes.MENU_LIST_INPUT_DEFAULT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{textAlign:"left", marginLeft: 1, marginBottom: 8, fontSize: 14, color: '#333333' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <Autocomplete
            disablePortal={true}
            autoHighlight={true}
            id="combo-box-demo"
            options={props.inputList}
            fullWidth={true}
            // value={props.inputValue?props.inputValue:''}
            // value={"Savings"}
            defaultValue={defaultvalue}
            classes={{ paper: classes2.paper }}

            // ListboxProps={{ style: { position: 'absolute', backgroundColor: 'red',top:120} }}
            // getOptionLabel={(option) => option.bankCode}
            onChange={(e, value) => {
              // console.log(value)
              input.onChange(value && value.label ? value.label : '')
            }}
            renderInput={(params) =>
              <TextField2
                style={{ border: (touched && error) ? '2px solid red' : '1px solid #333333', borderRadius: 5, outline: 'none' }}
                {...params}
                fullWidth={true}
                placeholder={props.placeholder}
              />}
            renderOption={(props, option) => (
              <Box key={option.idx} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
          />

          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 88 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>

        </div>
      )

    case FormTypes.MENU_LIST_INPUT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{ textAlign:"left",marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <Autocomplete
            disablePortal={true}
            autoHighlight={true}
            id="combo-box-demo"
            options={props.inputList}
            fullWidth={true}
            value={props.inputValue ? props.inputValue : ''}
            // value={"Savings"}

            defaultValue={defaultvalue}
            classes={{ paper: classes2.paper }}

            // ListboxProps={{ style: { position: 'absolute', backgroundColor: 'red',top:120} }}
            // getOptionLabel={(option) => option.bankCode}
            onChange={(e, value) => {
              // console.log(value)
              input.onChange(value && value.label ? value.label : '')


            }}
            renderInput={(params) =>
              <TextField2
                style={{ border: (touched && error) ? '2px solid red' : '1px solid #333333', borderRadius: 5, outline: 'none' }}
                {...params}
                fullWidth={true}
                placeholder={props.placeholder}
              />}
            renderOption={(props, option) => (
              <Box key={option.idx} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                {option.label}
              </Box>
            )}
          />

          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 88 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>

        </div>
      )

    case FormTypes.BANK_NAME_MENU_LIST_INPUT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 112, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{textAlign:"left", marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 500, color: '#333333', fontFamily: 'Gilroy-Medium' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <Autocomplete
            disablePortal={true}
            blurOnSelect
            id='demo'
            autoHighlight={true}
            options={props.inputList}
            fullWidth={true}
            value={globalState.bankName ? globalState.bankName : ''}
            // defaultValue={defaultvalue}
            classes={{ paper: classes2.paper }}
            disabled={disabled ? disabled : false}

            // ListboxProps={{ style: { position: 'absolute', backgroundColor: 'red',top:120} }}
            // getOptionLabel={(option) => option.bankCode}
            onChange={(e, value) => {
              if (value?.type && value?.type === "banklogos") {
                console.log(e, value, "banklogos")
                if (e.target.id != '' && e.target.id != 'demo-option-0') {
                  input.onChange(e.target.id)
                  setBankValue(e.target.id)
                } else {
                  setBankValue('')
                  dispatch(setBankNameValue(''))
                }
              } else if (value?.type && value.type === "text" && !Array.isArray(value.label)) {
                console.log(e, value, "text")
                input.onChange(value && value.label ? value.label : '')
                setBankValue(value && value.label ? value.label : '')

              }


            }}
            renderInput={(params) =>
              <TextField2
                style={{ border: (touched && error) ? '2px solid red' : '1px solid #333333', borderRadius: 5, outline: 'none' }}
                {...params}
                fullWidth={true}
                placeholder={props.placeholder}
              />}
            renderOption={(props, option) => (
              <Box key={option.idx} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }} {...props}>


                {option.idx === 0 && option.type === "banklogos" ?
                  // <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',alignItems:'center',width:"100%"}}>
                  // <img src={Images.AXIS_LOGO} style={{width:30,height:30}} alt={"AXIS BANK"} id={"AXIS BANK"}/>
                  // <img src={Images.ICICI_LOGO} style={{width:30,height:30}} alt={"ICICI BANK LIMITED"} id={"ICICI BANK LIMITED"}/>
                  // <img src={Images.HDFC_LOGO} style={{width:30,height:30}} alt={"HDFC BANK LTD"} id={"HDFC BANK LTD"}/>

                  // <img src={Images.SBI_LOGO} style={{width:30,height:30}} alt={"STATE BANK OF INDIA"} id={"STATE BANK OF INDIA"}/>
                  // </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: "100%" }}>
                    {option.label.map((ele, idx) => {
                      return <img key={idx} src={ele.img} style={{ width: 50, height: 50 }} alt={ele.name} id={ele.name} />
                    })}
                  </div>
                  :
                  !Array.isArray(option.label) && <div style={{ textAlign: 'start', width: '100%' }}>
                    {option.label}
                  </div>
                }



              </Box>
            )}
          />

          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 88 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>

        </div>
      )
    case FormTypes.default_BANK_NAME_MENU_LIST_INPUT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 112, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{textAlign:"left", marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <Autocomplete
            disablePortal={true}
            blurOnSelect
            id='demo'
            autoHighlight={true}
            options={props.inputList}
            fullWidth={true}
            {...(props?.inputValue !== undefined ? { value: props.inputValue } : {})}
            // defaultValue={defaultvalue}
            classes={{ paper: classes2.paper }}
            disabled={disabled ? disabled : false}

            // ListboxProps={{ style: { position: 'absolute', backgroundColor: 'red',top:120} }}
            // getOptionLabel={(option) => option.bankCode}
            onChange={(e, value) => {
              input.onChange(value && value.label ? value.label : '')
              if (props.onchangeHandler) {
                props.onchangeHandler(value)
              }
            }}
            renderInput={(params) =>
              <TextField2
                style={{ border: (touched && error) ? '2px solid red' : '1px solid #333333', borderRadius: 5, outline: 'none' }}
                {...params}
                fullWidth={true}
                placeholder={props.placeholder}
              />}
            renderOption={(props, option) => (
              <Box key={option.idx} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column' }} {...props}>
                <div style={{ textAlign: 'start', width: '100%' }}>
                  {option.label}
                </div>
              </Box>
            )}
          />

          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 88 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>
        </div>
      )


    case FormTypes.SELECTIVE_INPUT:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{ marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>
          <>


            <FormControl variant="standard" sx={{ m: 0, minWidth: '100%' }}>
              <Select
                {...input}
                {...inputProps}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedValue}
                name={fieldName}
                onChange={(e) => {
                  selectChangeHandler(e)
                  console.log(e.target)
                  input.onChange(e.target.value)
                }}
                displayEmpty
                label="Age"
                disableUnderline={true}
                inputProps={{ "aria-label": "Without label" }}
                style={{ border: (touched && error) ? '2px solid red' : '1px solid #333333', height: 52, borderRadius: 5 }}

              >
                {renderSelectedValue(props.placeholder, fieldName)}

                {props.selectivedata && props.selectivedata.length > 0 &&
                  props.selectivedata.map((ele, idx) => {
                    return <MenuItem key={idx} value={`${ele.key}-${ele.value}`}  >
                      <em style={{ marginLeft: 12 }}>{ele.value} </em>
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
              {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
                (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
              }
            </span>
          </>
        </div>
      )
    case FormTypes.FILE:
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{ marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>

          <input
            accept="image/*"
            // className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            ref={btnRef}
            onClick={(e) => e.target.value = null}

            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                if (e.target.files[0].name.toLowerCase().includes(".png")
                  || e.target.files[0].name.toLowerCase().includes(".jpeg")
                  || e.target.files[0].name.toLowerCase().includes(".jpg")
                  || e.target.files[0].name.toLowerCase().includes(".heic")
                ) {
                  console.log(e.target.files[0])
                  getBase64(e.target.files[0], (res) => {
                    input.onChange(res)
                  })
                } else {
                  console.log("Invalid File format", e.target.files[0].name)
                  dispatch(isPOPOpen({
                    isOpen: true,
                    msg: 'Invalid File format!',
                    cancleBtntxt: 'OK',
                    showRedirection: false,
                    title:"Error"
                  }))
                }

              } else {
              }

            }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <button onClick={uploadClickHandler} variant="raised" component="span" style={{ backgroundColor: Colors.secondaryprimary, border: 'none', borderRadius: 6, color: 'white', width: 100, height: 40, fontFamily: 'Gilroy-Medium', outline: 'none' }} >
              {!selectedImage ? 'Upload' : 'Change'}
            </button>
          </div>


          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> <span style={{ color: 'red', fontSize: 13, fontFamily: 'Gilroy-Medium' }}>{errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}</span> </> :
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>
        </div>
      )
    // case FormTypes.DATE_PICK_INPUT:
    //   return (
    //     <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
    //       <p style={{ marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
    //         {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
    //       </p>
    //       <LocalizationProvider dateAdapter={AdapterDateFns} >
    //         <Stack spacing={3}>

    //           <DesktopDatePicker
    //             inputFormat="dd/MM/yyyy"
    //           maxDate={new Date()}
    //           onChange={(e, val) => {
    //             // console.log(e,val,input.value)
    //             return input.onChange(e)
    //           }}
    //           value={input.value}
    //           renderInput={(params2) => <TextField
    //             fullWidth={true}
    //             variant="outlined"
    //             helperText={errorText || !formState.formError[fieldName].isvalid && formState.formError[fieldName].msg}

    //             {...params2}

    //           />}
    //         />
    //       </Stack>
    //     </LocalizationProvider>
    //     {/* <span  style={{position:'absolute',left:16,bottom:1,top:82}} width='18px' height='18px'>
    //                {(touched&&error)?<CancelRounded  style={{fontSize:18,color:'red'}}/>:
    //                (touched&&error)&&<InfoOutlined style={{fontSize:18,color:'#314451'}} />
    //                 }
    //           </span> */}
    //   </div>

    // );

    case FormTypes.DATE_INPUT:
      console.log("monemt date",moment().subtract(18,'year'),moment())
      let maxDateCustom=moment()?.subtract(18,'year')
      return (
        <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
          <p style={{ marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 500, color: '#333333' ,textAlign:'start', fontFamily: 'Gilroy-Medium'}}>
            {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                  disabled={disabled?disabled:false}
                  sx={{width:"100%"}}
                   format='DD/MM/YYYY'
                  minDate={dayjs().subtract(100, 'year')}
                  maxDate={dayjs().subtract(18, 'year')}
                  openTo="year"
                   value={form?.userPersonalDetailsForm?.values?.DOB_ID }
                   onChange={(newValue) => {
                    setDateValue(newValue);
                    input.onChange(newValue)
                  }}
                />
                </LocalizationProvider>
          {props.rightView && <div style={{ position: 'absolute', right: 15, top: '50%', transform: 'translate(-50%,-50%)' }}>
            {props.rightView}
          </div>}

          <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
            {(touched && error) ? <><CancelRounded style={{ fontSize: 18, color: 'red' }} /> 
             <span style={{ color: 'red' , marginLeft: 1, marginBottom: 8, fontSize: 13, fontWeight: 500 ,textAlign:'start', fontFamily: 'Gilroy-Medium'}}>{error}</span> 
           </>:
              (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
            }
          </span>
        </div>

      )

    case FormTypes.OUTLINE_CAPTCHA_INPUT:
      return (
        <div>
          <div className={`container-fluid `} style={{ position: 'relative', minHeight: 100, borderBottom: showBottomBorder ? '3px solid #f7f7f7' : 'none', marginTop: 10 }}>
            <p style={{ marginLeft: 1, marginBottom: 8, fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
              {label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
            </p>

            <div style={props.rightView ? { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', flexWrap: 'wrap' } : { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>

              <TextField
                {...input}
                {...inputProps}
                id="outlined-basic"
                disabled={disabled ? disabled : false}
                //  onChange={onchangeHandler}
                //  onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                // value={formState.reduxformInputData[fieldName].value}
                label=""
                variant="outlined"
                fullWidth={false}
                className={Styles.textField}
                helperText={errorText}
                error={touched && error}
                type={type ? type : 'text'}
                placeholder={placeholder}
                FormHelperTextProps={{
                  className: classes.helperTextStyle,

                }}
                InputLabelProps={{
                  className: classes.label,
                  classes: {
                  }
                }}
                inputProps={{
                  min: 0, maxLength: inputLimit, style: {
                    marginLeft: 0,
                    // border:(touched&&error)?'1px solid red':'1px solid #333333',borderRadius:8
                    // padding:5
                  }
                }}
                InputProps={{
                  className: classes.captchaStyle,
                  classes: {
                    root: classes.cssOutlinedInput,
                    notchedOutline: classes.notchedOutline,
                    error: classes.outlineerror,
                    focused: classes.focusedError,
                    colorSecondary: classes.colorSecondary
                  },
                }}
              />

              {props.rightView && <div style={{ width: '40%', textAlign: 'center' }}>
                {props.rightView}
              </div>}

            </div>
            <span style={{ position: 'absolute', left: 16, bottom: 1, top: 82 }} width='18px' height='18px'>
              {(touched && error) ? <CancelRounded style={{ fontSize: 18, color: 'red' }} /> :
                (touched && error) && <InfoOutlined style={{ fontSize: 18, color: '#314451' }} />
              }
            </span>
          </div>
        </div>
      )

    default: return null
  }
}
export default withStyles(Defaultstyles)(InputTextField)