import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useDispatch, useSelector } from 'react-redux'
import { isPOPOpen, isLoading } from '../../Store/Actions/GlobalActions'
import { logoutUser } from '../../Store/Actions/RestAPiActions'
import CommonModal from './CommonModal'
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";
import Images from '../../Images/Images';
import Colors from '../../Constants/Colors';
import { Global } from "@emotion/react";
import CASHeUtils from '../../Utils/CASHeUtils';


let authData = JSON.parse(localStorage.getItem('authData'))


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    // alignItems: 'flex-end',
    // justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid white',
    borderRadius: "10px 10px 0px 0px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    minHeight: 150,
    outline: 'none',
    width: 420,
    margin: theme.spacing(0, 2, 0),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column'

  },
}));


const POPUPModel = (props) => {
  const amountDetails = JSON.parse(sessionStorage.getItem('amountDetails'));

  const classes = useStyles();
  const dispatch = useDispatch();
  const globalState = useSelector(state => state.globalState)
  const navigate =  useNavigate()
  const ReachOutFooterText=false;



 
  const ClipLoader = (flag) => {
    dispatch(isLoading(flag))
  }



const closeModal=()=>{
  dispatch(isPOPOpen({
    isOpen: false,
    msg: null,
    btntxt: null
  }))
  if(globalState.popUpModel && globalState.popUpModel.shouldLogout === true){
    ClipLoader(true)
    localStorage.removeItem('authData')
    sessionStorage.clear()
    setTimeout(() => {
      ClipLoader(false)
      navigate('/')
    }, 1000);
  }
}
  const handleCloseAndsuccessHandler = () => {
  
    dispatch(isPOPOpen({
      isOpen: false,
      msg: null,
      btntxt: null
    }))
    if (globalState.popUpModel && globalState.popUpModel.successHandler) {
      globalState.popUpModel.successHandler()
    }else if(globalState.popUpModel && globalState.popUpModel.navigatetohome){
      ClipLoader(true)
      if(authData && authData['entity'] && authData.entity.access_token && globalState.popUpModel && globalState.popUpModel.shouldLogout === true){
        handleLogout(authData.entity.access_token)
      }else if(globalState.popUpModel && globalState.popUpModel.shouldLogout === true){
        localStorage.removeItem('authData')
        sessionStorage.clear()
      setTimeout(() => {
        ClipLoader(false)
        navigate('/')
      }, 1000);
    }else{
      ClipLoader(false)
    }
    }
  };

  const handleLogout=(token)=>{
    ClipLoader(true)
    localStorage.removeItem('authData')
    sessionStorage.clear()
    setTimeout(() => {
      ClipLoader(false)
      navigate('/')
    }, 1000);
    
  }

  const handleCancleAndfailureHandler = () => {
  
    dispatch(isPOPOpen({
      isOpen: false,
      msg: null,
      btntxt: null,
      cancleBtntxt: null
    }))
    if (globalState.popUpModel && globalState.popUpModel.failureHandler) {
      globalState.popUpModel.failureHandler()

    }else if(globalState.popUpModel && globalState.popUpModel.navigatetohome){
      ClipLoader(true)

      if(authData && authData['entity'] && authData.entity.access_token && globalState.popUpModel && globalState.popUpModel.shouldLogout === true){
        handleLogout()
      }else if(globalState.popUpModel && globalState.popUpModel.shouldLogout === true){
        localStorage.removeItem('authData')
        sessionStorage.clear()
        setTimeout(() => {
          ClipLoader(false)
          navigate('/')
        }, 1000);
      }else{
        ClipLoader(false)
      }
    }
  }

  console.log(globalState)

  const renderContent=()=>{
    return <div  style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',backgroundColor:'white',width:'550px',outline:'none',borderRadius:'10px 10px 10px 10px'}}>
     <div style={{height:40,with:'100%', display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'0.7px solid rgba(0, 0, 0, 0.25)',minHeight:"56px"}} className={"container-fluid"}>
              {/* <img
                src={Images.karatHeaderLogo}
                alt="Modal image"
                style={{width:40}}
              /> */}
              <div style={{fontWeight:"500",fontFamily:"Gilroy-Semibold",fontSize:"17px",}}>{props?.popUpData?.title || <>
                <img
                src={Images.karatHeaderLogo}
                alt="Modal image"
                style={{width:40}}
              /></>
              }</div>
          {props?.popUpData?.showCrossIcon && <CloseIcon className="icon-style" onClick={closeModal} style={{color:'Black'}} />}
      </div>
      <p style={{padding:"8px 16px", minHeight:50,marginBottom:"8px",fontFamily: 'Gilroy-SemiBold' ,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'column'}}>{props.popUpData.msg}</p>


      <div className='mt-2 mb-3'>
        <img src={globalState.popUpModel.customImg?globalState.popUpModel.customImg:Images.ErrorDesignPopUp} style={{width:140}}/>
      </div>
      <div className='mt-2 mb-3'>
      {!!props.popUpData.PopUpFooterText?
       <p style={{padding:"4px 16px",margin:"0", minHeight:50,fontSize:"14px",fontFamily:"Gilroy-Regular", fontWeight:"400" ,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'column'}}>
        {props.popUpData.PopUpFooterText}</p>
        :<div></div>
}
      </div>
      {/* {!!props.popUpData.ReachOutFooterText && (
        <p
          style={{padding: "4px 16px",margin: "0",minHeight: 50,fontSize: "14px",fontFamily: "Gilroy-Regular",fontWeight: "400",display: 'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center',flexDirection: 'column'}}>
          Reach out to{' '}
          <a style={{ textDecoration: "none" }} href="mailto:hello@13karat.in">
            hello@13karat.in
          </a>{' '}
          for any assistance
        </p>
      )} */}
      {props.popUpData.ReachOutFooterText && (
  <div style={{padding: "12px 16px",margin: "0",minHeight: 50,fontSize: "14px",fontFamily: "Gilroy-Regular",fontWeight: "400",textAlign: 'center',}}>
          Reach out to {' '}
          <a style={{ textDecoration: "none",display:"inline" }} href="mailto:partnersupport@13karat.in"> partnersupport@13karat.in </a> {' '} for<div></div>
          <div>  any assistance</div>
      
  </div>
)}

      
    
  

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%',fontFamily: 'Gilroy-SemiBold',marginBottom:"10px" }}>
      {(globalState.popUpModel && globalState.popUpModel.cancleBtntxt) &&
        <button onClick={handleCancleAndfailureHandler} style={{ marginBottom:10,border: 'none', outline: 'none', borderRadius: 5, width: `${!globalState.popUpModel.btntxt ? 90 : 40}%`, height: 38, backgroundColor: "#fff", color:"#2D2F51" ,border:"1px solid #2D2F51"}}>{globalState.popUpModel.cancleBtntxt}</button>
      }

      {(globalState.popUpModel && globalState.popUpModel.btntxt) &&
        <button onClick={handleCloseAndsuccessHandler} style={{marginBottom:10, border: 'none', outline: 'none', borderRadius: 5, width: `${!globalState.popUpModel.cancleBtntxt ? 90 : 40}%`, height: 38, backgroundColor: Colors.secondaryprimary, color: 'white' }}>{globalState.popUpModel.btntxt}</button>
      }

    </div>
  </div>
  }

  const getPopupWidth=()=>{
    if( window.location.pathname.includes("onboarding") ){
      return window.innerWidth-800
    }else{
      return "auto"
    }
  }
  return (
    <React.Fragment>
      {/* <form
        id="postTrxForm"
        action="/"
        method="POST"
        style={{ display: "none" }}
      /> */}

       <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open} style={{margin:'auto',marginLeft:getPopupWidth()}}>

          
          {renderContent()}
        
        </Fade>
      </Modal>
    </React.Fragment>
  );
}


export default POPUPModel;