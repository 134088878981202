import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
//import Colors from '../../Constants/Colors';
import SyncLoader from "react-spinners/SyncLoader";
import Modal from '@material-ui/core/Modal';


const  DefaultButton =(props)=>  {
        return (
            <>
                   <Button  variant={props.variant} {...props} 
               onClick={props.onClick} 
               disabled={props.disabled || props?.showloader}
               fullWidth={props.fullWidth}
               >
                {props?.showloader? <SyncLoader loading={props?.showloader}  size={props.loaderSize?props.loaderSize:10} color={props.loaderColor?props.loaderColor:'white'} margin={2}/>:
                   <span style={{textTransform:'none',fontFamily:"Gilroy-SemiBold",...props.textstyle}}>{props.title}</span>
                }
               </Button>
               
            
             </>
        )
    }
export default DefaultButton;
