import PersonIcon from "@mui/icons-material/Person";
import React, { Fragment, useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as BaseScreen from "../../BaseScreen";
import Strings from "../../Constants/Strings";
import Images from "../../Images/Images";
import {
  isLoading,
  setAadharNumber,
  isPOPOpen,
  setPanDocument,
  setAadharDoc,
  setProfileDocument,
  setGstNumber,
  setGstDocument,
  setStepCount,
} from "../../Store/Actions/GlobalActions.js";
import clasess from "../../Style/DocumentUpload.module.css";
import {
  uploaddocDetail,
  fetchDocDetail,
  fetchProfile,
  setFetch_Doc_State,
  GetPdfInvestment,
} from "../../Store/Actions/RestAPiActions.js";
import Stack from "@mui/material/Stack";
import CommonModal from "../../Components/OnboardingComponents/CommonModal";
import Button from "@mui/material/Button";
import CASHeUtils from "../../Utils/CASHeUtils";
import EditIcon from "@mui/icons-material/Edit";
import CASHeValidations from "../../Utils/CasheValidator";
import Colors from "../../Constants/Colors";
// import Camera from "../components/Camera";
import ReduxValues from "../../Store/ReduxValues";
import IconButton from "@mui/material/IconButton";
import Slider from "react-slick";
import { Card } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutSupportComponent from '../../Components/OnboardingComponents/LogoutSupportComponent';
import {getStepperColor,renderStepper} from '../../Components/PortelComponents/UserPersonalDetails'

const PDFJS = require("pdfjs-dist/webpack");

const docType = Strings.docType;


export const convertPdfToImages = async (file) => {
  const images = [];
  const data = await readFileData(file);
  // const data = file
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  await page.render({ canvasContext: context, viewport: viewport }).promise;
  images.push(canvas.toDataURL());

  canvas.remove();
  return images;
};

export  const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

const KycDocumentUpload = (props) => {
  const panDocRef = useRef();
  const gstDocRef = useRef();
  const location = useLocation();
  const docAadharFrontRef = useRef();
  const docAadharFrontRef2 = useRef();

  const docAadharBackRef = useRef();
  const docAadharBackRef2 = useRef();

  const camRef = useRef();
  const startcamref = useRef();

  const globalState = useSelector((state) => state.globalState);

  const restApiState = useSelector((state) => state.restApiState);
  const fetchDocResponse = restApiState?.fetchDocResponse || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gstFile, setGstFile] = useState(null);
  const [panDoc, setPanDoc] = useState(null);
  const [aadharFrontDocument, setAadharFontDocument] = useState(null);
  const [aadharBackDocument, setAadharBackDocument] = useState(null);
  const [openCam, setOpenCam] = useState(false);
  const [isOpenCamModal, setIsOpenCamModal] = useState(false);

  const [aAdharFrontPayload, setAadharFrontPayload] = useState(null);
  const [isAdharFront, setIsAdharFront] = useState(false);
  const [aAdharBackPayload, setAadharBackPayload] = useState(null);
  const [isAdharBack, setIsAdharBack] = useState(false);
  const [aAdharUnifiedPayload, setAadharUnifiedPayload] = useState(null);
  const [aadharError, setAadharError] = useState(false);
  const [gstError, setGstError] = useState(false);

  const [panFileSizeError, setPanFileSizeError] = useState(false);
  const [addharFileSizeError, setAddharFileSizeError] = useState(false);
  const [isProfileFetched, setIsProfileFetched] = useState(false);
  const [isCheckedElement, SetisCheckedElement] = useState("SingleDocument");

  const userData = restApiState?.fetchProfileResponse || {};

  const fileNotUpload =
  !((userData?.aadhar_number != null || globalState?.aadharNumber?.split(" ")?.join("")?.length === 12 )) ||
  !panDoc ||
  !(isAdharFront || restApiState?.fetchDocResponse?.aadhaarUnified?.imgUrl) ||
  (isCheckedElement !== "SingleDocument" ? !isAdharBack : false);

  const fetchProfileHandler = () => {
    ClipLoader(true)
    dispatch(fetchProfile({
      callback: (res) => {
        if (res) {
          ClipLoader(false)
        }
      }
    }))
  }

  

  useEffect(() => {
    fetchProfileHandler()
    console.log("File: fileNotUpload", fileNotUpload);
    console.log("File: panDoc", panDoc);
    console.log("File: aadharFrontDocument", aadharFrontDocument);
    console.log("File: aadharBackDocument", aadharBackDocument);
  }, [aadharBackDocument, aadharFrontDocument, fileNotUpload, panDoc]);

  useEffect(() => {
    if (!!fetchDocResponse?.pan?.imgUrl) {
      setPanDoc(fetchDocResponse.pan.imgUrl);
    }
    // setIsAdharFront(!!fetchDocResponse?.aadhaarFrontSide);
    // setIsAdharBack(!!fetchDocResponse?.aadhaarBackSide);
  }, [fetchDocResponse]);

  useEffect(() => {
    setIsAdharFront(!!aAdharFrontPayload);
    setIsAdharBack(!!aAdharBackPayload);
  }, [aAdharBackPayload, aAdharFrontPayload])

  const ClipLoader = (flag) => {
    dispatch(isLoading(flag));
  };

  const reloadHandler = () => {
    window.location.reload();
  };

  const uploadDocumentHandler = (request, msg, cb, image,showOnlyCustomMsg,) => {
    ClipLoader(true);
    dispatch(
      uploaddocDetail({
        request,
        callback: (res) => {
          console.log("document upload success:", res);
          ClipLoader(false);
          if (res && !res.isErrorOccure) {
            if (msg) {
              dispatch(
                isPOPOpen({
                  isOpen: true,
                  btntxt: "OK",
                  msg: showOnlyCustomMsg?msg:`${msg} Uploaded Successfully`,
                  title: "Document Upload",
                  customImg: image ? image : Images.SuccessPanCardNew,
                  // PopUpFooterText:"Möliga myska minde. Hyde vigt real. Gaprertad ode rubase. Oktig hemid skräpbot. "
                  // successHandler:shouldReload?reloadHandler:null
                })
              );
              if (cb) {
                cb(true);
              }
            } else {
              if (cb) {
                cb(true);
              }
            }
          } else {
            if (cb) {
              cb(false);
            }

            // dispatch(isPOPOpen({
            //   isOpen:true,
            //   btntxt:"ok",
            //   msg:'Something went wrong'

            // }))
          }
        },
      })
    );
  };

  const navigatetoDashboard = () => { 

    navigate('/onboarding/agreement')

  }
  const panInputChangeHandler = () => { };
  const skipButton = () => {
    
    if(location?.state?.navigateTo){
      navigate(location?.state?.navigateTo )
    }
    else if(  restApiState?.fetchProfileResponse?.is_esigned===true){
      navigate('/portel/Dashboard')
    }
    else{
      navigate('/onboarding/agreement')
    }

  }

  const successAadhaarHandler = (aadharRes) => {
    if(aadharRes) {
      dispatch(
        isPOPOpen({
          isOpen: false
        })
      );
      ClipLoader(true)

    dispatch(fetchProfile({
      callback: (res) => {
        if (res && res.is_esigned == true) {
          ClipLoader(false)
          navigate('/portel/Dashboard')
        }else{
          skipButton()
        }
      }
    }))
  }
  }

  const rightBtnHandler = () => {
    
    let AadhaarValueRedux=globalState?.aadharNumber?.split(" ")?.join("") || null
    const isValid= AadhaarValueRedux?.length === 12 
    
    if(isValid && !restApiState?.fetchProfileResponse?.aadhar_number){
        const formData = new FormData();
        formData.append("doc_type", docType.aadhaarUnified);
        formData.append("aadhaar",AadhaarValueRedux);
        uploadDocumentHandler(formData, "Aadhar Number saved", successAadhaarHandler, Images.AddharSuccessNew,true);
  
    } else {
      successAadhaarHandler(true)
    }
  };

  const leftBtnHandler = () => {
    alert("left Button clicked");
  };

  const uploadAadhaarFront = () => {
    alert("Upload Front");
  };

  const uploadAadhaarBack = () => {
    alert("Upload Back");
  };

  const [isChecked, setIsChecked] = useState(true);
  const [isSelected, setIsSelected] = useState(false);

  const radioButtonHandler = (e) => {
    console.log(e.target.value);
    setIsChecked(e.target.checked);
    if (e.target.value === "MultipleDocument") {
      SetisCheckedElement("MultipleDocument");
      setIsSelected(true);
    } else {
      SetisCheckedElement("SingleDocument");
      setIsSelected(false);
    }
    // setOptionSelected(true);
  };

  const handleLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    navigate('/')
  }
  const docHandler = (doctype, customdocRef) => {
    if(!checkAadharInputFieldError(doctype) && !checkGstInputFieldError(doctype)){
    if (doctype === docType.pan) {
      if(panDocRef?.current?.value){
        panDocRef.current.value=null
      }
      panDocRef?.current?.click();
    } else if (doctype === docType.aadhaarFrontSide) {
        // docAadharFrontRef?.current?.value=null
       
     
      if (customdocRef) {
        if(customdocRef?.current?.value){
          customdocRef.current.value=null
        }
        customdocRef?.current?.click();
      } else {
        if(docAadharFrontRef?.current?.value){
          docAadharFrontRef.current.value=null
        }
        docAadharFrontRef?.current?.click();
      }
    } else if (doctype === docType.aadhaarBackSide) {
      if (customdocRef) {
        if(customdocRef?.current?.value){
          customdocRef.current.value=null
        }
        customdocRef?.current?.click();
      } else {
        if(docAadharBackRef?.current?.value){
          docAadharBackRef.current.value=null
        }
        docAadharBackRef?.current.click();
      }
    } else if (doctype === docType.upload_GST_Certificate) {
      if (customdocRef) {
        if(customdocRef?.current?.value){
          customdocRef.current.value=null
        }
        customdocRef?.current?.click();
      } else {
        if(gstDocRef?.current?.value){
          gstDocRef.current.value=null
        }
        gstDocRef?.current?.click();
      }
    }
  }
  };

  const renderCameraContent = () => {
    return (
      <div
        style={{
          marginTop: 20,
        }}
      >
        {/* <Camera
          onCaptureImage={onCaptureImage}
          closeCamref={camRef}
          startCamref={startcamref}
        /> */}
      </div>
    );
  };

  const closeCamModal = () => {
    camRef.current.click();
    setIsOpenCamModal(false);
  };

  const renderBottomCameraModal = () => {
    return (
      <CommonModal
        open={isOpenCamModal}
        onClose={closeCamModal}
        bgReq={true}
        minimumHeight={400}
        freezBackdrop={true}
        ShowHeaderText={true}
        headerStyles={{
          height: 50,
          with: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        headerText={"Camera"}
        headerTextStyles={{
          fontSize: 17,
          fontFamily: "Gilroy-SemiBold",
          color: "#333333",
        }}
        innerChildData={renderCameraContent()}
      />
    );
  };

  const checkAadharInputFieldError=(doctype)=>{
    if((doctype === docType.aadhaarBackSide || doctype === docType.aadhaarFrontSide || doctype === docType.aadhaarUnified) && (!/^[2-9]{1}[0-9]{11}$/.test(globalState?.aadharNumber?.split(" ")?.join("")) && !restApiState?.fetchProfileResponse?.aadhar_number)) {
      setAadharError(true);
      return true
   }else{
     setAadharError(false);
     return false
   }
  }

  const checkGstInputFieldError=(doctype)=>{
    if((doctype === docType.upload_GST_Certificate) && (CASHeValidations.validateGSTNNumber(globalState?.gstNumber?.split(" ").join("")))) {
      setGstError(true);
      return true
   }else{
    setGstError(false);
    return false
   }
  }

  const handleImageChange = async (e, doctype) => {
    e.preventDefault();
    const files = e.target.files[0];
    // make sure that it's not null or undefined
    const maxSizeInBytes = 10 * 1024 * 1024; // Set maximum file size (5 MB in this example)
    // Check if the file size exceeds the maximum size
    if (files.size > maxSizeInBytes) {
      // Handle the case where the file size exceeds the maximum allowed size
      console.log("File size exceeds the limit.");
      if (doctype === docType.pan) {
        setPanFileSizeError(true);
      }
      if (doctype === docType.aadhaarFrontSide) {
        setAddharFileSizeError(true);
      }
      return false;
    } else {
      // The file size is within the limit, proceed with further operations
      // Your existing logic to handle the file
      if (doctype === docType.pan) {
        setPanFileSizeError(false);
      }
      if (doctype === docType.aadhaarFrontSide) {
        setAddharFileSizeError(false);
      }
      // ... rest of your code
    }
    
   if(!checkAadharInputFieldError(doctype) && !checkGstInputFieldError(doctype)){
    if (doctype === docType.upload_GST_Certificate) {
      if (
        files &&
        (files.name.toLowerCase().includes(".png") ||
          files.name.toLowerCase().includes(".jpeg") ||
          files.name.toLowerCase().includes(".jpg") ||
          files.name.toLowerCase().includes(".pdf") ||
          files.name.toLowerCase().includes(".heic"))
      ) {
        setGstFile(files);
        var reader = new FileReader();
        if (files.name.toLowerCase().includes(".pdf")) {
          let imgUrlData = await covertPdfToImg(files);
          if (imgUrlData) {
              dispatch(setGstDocument(imgUrlData));
              dispatch({
                type: ReduxValues.RestApiActions.FETCH_DOC,
                payload: {
                  docType: Strings.docType.upload_GST_Certificate,
                  res: { imgUrl: imgUrlData, data: null },
                },
              });
          }
        } else {
          reader.onloadend = function () {
            dispatch(setGstDocument(reader.result));
            dispatch({
              type: ReduxValues.RestApiActions.FETCH_DOC,
              payload: {
                docType: Strings.docType.upload_GST_Certificate,
                res: { imgUrl: reader.result, data: null },
              },
            });
          };
          reader.readAsDataURL(files);
        }

        const formData = new FormData();
        formData.append("doc_type", docType.upload_GST_Certificate);
        formData.append("doc", files);
        if(!restApiState?.fetchProfileResponse?.gst_certificate_no){
          formData.append(
            "gst_certificate_no",
            globalState.gstNumber
              ? globalState.gstNumber.split(" ").join("")
              : ""
          );
        }
      
        uploadDocumentHandler(formData, "GST", null, Images.SuccessNewWithdraw);
      } else {
        dispatch(
          isPOPOpen({
            isOpen: true,
            msg: "Please upload a valid format",
            btntxt: "OK",
            title: "Document Upload"
          })
        );
      }
    } else if (doctype === docType.pan) {
      if (
        files &&
        (files.name.toLowerCase().includes(".png") ||
          files.name.toLowerCase().includes(".jpeg") ||
          files.name.toLowerCase().includes(".jpg") ||
          files.name.toLowerCase().includes(".pdf") ||
          files.name.toLowerCase().includes(".heic"))
      ) {
        setPanDoc(files);
        var reader = new FileReader();
        if (files.name.toLowerCase().includes(".pdf")) {
          let imgUrlData = await covertPdfToImg(files);
          if (imgUrlData) {
            dispatch(
              setFetch_Doc_State({
                payload: {
                  docType: Strings.docType.pan,
                  res: { imgUrl: imgUrlData, data: null },
                },
              })
            );

            const formData = new FormData();
            formData.append("doc_type", docType.pan);
            formData.append("doc", files);
            uploadDocumentHandler(formData, "PAN", null, Images.SuccessPanCardNew);
            return;
          }
        } else {
          reader.onloadend = function () {
            dispatch(setPanDocument(reader.result));
            dispatch({
              type: ReduxValues.RestApiActions.FETCH_DOC,
              payload: {
                docType: Strings.docType.pan,
                res: { imgUrl: reader.result, data: null },
              },
            });
          };
          reader.readAsDataURL(files);
          const formData = new FormData();
          formData.append("doc_type", docType.pan);
          formData.append("doc", files);
          uploadDocumentHandler(formData, "PAN", null, Images.SuccessPanCardNew);
        }
      } else {
        dispatch(
          isPOPOpen({
            isOpen: true,
            msg: "Please upload a valid format",
            btntxt: "OK",
            title: "Document Upload"
          })
        );
      }
    } else if (doctype === docType.aadhaarFrontSide) {
      if (
        files &&
        (files.name.toLowerCase().includes(".png") ||
          files.name.toLowerCase().includes(".jpeg") ||
          files.name.toLowerCase().includes(".jpg") ||
          files.name.toLowerCase().includes(".pdf") ||
          files.name.toLowerCase().includes(".heic"))
      ) {
        setAadharFontDocument(files);
        var reader = new FileReader();
        if (files.name.toLowerCase().includes(".pdf")) {
          let imgUrlData = await covertPdfToImg(files);
          if (imgUrlData) {

            dispatch(setAadharDoc({ aadharDocFront: imgUrlData }));

            const formData = new FormData();
            formData.append(
              "doc_type",
              isSelected ? docType.aadhaarFrontSide : docType.aadhaarUnified
            );
            formData.append("doc", files);
            // formData.append(
            //   "aadhaar",
            //   globalState.aadharNumber
            //     ? globalState.aadharNumber.split(" ").join("")
            //     : ""
            // );
            setAadharFrontPayload(files);
          }
        } else {

          reader.onloadend = function () {
            dispatch(setAadharDoc({aadharDocFront:reader.result}));
            dispatch({
              type: ReduxValues.RestApiActions.FETCH_DOC,
              payload: {
                docType: Strings.docType.aadhaarFrontSide,
                res: { imgUrl: reader.result, data: null },
              },
            });
          };
          reader.readAsDataURL(files);
          const formData = new FormData();
          formData.append(
            "doc_type",
            isSelected ? docType.aadhaarFrontSide : docType.aadhaarUnified
          );
          formData.append("doc", files);
          // formData.append("aadhaar", globalState.aadharNumber ? globalState?.aadharNumber?.split(" ")?.join("") : "");
          setAadharFrontPayload(files);
        }

        const formData = new FormData();
        formData.append("doc_type", isSelected ? docType.aadhaarFrontSide : docType.aadhaarUnified);
        formData.append("doc", files);
        // formData.append("aadhaar", globalState.aadharNumber ? globalState?.aadharNumber?.split(" ")?.join("") : "");
        uploadDocumentHandler(formData, isSelected? "Aadhar Front": "Aadhar", null, Images.AddharSuccessNew);
        // uploadDocumentHandler(formData,"Aadhar front side")
      } else {
        dispatch(
          isPOPOpen({
            isOpen: true,
            msg: "Please upload a valid format",
            btntxt: "OK",
            title: "Document Upload"
          })
        );
      }
    } else if (doctype === docType.aadhaarBackSide) {
      if (
        files &&
        (files.name.toLowerCase().includes(".png") ||
          files.name.toLowerCase().includes(".jpeg") ||
          files.name.toLowerCase().includes(".jpg") ||
          files.name.toLowerCase().includes(".pdf") ||
          files.name.toLowerCase().includes(".heic"))
      ) {
        setAadharBackDocument(files);
        var reader = new FileReader();
        if (files.name.toLowerCase().includes(".pdf")) {
          let imgUrlData = await covertPdfToImg(files);
          if (imgUrlData) {
            // dispatch(
            //   setFetch_Doc_State({
            //     payload: {
            //       docType: Strings.docType.aadhaarBackSide,
            //       res: { imgUrl: imgUrlData, data: null },
            //     },
            //   })
            // );
            dispatch(setAadharDoc({ aadharDocBack: imgUrlData }));

            const formData = new FormData();
            formData.append("doc_type", docType.aadhaarBackSide);
            formData.append("doc", files);
            // formData.append(
            //   "aadhaar",
            //   globalState.aadharNumber
            //     ? globalState.aadharNumber.split(" ").join("")
            //     : ""
            // );

            setAadharBackPayload(files);
          }
        } else {
          reader.onloadend = function () {
            dispatch(setAadharDoc({ aadharDocBack: reader.result }));
            dispatch({
              type: ReduxValues.RestApiActions.FETCH_DOC,
              payload: {
                docType: Strings.docType.aadhaarBackSide,
                res: { imgUrl: reader.result, data: null },
              },
            });
          };
          reader.readAsDataURL(files);
          const formData = new FormData();
          formData.append("doc_type", docType.aadhaarBackSide);
          formData.append("doc", files);
          // formData.append(
          //   "aadhaar",
          //   globalState.aadharNumber
          //     ? globalState.aadharNumber.split(" ").join("")
          //     : ""
          // );

          setAadharBackPayload(files);
        }
        // uploadDocumentHandler(formData,"Aadhar back side")
        setIsSelected(true);

        // reader.readAsDataURL(files);
        const formData = new FormData();
        formData.append("doc_type", docType.aadhaarBackSide);
        formData.append("doc", files);
        uploadDocumentHandler(formData, "Aadhar Back", null, Images.AddharSuccessNew);
      } else {
        dispatch(
          isPOPOpen({
            isOpen: true,
            msg: "Please upload a valid format",
            btntxt: "OK",
            title: "Document Upload"
          })
        );
      }
    }
  };
}

  const aadharNumberChangeHandler = (e) => {
    setAadharError(false);
    const value = e.target.value.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter((s) => s.length > 0).join(" ");
    if (CASHeValidations.validateAadhaar(value)) {
      dispatch(setAadharNumber(value));

      // if(value?.length===14){
      //   const formData = new FormData();
      //   formData.append("doc_type", docType.aadhaarUnified);
      //   formData.append("aadhaar",value?.split(" ").join(""));
      //   uploadDocumentHandler(formData, "Aadhar Number saved", null, Images.AddharSuccessNew,true);
      // }

    }
  };

  const fetchDoc = (doc_type, cb) => {
    console.log("fetchDoc",doc_type)
    let req = {
      doc_type: doc_type,
    };
    ClipLoader(true);
    dispatch(
      fetchDocDetail({
        req: req,
        docType: doc_type,
        callback: (res) => {
          if (res) {
            console.log(res);
            cb(res);
          } else{
            
            cb(null);
          }
        },
      })
    );
  };
  const fetchGstDoc = () => {
    fetchDoc(Strings.docType.upload_GST_Certificate, (res) => {
    });
  };

  const fetchPanDoc = () => {
    fetchDoc(Strings.docType.pan, (res) => {
    });
  };

  const fetchAadharUnifiedDoc = () => {
    fetchDoc(Strings.docType.aadhaarUnified, (res) => {
      if (res) {
      }else{
        fetchAadharFrontDoc(Strings.docType.aadhaarFrontSide);
      }
    });
  };

  const fetchAadharFrontDoc = (doc_type) => {
    fetchDoc(doc_type, (res) => {
      if (res) {
        fetchAadharBackDoc(Strings.docType.aadhaarBackSide);
        SetisCheckedElement("MultipleDocument");
        setIsSelected(true);
      }
    });
  };

  const fetchAadharBackDoc = (doc_type) => {
    fetchDoc(doc_type);
  };

  useMemo(() => {
    // fetchProfileDoc(Strings.docType.profile)
  }, []);

  useEffect(() => {
    console.log("fetchDoc useEffect")
    fetchPanDoc()
    fetchAadharUnifiedDoc()
    fetchGstDoc();
    // fetchAadharBackDoc()
    
  }, [isProfileFetched]);

  const goBack = () => {
    if (
      restApiState.fetchProfileResponse &&
      restApiState.fetchProfileResponse.is_esigned
    ) {
      navigate(-1);    
    }else {
      navigate("/onboarding/steps");   
    }

    // if (
    //   restApiState.fetchProfileResponse &&
    //   restApiState.fetchProfileResponse.is_profile_completed &&
    //   location.state &&
    //   location.state.isFromProfilePage
    // ) {
    //   navigate("/onboarding/agreement");    }
    // console.log("location.state:", location.state, location.pathname);
    // if (location && location.state && location.state["isfromCkycPage"]) {
    //   navigate("/onboarding/agreement");    } else if (location && location.pathname === "/CKycStatus") {
    //     navigate("/onboarding/agreement");    }
    // // if(location && location.state&& location.state['isFromLoginScreen']){}
    // else {
    //   navigate(-1);
    // }
  };
  useEffect(() => {
    dispatch(setStepCount(6));
}, [])


  const GSTSuccessHandlerChange=(res)=>{
    if(res){
      fetchProfileHandler()
  }
  }

  const gstInputChangeHandler = (e) => {
    setGstError(false);
    const value = e.target.value
    if(value?.length<=15){ 
      if (!CASHeValidations.validateGSTNNumber(value)) {
          dispatch(setGstNumber(value));
          if(value.length===15){
            const formData = new FormData();
            formData.append("doc_type", docType.upload_GST_Certificate);
            formData.append("gst_certificate_no",value?.split(" ").join(""));
            uploadDocumentHandler(formData, "GST Number saved", GSTSuccessHandlerChange, Images.SuccessNewWithdraw,true);
          }
         }
      if (value.length == 0) {
        dispatch(setGstNumber(''));
      }
   }
  };

  const renderProfileImage = () => {
    return (
      <div className={clasess.profilePicStyles}>
        {fetchDocResponse && fetchDocResponse["profile"] ? (
          <img
            src={fetchDocResponse["profile"]}
            style={{
              width: 100,
              height: 100,
              borderRadius: "50%",
              border: `3px solid ${Colors.whatsappgreen}`,
            }}
            onClick={() => docHandler(docType.profile)}
            alt=""
          />
        ) : (
          <img
            src={Images.profile}
            style={{ width: 100, height: 100 }}
            onClick={() => docHandler(docType.profile)}
            alt=""
          />
        )}

        {fetchDocResponse && fetchDocResponse["profile"] && (
          <IconButton onClick={() => docHandler(docType.profile)}>
            <EditIcon
              style={{
                width: 20,
                height: 20,
                color: "white",
                position: "absolute",
                top: "35%",
              }}
            />
          </IconButton>
        )}

        <span className={clasess.customerName}>
          {" "}
          {restApiState.fetchProfileResponse &&
            restApiState.fetchProfileResponse["name"]
            ? restApiState.fetchProfileResponse["name"]
            : ""}
        </span>
      </div>
    );
  };





  const covertPdfToImg = async (Imagedata) => {
    // let newfile=  new File([Imagedata], `file.pdf`, {lastModified: new Date().getTime(),type:'application/pdf'});
    let newfile = Imagedata;
    var reqfile = await convertPdfToImages(newfile);
    //  new File([Imagedata.data], `pan.png`, {lastModified: new Date().getTime(),type:'image/png'});
    let img = reqfile;
    console.log(img[0]);
    return img[0];
  };

  // Mobile View
//   const renderMobileComponent=()=>{
//   return (
//     <>
//       {renderBottomCameraModal()}
//       <Fragment>
//         {BaseScreen.renderHeader({
//           title: "Document Upload",
//           goBack: goBack,
//           showLogo: true,
//           showTitle: true,
//           showTitleCenter: true,
//           showLogoRight: true,
//           showBackIcon: true,
//           showBackText: true,
//           headerIconImage: Images.karatHeaderLogo,
//           headerRightImgWidth: 38,
//           headerRightImgHeight: 18,
//           reqShadow: true,
//         })}
//         <div
//           className="container-fluid"
//           style={{ marginTop: 110, marginBottom: 110 }}
//         >
//           {/* {openCam && <Camera onCaptureImage={onCaptureImage}/>} */}

//           {/* {renderProfileImage()} */}

//           <div className={clasess.panCard}>
//             <span style={{ fontFamily: "Gilroy-Medium", fontWeight: 500, textAlign: "left", fontSize: "14px"}}>
//               {Strings.documentUpload.pan}
//               <span style={{ color: 'red' }}>*</span>
//             </span>
//             <input
//               placeholder="ABCDE1234G"
//               value={userData.pan_number}
//               style={{
//                 height: 44,
//                 fontFamily: "Gilroy-SemiBold",
//                 fontWeight: 600,
//                 color: "grey",
//                 padding: 8,
//                 marginTop: 10,
//                 border: "1px solid #666666",
//                 borderRadius: 4,
//                 letterSpacing: "1px",
//               }}
//               disabled={true}
//               onChange={panInputChangeHandler}
//             />
//             <p
//               style={{
//                 color: "#333333",
//                 fontFamily: "Gilroy-Medium",
//                 fontWeight: 500,
//                 paddingTop: 8,
//                 paddingBottom: 0,
//                 textAlign: "left",
//                 fontSize: "14px"
//               }}
//             >
//               {Strings.documentUpload.upload_pan}
//             </p>
//             <div className={clasess.panCardUploadCard}>
//               <div
//                 className={clasess.uploadCard}
//                 style={
//                   fetchDocResponse && fetchDocResponse["pan"]
//                     ? {
//                       backgroundImage: `url(${fetchDocResponse?.pan?.imgUrl})`,
//                       height: 89,
//                       width: 131,
//                       backgroundSize: "cover",
//                     }
//                     : {}
//                 }
//               >
//                 <input
//                   id="pan-input"
//                   type="file"
//                   style={{ opacity: "0", zIndex: "99", display: "none" }}
//                   onChange={(e) => {
//                     handleImageChange(e, docType.pan);
//                   }}
//                   multiple={false}
//                   ref={panDocRef}
//                 />
//                 {fetchDocResponse && fetchDocResponse["pan"] ? (
//                   <IconButton onClick={() => docHandler(docType.pan)}>
//                     <EditIcon
//                       style={{ width: 20, height: 20, color: "white" }}
//                     />
//                   </IconButton>
//                 ) : (
//                   <img
//                     src={Images.uploadIcon}
//                     style={{ width: 20, height: 20 }}
//                     onClick={() => docHandler(docType.pan)}
//                     alt=""
//                   />
//                 )}
//               </div>

//               <span
//                 style={{
//                   color: "#666666",
//                   fontFamily: "Gilroy-Regular",
//                   margin: 10,
//                   fontSize: "12px"
//                 }}
//               >
//                 {Strings.documentUpload.pan}
//               </span>
//             </div>
//             <p
//               style={{
//                 fontSize: "12px",
//                 color: "#333",
//                 paddingTop: "6px",
//                 marginBlock: "0px",
//                 textAlign: "left",
//                 fontFamily:"Gilroy-Medium"
//               }}
//             >
//               <img
//                 style={{ width: "18px", height: "18px" }}
//                 src={Images.IconsInfo}
//                 alt=""
//               />
//               jpg, jpeg, png, pdf, heic extensions supported (max size 10MB)
//             </p>
//             {panFileSizeError && (
//               <p
//                 style={{
//                   color: "red",
//                   fontSize: "12px",
//                   fontFamily: "Gilroy-SemiBold",
//                   textAlign: "left",
//                 }}
//               >
//                 Maximum allowed file size is up to 10MB.
//               </p>
//             )}
//           </div>
//           <div className={clasess.panCard}>
//             <p style={{ fontFamily: "Gilroy-Medium", margin: 0, textAlign: "left", fontSize: "14px"}}>
//               {userData.user_type === 1 ? Strings.documentUpload.aadhaar : "Company Registration Certificate or Aadhar"}
//               <span style={{ color: "red" }}>*</span>
//             </p>
//             <input
//               placeholder="1234 1234 1234"
//               style={{
//                 height: 44,
//                 fontFamily: "Gilroy-SemiBold",
//                 fontWeight: 600,
//                 color: "#333",
//                 padding: 16,
//                 marginTop: 10,
//                 border: aadharError ? "2px solid red" : "1px solid #666666",
//                 borderRadius: 4,
//                 outline: "none",
//               }}
//               onChange={aadharNumberChangeHandler}
//               value={globalState.aadharNumber}
//               disabled={restApiState?.fetchProfileResponse?.aadhar_number?true:false}
//             />
//             {aadharError && (
//               <p
//                 style={{
//                   color: "red",
//                   fontSize: 12,
//                   marginTop: 1,
//                   marginBottom: 2,
//                   marginLeft: 2,
//                   fontFamily: "Gilroy-SemiBold",
//                   textAlign: "left",
//                 }}
//               >
//                 Please enter valid aadhar number.
//               </p>
//             )}
//             <p
//               style={{
//                 color: "#333333",
//                 fontFamily: "Gilroy-Medium",
//                 fontWeight: 500,
//                 paddingTop: 8,
//                 paddingBottom: 0,
//                 textAlign: "left",
//                 fontSize: "14px"
//               }}
//             >
//               {Strings.documentUpload.upload_aadhaar}
//             </p>
//             <div className={clasess.radioButtons}>
//               <div
//                 style={{
//                   width: "50%",
//                   display: "flex",
//                   justifyContent: "left",
//                 }}
//               >
//                 <input
//                   type="radio"
//                   id="1"
//                   className="form-check-input  custom-control-inline"
//                   value="SingleDocument"
//                   checked={isChecked && isCheckedElement === "SingleDocument"}
//                   onChange={radioButtonHandler}
//                 />
//                 <label
//                   htmlFor="1"
//                   style={{ fontSize: 14, marginTop: 4, marginLeft: 0, fontFamily: "Gilroy-SemiBold" }}
//                 >
//                   {Strings.documentUpload.singleDoc}
//                 </label>
//               </div>

//               <div
//                 style={{
//                   width: "50%",
//                   display: "flex",
//                   justifyContent: "center",
//                 }}
//               >
//                 <input
//                   type="radio"
//                   id="2"
//                   className="form-check-input  custom-control-inline"
//                   value="MultipleDocument"
//                   checked={isChecked && isCheckedElement === "MultipleDocument"}
//                   onChange={radioButtonHandler}
//                 />
//                 <label
//                   htmlFor="2"
//                   style={{ fontSize: 14, marginTop: 4, marginLeft: 0 , fontFamily: "Gilroy-SemiBold"}}
//                 >
//                   {Strings.documentUpload.mulDoc}
//                 </label>
//               </div>
//             </div>
//             <div style={{ display: "flex", flexDirection: "row" }}>
//               <div className={clasess.aadharStyles}>
//                 {/* <div className={clasess.uploadCard} style={fetchDocResponse && fetchDocResponse['aadhaarFrontSide'] ?{backgroundImage: `url(${fetchDocResponse && fetchDocResponse['aadhaarFrontSide']})`,height:89,width:131,backgroundSize:'cover'}:{}}> */}
//                 {globalState.aadharDoc?.front ? (
//                   <div
//                     className={clasess.uploadCard}
//                     style={{
//                       backgroundImage: `url(${globalState.aadharDoc?.front})`,
//                       height: 89,
//                       width: 120,
//                       backgroundSize: "cover",
//                     }}
//                   >
//                     <input
//                       type="file"
//                       style={{ opacity: "0", zIndex: "99", display: "none" }}
//                       onChange={(e) => {
//                         handleImageChange(e, docType.aadhaarFrontSide);
//                       }}
//                       multiple={false}
//                       ref={docAadharFrontRef2}
//                     />
//                     {globalState.aadharDoc?.front ||
//                       (fetchDocResponse &&
//                         fetchDocResponse["aadhaarFrontSide"]) ? (
//                       <IconButton
//                         onClick={() =>
//                           docHandler(
//                             docType.aadhaarFrontSide,
//                             docAadharFrontRef2
//                           )
//                         }
//                       >
//                         <EditIcon
//                           style={{ width: 20, height: 20, color: "white" }}
//                         />
//                       </IconButton>
//                     ) : (
//                       <img
//                         src={Images.uploadIcon}
//                         style={{ width: 20, height: 20 }}
//                         onClick={() => docHandler(docType.aadhaarFrontSide, docAadharFrontRef2)}
//                         alt=""
//                       />
//                     )}
//                   </div>
//                 ) : (
//                   <div
//                     className={clasess.uploadCard}
//                     style={
//                       fetchDocResponse && fetchDocResponse["aadhaarUnified"]?.imgUrl
//                         ? {
//                           backgroundImage: `url(${fetchDocResponse &&
//                             fetchDocResponse["aadhaarUnified"].imgUrl
//                             })`,
//                           height: 89,
//                           width: 131,
//                           backgroundSize: "cover",
//                         }
//                         : fetchDocResponse &&
//                           fetchDocResponse["aadhaarFrontSide"]
//                           ? {
//                             backgroundImage: `url(${fetchDocResponse &&
//                               fetchDocResponse["aadhaarFrontSide"].imgUrl
//                               })`,
//                             height: 89,
//                             width: 131,
//                             backgroundSize: "cover",
//                           }
//                           : {}
//                     }
//                   >
//                     <input
//                       type="file"
//                       style={{ opacity: "0", zIndex: "99", display: "none" }}
//                       onChange={(e) => {
//                         handleImageChange(e, docType.aadhaarFrontSide);
//                       }}
//                       multiple={false}
//                       ref={docAadharFrontRef}
//                     />
//                     {globalState.aadharDoc?.front ||
//                       (fetchDocResponse &&
//                         fetchDocResponse["aadhaarFrontSide"] ||  fetchDocResponse["aadhaarUnified"] &&
//                         fetchDocResponse["aadhaarFrontSide"]?.imgUrl || fetchDocResponse["aadhaarUnified"]?.imgUrl ) ? (
//                       <EditIcon
//                         style={{ width: 20, height: 20, color: "white" }}
//                         onClick={() => docHandler(docType.aadhaarFrontSide,docAadharFrontRef)}
//                       />
//                     ) : (
//                       <img
//                         src={Images.uploadIcon}
//                         style={{ width: 20, height: 20 }}
//                         onClick={() => docHandler(docType.aadhaarFrontSide,docAadharFrontRef)}
//                         alt=""
//                       />
//                     )}
//                   </div>
//                 )}

//                 <span
//                   style={{
//                     color: "#666666",
//                     fontFamily: "Gilroy-Regular",
//                     margin: 10,
//                     fontSize: "12px"
//                   }}
//                 >
//                   {Strings.documentUpload?.front}
//                 </span>
//               </div>
//               {isSelected && (
//                 <div className={clasess.aadharStyles}>
//                   {globalState.aadharDoc?.back ? (
//                     <div
//                       className={clasess.uploadCard}
//                       style={{
//                         backgroundImage: `url(${globalState.aadharDoc.back})`,
//                         height: 89,
//                         width: 131,
//                         backgroundSize: "cover",
//                       }}
//                     >
//                       <input
//                         type="file"
//                         style={{ opacity: "0", zIndex: "99", display: "none" }}
//                         onChange={(e) => {
//                           handleImageChange(e, docType.aadhaarBackSide);
//                         }}
//                         multiple={false}
//                         ref={docAadharBackRef2}
//                       />
//                       {globalState.aadharDoc?.back ? (
//                         <IconButton
//                           onClick={() =>
//                             docHandler(
//                               docType.aadhaarBackSide,
//                               docAadharBackRef2
//                             )
//                           }
//                         >
//                           <EditIcon
//                             style={{ width: 20, height: 20, color: "white" }}
//                           />
//                         </IconButton>
//                       ) : (
//                         <img
//                           src={Images.uploadIcon}
//                           style={{ width: 20, height: 20 }}
//                           onClick={() => docHandler(docType.aadhaarBackSide,docAadharBackRef2)}
//                           alt=""
//                         />
//                       )}
//                     </div>
//                   ) : (
//                     <div
//                       className={clasess.uploadCard}
//                       style={
//                         fetchDocResponse && fetchDocResponse["aadhaarBackSide"]
//                           ? {
//                             backgroundImage: `url(${fetchDocResponse &&
//                               fetchDocResponse["aadhaarBackSide"].imgUrl
//                               })`,
//                             height: 89,
//                             width: 131,
//                             backgroundSize: "cover",
//                           }
//                           : {}
//                       }
//                     >
//                       <input
//                         type="file"
//                         style={{ opacity: "0", zIndex: "99", display: "none" }}
//                         onChange={(e) => {
//                           handleImageChange(e, docType.aadhaarBackSide);
//                         }}
//                         multiple={false}
//                         ref={docAadharBackRef}
//                       />
//                       {globalState.aadharDoc?.back ||
//                         (fetchDocResponse &&
//                           fetchDocResponse["aadhaarBackSide"]) ? (
//                         <EditIcon
//                           style={{ width: 20, height: 20, color: "white" }}
//                           onClick={() => docHandler(docType.aadhaarBackSide,docAadharBackRef)}
//                         />
//                       ) : (
//                         <img
//                           src={Images.uploadIcon}
//                           style={{ width: 20, height: 20 }}
//                           onClick={() => docHandler(docType.aadhaarBackSide,docAadharBackRef)}
//                           alt=""
//                         />
//                       )}
//                     </div>
//                   )}

//                   <span
//                     style={{
//                       color: "#666666",
//                       fontFamily: "Gilroy-Regular",
//                       margin: 10,
//                       fontSize: "12px"
//                     }}
//                   >
//                     {Strings.documentUpload.back}
//                   </span>
//                 </div>
//               )}
//             </div>
//             <p
//               style={{
//                 fontSize: "12px",
//                 color: "#333",
//                 marginTop: "6px",
//                 marginBlock: "0px",
//                 textAlign: "left",
//                 fontFamily:"Gilroy-Medium"
//               }}
//             >
//               <img
//                 style={{ width: "18px", height: "18px" }}
//                 src={Images.IconsInfo}
//                 alt=""
//               />
//               jpg, jpeg, png, pdf, heic extensions supported (max size 10MB)
//             </p>
//             {addharFileSizeError && (
//               <p
//                 style={{
//                   color: "red",
//                   fontSize: "12px",
//                   fontFamily: "Gilroy-SemiBold",
//                   textAlign: "left",
//                 }}
//               >
//                 Maximum allowed file size is up to 10MB.
//               </p>
//             )}
//           </div>
//           <div className={clasess.panCard}>
//             <span style={{ fontFamily: "Gilroy-Medium", fontWeight: 500, textAlign: "left", fontSize: "14px"}}>
//               {Strings.documentUpload.GSTCertificate}
              
//             </span>
//             <input
//               id="GSTCertificate"
//               placeholder="22AAAAA0000A1Z5"
//               value={globalState?.gstNumber}
//               style={{
//                 height: 44,
//                 fontFamily: "Gilroy-SemiBold",
//                 fontWeight: 600,
//                 color: "#333",
//                 padding: 8,
//                 marginTop: 10,
//                 border: "1px solid #666666",
//                 borderRadius: 4,
//                 letterSpacing: "1px",
//               }}
//               // disabled={true}

//               onChange={gstInputChangeHandler}
//               name="GSTCertificate"
//             />
//             {gstError &&    <p
//                 style={{
//                   color: "red",
//                   fontSize: 12,
//                   marginTop: 1,
//                   marginBottom: 2,
//                   marginLeft: 2,
//                   fontFamily: "Gilroy-SemiBold",
//                   textAlign: "left",
//                 }}
//               >
//                 Please enter valid GST number.</p>}
//             <p
//               style={{
//                 color: "#333333",
//                 fontFamily: "Gilroy-Medium",
//                 fontWeight: 500,
//                 paddingTop: 8,
//                 paddingBottom: 0,
//                 textAlign: "left",
//                 fontSize: "14px"
//               }}
//             >
//               {Strings.documentUpload.upload_GST_Certificate}
//             </p>
//             <div className={clasess.panCardUploadCard}>
//               <div
//                 className={clasess.uploadCard}
//                 style={
//                   fetchDocResponse && fetchDocResponse["upload_GST_Certificate"]
//                     ? {
//                       backgroundImage: `url(${fetchDocResponse["upload_GST_Certificate"].imgUrl})`,
//                       height: 89,
//                       width: 131,
//                       backgroundSize: "cover",
//                     }
//                     : {}
//                 }
//               >
//                 <input
//                   id="GSTCertificate-input"
//                   type="file"
//                   style={{ opacity: "0", zIndex: "99", display: "none" }}
//                   onChange={(e) => {
//                     handleImageChange(e, docType.upload_GST_Certificate);
//                   }}
//                   multiple={false}
//                   ref={gstDocRef}
//                 />
//                 {fetchDocResponse && fetchDocResponse["upload_GST_Certificate"] ? (
//                   <IconButton onClick={() => docHandler(docType.upload_GST_Certificate)}>
//                     <EditIcon
//                       style={{ width: 20, height: 20, color: "white" }}
//                     />
//                   </IconButton>
//                 ) : (
//                   <img
//                     src={Images.uploadIcon}
//                     style={{ width: 20, height: 20 }}
//                     onClick={() => docHandler(docType.upload_GST_Certificate)}
//                     alt=""
//                   />
//                 )}
//               </div>

//               <span
//                 style={{
//                   color: "#666666",
//                   fontFamily: "Gilroy-Regular",
//                   margin: 10,
//                   fontSize: "12px" 
//                 }}
//               >
//                 {Strings.documentUpload.GSTCertificate}
//               </span>
//             </div>
//             <p
//               style={{
//                 fontSize: "12px",
//                 color: "#333",
//                 paddingTop: "6px",
//                 marginBlock: "0px",
//                 textAlign: "left",
//                 fontFamily:"Gilroy-Medium"
//               }}
//             >
//               <img
//                 style={{ width: "18px", height: "18px" }}
//                 src={Images.IconsInfo}
//                 alt=""
//               />
//               jpg, jpeg, png, pdf, heic extensions supported (max size 10MB)
//             </p>
//             {panFileSizeError && (
//               <p
//                 style={{
//                   color: "red",
//                   fontSize: "12px",
//                   fontFamily: "Gilroy-SemiBold",
//                   textAlign: "left",
//                 }}
//               >
//                 Maximum allowed file size is up to 10MB.
//               </p>
//             )}
//           </div>
//         </div>
     
//       </Fragment>
      
//       <div style={{ marginTop: "110px" }}>
//           {BaseScreen.renderFooter({
//             customLeftBtnStyle: {
//               border: "1px solid #2D2F51",
//               width: window.innerWidth <= 350 ? 130 : "42%",
//               height: "44px",
//               backgroundColor: "white",
//               color: "#2D2F51",
//               borderRadius: 4,
//               marginRight: 10,
//             },
//             showLeftBtn: true,
//             leftBtnHeight: 44,
//             leftBtnWidth: "25%",
//             leftBtnBorderColor: "1px solid #2D2F51",
//             leftBtnBgColor: "white",
//             showRightBtn: true,
//             rightBtnHeight: 44,
//             rightBtnWidth: window.innerWidth <= 350 ? 130 : "42%",
//             ShowLeftBtnText: true,
//             leftBtnText: restApiState?.fetchProfileResponse?.is_esigned===true && "Go Back" || "Skip",
//             rightBtnBorder: "0px",
//             rightBtnBgColor: fileNotUpload ? " #CCCCCC" : Colors.secondaryprimary,
//             textColor: "#FFFFFF",
//             title: restApiState?.fetchProfileResponse?.is_esigned===true && "Proceed" || Strings.documentUpload.submit,
//             showRightBtnArrow: false,
//             ShowLeftBtnArrow: false,
//             onLeftBtnClicked: skipButton,
//             onRightBtnClicked: rightBtnHandler ,
//             disabled: fileNotUpload,
//             leftBtnTextFontsize: "17px",
//             rightBtnFontSize: "17px",
//             rightBtnTextFontFamily: "Gilroy-SemiBold",
//             leftBtnTxtFontFamily: "Gilroy-SemiBold"
    
//                  })}
//         </div>
//     </>
//   )};return renderMobileComponent();
// }

{/* CODE for Desktop view */}
  const renderDesktopComponent=()=>{
    return (
      <>
        {renderBottomCameraModal()}
        <Fragment>
          <div style={{ display: 'flex', height: '100vh' }}>
        {props?.isFromIfaUpdateDocument||location?.state?.isFromPortel? <></>:  
          <div style={{ width: '30%', backgroundImage: `url(${Images.backgroundOnboarding})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ position: 'fixed', top: 10, left: 10, right: 100, width: '10%' }} src={Images.karatHeaderLogoForLogin} alt="Karat Header Logo" />         
          </div>
           }
           <div style={{ width:window.innerWidth - 500, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding:10 }}>
            <div style={{ width: '540px', display: 'flex', flexDirection: 'column', height:'100%' }}>
            <div style={{flex: '0 0 auto'}}>
           {!(location?.state?.navigateTo) && <LogoutSupportComponent /> }
           <div style={{display:'flex',justifyContent:'space-between',alignItems:'center', margin: '0 10px'}}>

            <p style={{margin:0, fontFamily: 'Gilroy-Bold', fontSize: '28px', fontWeight: 700, lineHeight: '42px', letterSpacing: '0.014em', textAlign:'initial', backgroundColor: '#fff' }} title="Upload Documents">Upload Documents</p>
            <div>
                                {renderStepper(globalState,[],true,190)}
                                </div>
                </div>
            </div> 
            <div style={{ flex: '1 1 auto', position: 'relative', overflowY: 'auto', scrollbarWidth: 'none'}}>
            {/* {openCam && <Camera onCaptureImage={onCaptureImage}/>} */}
  
            {/* {renderProfileImage()} */}
  
            <div className={clasess.panCard}>
              <span style={{ fontFamily: "Gilroy-Medium", fontWeight: 500, textAlign: "left", fontSize: "14px"}}>
                {Strings.documentUpload.pan}
                <span style={{ color: 'red' }}>*</span>
              </span>
              <input
                placeholder="ABCDE1234G"
                value={userData.pan_number}
                style={{
                  height: 44,
                  fontFamily: "Gilroy-SemiBold",
                  fontWeight: 600,
                  color: "grey",
                  padding: 8,
                  marginTop: 10,
                  border: "1px solid #666666",
                  borderRadius: 4,
                  letterSpacing: "1px",
                }}
                disabled={true}
                onChange={panInputChangeHandler}
              />
              <p
                style={{
                  color: "#333333",
                  fontFamily: "Gilroy-Medium",
                  fontWeight: 500,
                  paddingTop: 8,
                  paddingBottom: 0,
                  textAlign: "left",
                  fontSize: "14px"
                }}
              >
                {Strings.documentUpload.upload_pan}
              </p>
              <div className={clasess.panCardUploadCard}>
                <div
                  className={clasess.uploadCard}
                  style={
                    fetchDocResponse && fetchDocResponse["pan"]
                      ? {
                        backgroundImage: `url(${fetchDocResponse?.pan?.imgUrl})`,
                        height: 89,
                        width: 131,
                        backgroundSize: "cover",
                      }
                      : {}
                  }
                >
                  <input
                    id="pan-input"
                    type="file"
                    style={{ opacity: "0", zIndex: "99", display: "none" }}
                    onChange={(e) => {
                      handleImageChange(e, docType.pan);
                    }}
                    multiple={false}
                    ref={panDocRef}
                  />
                  {fetchDocResponse && fetchDocResponse["pan"] ? (
                    <IconButton onClick={() => docHandler(docType.pan)}>
                      <EditIcon
                        style={{ width: 20, height: 20, color: "white" }}
                      />
                    </IconButton>
                  ) : (
                    <img
                      src={Images.uploadCommonInfosvg}
                      style={{width:580,height:127}}
                      onClick={() => docHandler(docType.pan)}
                      alt=""
                    />
                  )}
                </div>
  
                {/* <span
                  style={{
                    color: "#666666",
                    fontFamily: "Gilroy-Regular",
                    margin: 10,
                    fontSize: "12px"
                  }}
                >
                  {Strings.documentUpload.pan}
                </span> */}
              </div>
              <p
                style={{
                  fontSize: "12px",
                  color: "#333",
                  paddingTop: "6px",
                  marginBlock: "0px",
                  textAlign: "left",
                  fontFamily:"Gilroy-Medium"
                }}
              >
                <img
                  style={{ width: "18px", height: "18px" }}
                  src={Images.IconsInfo}
                  alt=""
                />
                jpg, jpeg, png, pdf, heic extensions supported (max size 10MB)
              </p>
              {panFileSizeError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontFamily: "Gilroy-SemiBold",
                    textAlign: "left",
                  }}
                >
                  Maximum allowed file size is up to 10MB.
                </p>
              )}
            </div>
            <div className={clasess.panCard}>
              <p style={{ fontFamily: "Gilroy-Medium", margin: 0, textAlign: "left", fontSize: "14px"}}>
                {userData.user_type === 1 ? Strings.documentUpload.aadhaar : "Company Registration Certificate or Aadhar"}
                <span style={{ color: "red" }}>*</span>
              </p>
              <input
                placeholder="1234 1234 1234"
                style={{
                  height: 44,
                  fontFamily: "Gilroy-SemiBold",
                  fontWeight: 600,
                  color: "#333",
                  padding: 16,
                  marginTop: 10,
                  border: aadharError ? "2px solid red" : "1px solid #666666",
                  borderRadius: 4,
                  outline: "none",
                }}
                onChange={aadharNumberChangeHandler}
                value={globalState.aadharNumber}
                disabled={restApiState?.fetchProfileResponse?.aadhar_number?true:false}
              />
              {aadharError && (
                <p
                  style={{
                    color: "red",
                    fontSize: 12,
                    marginTop: 1,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontFamily: "Gilroy-SemiBold",
                    textAlign: "left",
                  }}
                >
                  Please enter valid aadhar number.
                </p>
              )}
              <p
                style={{
                  color: "#333333",
                  fontFamily: "Gilroy-Medium",
                  fontWeight: 500,
                  paddingTop: 8,
                  paddingBottom: 0,
                  textAlign: "left",
                  fontSize: "14px"
                }}
              >
                {Strings.documentUpload.upload_aadhaar}
              </p>
              <div className={clasess.radioButtons}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <input
                    type="radio"
                    id="1"
                    className="form-check-input  custom-control-inline"
                    value="SingleDocument"
                    checked={isChecked && isCheckedElement === "SingleDocument"}
                    onChange={radioButtonHandler}
                  />
                  <label
                    htmlFor="1"
                    style={{ fontSize: 14, marginTop: 4, marginLeft: 0, fontFamily: "Gilroy-SemiBold" }}
                  >
                    {Strings.documentUpload.singleDoc}
                  </label>
                </div>
  
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="radio"
                    id="2"
                    className="form-check-input  custom-control-inline"
                    value="MultipleDocument"
                    checked={isChecked && isCheckedElement === "MultipleDocument"}
                    onChange={radioButtonHandler}
                  />
                  <label
                    htmlFor="2"
                    style={{ fontSize: 14, marginTop: 4, marginLeft: 0 , fontFamily: "Gilroy-SemiBold"}}
                  >
                    {Strings.documentUpload.mulDoc}
                  </label>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div className={clasess.aadharStyles}>
                  {/* <div className={clasess.uploadCard} style={fetchDocResponse && fetchDocResponse['aadhaarFrontSide'] ?{backgroundImage: `url(${fetchDocResponse && fetchDocResponse['aadhaarFrontSide']})`,height:89,width:131,backgroundSize:'cover'}:{}}> */}
                  {globalState.aadharDoc?.front ? (
                    <div
                      className={clasess.uploadCard}
                      style={{
                        backgroundImage: `url(${globalState.aadharDoc?.front})`,
                        height: 89,
                        width: 120,
                        backgroundSize: "cover",
                      }}
                    >
                      <input
                        type="file"
                        style={{ opacity: "0", zIndex: "99", display: "none" }}
                        onChange={(e) => {
                          handleImageChange(e, docType.aadhaarFrontSide);
                        }}
                        multiple={false}
                        ref={docAadharFrontRef2}
                      />
                      {globalState.aadharDoc?.front ||
                        (fetchDocResponse &&
                          fetchDocResponse["aadhaarFrontSide"]) ? (
                        <IconButton
                          onClick={() =>
                            docHandler(
                              docType.aadhaarFrontSide,
                              docAadharFrontRef2
                            )
                          }
                        >
                          <EditIcon
                            style={{ width: 20, height: 20, color: "white" }}
                          />
                        </IconButton>
                      ) : (
                        <img
                          src={Images.uploadIcon}
                          style={{ width: 20, height: 20 }}
                          onClick={() => docHandler(docType.aadhaarFrontSide, docAadharFrontRef2)}
                          alt=""
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={clasess.uploadCard}
                      style={
                        fetchDocResponse && fetchDocResponse["aadhaarUnified"]?.imgUrl
                          ? {
                            backgroundImage: `url(${fetchDocResponse &&
                              fetchDocResponse["aadhaarUnified"].imgUrl
                              })`,
                            height: 89,
                            width: 131,
                            backgroundSize: "cover",
                          }
                          : fetchDocResponse &&
                            fetchDocResponse["aadhaarFrontSide"]
                            ? {
                              backgroundImage: `url(${fetchDocResponse &&
                                fetchDocResponse["aadhaarFrontSide"].imgUrl
                                })`,
                              height: 89,
                              width: 131,
                              backgroundSize: "cover",
                            }
                            : {}
                      }
                    >
                      <input
                        type="file"
                        style={{ opacity: "0", zIndex: "99", display: "none" }}
                        onChange={(e) => {
                          handleImageChange(e, docType.aadhaarFrontSide);
                        }}
                        multiple={false}
                        ref={docAadharFrontRef}
                      />
                      {globalState.aadharDoc?.front ||
                        (fetchDocResponse &&
                          fetchDocResponse["aadhaarFrontSide"] ||  fetchDocResponse["aadhaarUnified"] &&
                          fetchDocResponse["aadhaarFrontSide"]?.imgUrl || fetchDocResponse["aadhaarUnified"]?.imgUrl ) ? (
                        <EditIcon
                          style={{ width: 20, height: 20, color: "white" }}
                          onClick={() => docHandler(docType.aadhaarFrontSide,docAadharFrontRef)}
                        />
                      ) : (
                        <img
                          src={Images.uploadCommonInfosvg}
                          // style={{ width: 20, height: 20 }}
                          style={{width:isSelected?200:580,height:isSelected?100:127}}
                          onClick={() => docHandler(docType.aadhaarFrontSide,docAadharFrontRef)}
                          alt=""
                        />
                      )}
                    </div>
                  )}
  
                  {/* <span
                    style={{
                      color: "#666666",
                      fontFamily: "Gilroy-Regular",
                      margin: 10,
                      fontSize: "12px"
                    }}
                  >
                    {Strings.documentUpload?.front}
                  </span> */}
                </div>
                {isSelected && (
                  <div className={clasess.aadharStyles}>
                    {globalState.aadharDoc?.back ? (
                      <div
                        className={clasess.uploadCard}
                        style={{
                          backgroundImage: `url(${globalState.aadharDoc.back})`,
                          height: 89,
                          width: 131,
                          backgroundSize: "cover",
                        }}
                      >
                        <input
                          type="file"
                          style={{ opacity: "0", zIndex: "99", display: "none" }}
                          onChange={(e) => {
                            handleImageChange(e, docType.aadhaarBackSide);
                          }}
                          multiple={false}
                          ref={docAadharBackRef2}
                        />
                        {globalState.aadharDoc?.back ? (
                          <IconButton
                            onClick={() =>
                              docHandler(
                                docType.aadhaarBackSide,
                                docAadharBackRef2
                              )
                            }
                          >
                            <EditIcon
                              style={{ width: 20, height: 20, color: "white" }}
                            />
                          </IconButton>
                        ) : (
                          <img
                            src={Images.uploadIcon}
                            style={{ width: 20, height: 20 }}
                            onClick={() => docHandler(docType.aadhaarBackSide,docAadharBackRef2)}
                            alt=""
                          />
                        )}
                      </div>
                    ) : (
                      <div
                        className={clasess.uploadCard}
                        style={
                          fetchDocResponse && fetchDocResponse["aadhaarBackSide"]
                            ? {
                              backgroundImage: `url(${fetchDocResponse &&
                                fetchDocResponse["aadhaarBackSide"].imgUrl
                                })`,
                              height: 89,
                              width: 131,
                              backgroundSize: "cover",
                            }
                            : {}
                        }
                      >
                        <input
                          type="file"
                          style={{ opacity: "0", zIndex: "99", display: "none" }}
                          onChange={(e) => {
                            handleImageChange(e, docType.aadhaarBackSide);
                          }}
                          multiple={false}
                          ref={docAadharBackRef}
                        />
                        {globalState.aadharDoc?.back ||
                          (fetchDocResponse &&
                            fetchDocResponse["aadhaarBackSide"]) ? (
                          <EditIcon
                            style={{ width: 20, height: 20, color: "white" }}
                            onClick={() => docHandler(docType.aadhaarBackSide,docAadharBackRef)}
                          />
                        ) : (
                          <img
                            // src={Images.uploadIcon}
                            // style={{ width: 20, height: 20 }}
                            src={Images.uploadCommonInfosvg}
                            style={{width:220,height:100}}
                            onClick={() => docHandler(docType.aadhaarBackSide,docAadharBackRef)}
                            alt=""
                          />
                        )}
                      </div>
                    )}
  
                    {/* <span
                      style={{
                        color: "#666666",
                        fontFamily: "Gilroy-Regular",
                        margin: 10,
                        fontSize: "12px"
                      }}
                    >
                      {Strings.documentUpload.back}
                    </span> */}
                  </div>
                )}
              </div>
              <p
                style={{
                  fontSize: "12px",
                  color: "#333",
                  marginTop: "6px",
                  marginBlock: "0px",
                  textAlign: "left",
                  fontFamily:"Gilroy-Medium"
                }}
              >
                <img
                  style={{ width: "18px", height: "18px" }}
                  src={Images.IconsInfo}
                  alt=""
                />
                jpg, jpeg, png, pdf, heic extensions supported (max size 10MB)
              </p>
              {addharFileSizeError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontFamily: "Gilroy-SemiBold",
                    textAlign: "left",
                  }}
                >
                  Maximum allowed file size is up to 10MB.
                </p>
              )}
            </div>
            <div className={clasess.panCard}>
              <span style={{ fontFamily: "Gilroy-Medium", fontWeight: 500, textAlign: "left", fontSize: "14px"}}>
                {Strings.documentUpload.GSTCertificate}
                
              </span>
              <input
                id="GSTCertificate"
                placeholder="22AAAAA0000A1Z5"
                value={globalState?.gstNumber}
                style={{
                  height: 44,
                  fontFamily: "Gilroy-SemiBold",
                  fontWeight: 600,
                  color: "#333",
                  padding: 8,
                  marginTop: 10,
                  border: "1px solid #666666",
                  borderRadius: 4,
                  letterSpacing: "1px",
                }}
                // disabled={true}
  
                onChange={gstInputChangeHandler}
                name="GSTCertificate"
              />
              {gstError &&    <p
                  style={{
                    color: "red",
                    fontSize: 12,
                    marginTop: 1,
                    marginBottom: 2,
                    marginLeft: 2,
                    fontFamily: "Gilroy-SemiBold",
                    textAlign: "left",
                  }}
                >
                  Please enter valid GST number.</p>}
              <p
                style={{
                  color: "#333333",
                  fontFamily: "Gilroy-Medium",
                  fontWeight: 500,
                  paddingTop: 8,
                  paddingBottom: 0,
                  textAlign: "left",
                  fontSize: "14px"
                }}
              >
                {Strings.documentUpload.upload_GST_Certificate}
              </p>
              <div className={clasess.panCardUploadCard}>
                <div
                  className={clasess.uploadCard}
                  style={
                    fetchDocResponse && fetchDocResponse["upload_GST_Certificate"]
                      ? {
                        backgroundImage: `url(${fetchDocResponse["upload_GST_Certificate"].imgUrl})`,
                        height: 89,
                        width: 131,
                        backgroundSize: "cover",
                      }
                      : {}
                  }
                >
                  <input
                    id="GSTCertificate-input"
                    type="file"
                    style={{ opacity: "0", zIndex: "99", display: "none" }}
                    onChange={(e) => {
                      handleImageChange(e, docType.upload_GST_Certificate);
                    }}
                    multiple={false}
                    ref={gstDocRef}
                  />
                  {fetchDocResponse && fetchDocResponse["upload_GST_Certificate"] ? (
                    <IconButton onClick={() => docHandler(docType.upload_GST_Certificate)}>
                      <EditIcon
                        style={{ width: 20, height: 20, color: "white" }}
                      />
                    </IconButton>
                  ) : (
                    <img
                      src={Images.uploadCommonInfosvg}
                      style={{width:580,height:127}}
                      onClick={() => docHandler(docType.upload_GST_Certificate)}
                      alt=""
                    />
                  )}
                </div>
  
                {/* <span
                  style={{
                    color: "#666666",
                    fontFamily: "Gilroy-Regular",
                    margin: 10,
                    fontSize: "12px" 
                  }}
                >
                  {Strings.documentUpload.GSTCertificate}
                </span> */}
              </div>
              <p
                style={{
                  fontSize: "12px",
                  color: "#333",
                  paddingTop: "6px",
                  marginBlock: "0px",
                  textAlign: "left",
                  fontFamily:"Gilroy-Medium"
                }}
              >
                <img
                  style={{ width: "18px", height: "18px" }}
                  src={Images.IconsInfo}
                  alt=""
                />
                jpg, jpeg, png, pdf, heic extensions supported (max size 10MB)
              </p>
              {panFileSizeError && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontFamily: "Gilroy-SemiBold",
                    textAlign: "left",
                  }}
                >
                  Maximum allowed file size is up to 10MB.
                </p>
              )}
            </div>
            <div style={{  marginTop: 20, marginBottom: 20, justifyContent: "space-between" }}>
              <button style={{border: "1px solid #2D2F51",width: window.innerWidth <= 350 ? 130 : "42%",height: "44px",backgroundColor: "white",color: "#2D2F51",borderRadius: 4,marginRight: 10,}}onClick={skipButton}>Skip</button>
              <button style={{height: '44px',width: window.innerWidth <= 350 ? 130 : "42%",backgroundColor: Colors.secondaryprimary,color: '#FFFFFF',fontFamily: 'Gilroy-SemiBold',fontSize: '16px',cursor: 'pointer',marginLeft: '5px', borderRadius: 4}}onClick={rightBtnHandler}>Submit</button>
            </div>
          </div>
          </div>
          </div>
          </div>
        </Fragment>
      </>
    );
  };
  return renderDesktopComponent()}


export default KycDocumentUpload;
