import React from 'react'
import { Field, SubmissionError } from 'redux-form'
import { setFormError } from '../Store/Actions/formAction'
import { setBankNameValue } from '../Store/Actions/GlobalActions'

// import Strings from '../../Constants/Strings'
import TextField from './TextField'
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


const showError = async (type, msg) => {
  throw new SubmissionError({
    EMAILID: 'server response user exists',
    _error: ''
  })
}

function submit(values, props) {
  return sleep(1).then(() => {
    props.onSave(values)

  }).catch(err => {
    console.log('form error in from ', err)
  })
}

const getError = (touched, error, warning) => {
  // console.log( "onerror",touched,error,warning)
  if (touched) {
    return error ? error : ''
  }
}


const renderInput = ({ input, inputProps, label, defaultvalue, type, meta: { touched, error, warning }, fieldName, placeholder, inputLimit, formType, customWidth, rightView, showBottomBorder, fieldDisabled, onChange, selectivedata, isRequired = false, inputRef, inputList ,onBlurHandler,onFocusHandler,defaultFun,inputValue,isFromPortal,radioButtonElements,customRightData,customLeftData,onchangeHandler}) => {
  return (
    <div >
      <TextField
        {...input}
        {...inputProps}
        inputValue={inputValue}
        input={input}
        inputProps={inputProps}
        errorText={getError(touched, error, warning)}
        touched={touched}
        error={error}
        warning={warning}
        inputLimit={inputLimit}
        placeholder={placeholder}
        type={type}
        fieldName={fieldName}
        label={label}
        formType={formType}
        customWidth={customWidth}
        rightView={rightView}
        showBottomBorder={showBottomBorder}
        defaultvalue={defaultvalue}
        disabled={fieldDisabled}
        selectivedata={selectivedata}
        isRequired={isRequired}
        inputRef={inputRef}
        inputList={inputList}
        value={123}
        defaultValue={1234}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        radioButtonElements={radioButtonElements}
        customRightData={customRightData}
        customLeftData={customLeftData}
        onchangeHandler={onchangeHandler}
        isFromPortal={isFromPortal}
      />
    </div>
  )
}


const upper = value => value && value.toUpperCase()

export const renderFields = (inputFields, props, dispatch, formState) => {
  console.log('inputFields', inputFields)
  if (inputFields && inputFields.length > 0) {

    return inputFields.map((ele, idx) => {

      return <Field
        defaultFun={ele.defaultFun}
        inputValue={ele.inputValue}
        key={idx}
        name={ele.fieldName}
        type={ele.type}
        component={renderInput}
        label={ele.label}
        fieldName={ele.fieldName}
        placeholder={ele.placeholder}
        inputLimit={ele.inputLimit}
        formType={ele.formType}
        customWidth={ele.customWidth}
        onChange={(e) => {

          if(ele.fieldName === "BANK_NAME_ID" ||ele.fieldName==="Bank_Name" && e ){
            console.log(e)
            dispatch(setBankNameValue(e))
            
          }
          if(ele.fieldName === "IFSC_CODE_ID" && e.target.value && e.target.value.length === 11){
                let req={
                  ifsc_code:e.target.value
              }
            ele.defaultFun(req)
          }else if(ele.fieldName === "IFSC_CODE_ID"){
            ele.defaultFun(null,e.target.value)
          }
          if (!formState?.formError[ele.fieldName]?.isvalid) {
            dispatch(setFormError({ formFieldName: ele.fieldName, isvalid: true, msg: '' }))
          }
          dispatch({
            type: 'form',
            payload: {
              formType: ele.formType,
              value: '123',
              name: ele.fieldName

            }
          })
        }}
        value="123"
        defaultValue={'1234'}

        rightView={ele.rightView}
        showBottomBorder={ele.showBottomBorder}
        defaultvalue={ele.defaultvalue}
        inputRef={ele.inputRef}
        defaultprops={props}
        disabled={false}
        fieldDisabled={ele.disabled}
        selectivedata={ele.selectivedata}
        isRequired={ele.isRequired}
        inputList={ele.inputList}
        onBlurHandler={ele.onBlur}
        onFocusHandler={ele.onFocus}
        normalize={(ele.fieldName==="PAN_ID" ||ele.fieldName === "IFSC_CODE_ID" )?upper:false}
        radioButtonElements={ele.radioButtonElements}
        customRightData={ele.customRightData}
        customLeftData={ele.customLeftData}
        onchangeHandler={ele.onchangeHandler}
        isFromPortal={ele.isFromPortal}

      />
    })
  }
}