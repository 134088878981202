import axios from "axios";
import BuildProperties from "../../BuildProperties";
import ApiConstant from "../../Constants/Originals/ApiConstant";
import Strings from "../../Constants/Strings";
import CASHeUtils from "../../Utils/CASHeUtils";
import ReduxValues from "../ReduxValues";
import { isLoading, isPOPOpen } from "./GlobalActions";
import { isActiveInternet } from "../../Utils/NetworkCheck";
import { useNavigate } from "react-router-dom";
import Images from "../../Images/Images";
import {convertPdfToImages} from '../../Pages/Onboarding/KycDocumentUpload'

let actionsStrings = ReduxValues.reduxActions;

export const APIMethod = {
  GET: "GET",
  POST: "POST",
};

const retryApiCall = (
  methodType,
  url,
  pathParams,
  isNotCASHeURL,
  request,
  token,
  isEncypted,
  dispatch,
  params
) => {
  FetchData({
    methodType: methodType,
    url: url,
    pathParams: pathParams,
    isNotCASHeURL: isNotCASHeURL,
    request: request,
    token: token,
    isEncypted: isEncypted,
    dispatch: dispatch,
    params: params,
    isRetry: true,
  });
};

const reqestPayload = "";
const resposnePayload = "";
console.log(reqestPayload, resposnePayload);

const FetchData = async (props) => {
  const {
    methodType,
    url,
    pathParams,
    isNotCASHeURL,
    request,
    token,
    isEncypted,
    dispatch,
    params,
    isRetry = false,
    isFile = false,
    customHeader,
    customBaseUrl,
    isHEXEnc,
    isReqToken,
    stopLoading = true,
    customTimeout,
  } = props;
  let baseURL2 = customBaseUrl ? customBaseUrl : BuildProperties.getBaseURL();
  let authData = JSON.parse(localStorage.getItem("authData"));
  console.log("baseURL2", baseURL2);
  console.log(url, "currrent api url test");
  if (!isActiveInternet()) {
    console.log("plz check internet connction");
    throw Error("plz check internet connction");
  }

  let reqHeaders = {
    "Content-Type": "application/json",
  };
  if (customHeader) {
    reqHeaders = customHeader;
  }
  if (token) {
    reqHeaders.Authorization = `Bearer ${token}`;
  } else if (isReqToken === true && authData) {
    reqHeaders.Authorization = `Bearer ${authData.entity.access_token}`;
    // reqHeaders.Authorization = `Bearer ${"K89Iwx5xtaAJf0HurASaxSKv6kwYJr"}`;
    
  }

  let requireRequest = request;
  if (isEncypted && !isRetry) {
    if (request) {
      requireRequest = {
        iv: "36763979244226452948404d63516654",
        ciphertext: CASHeUtils.getEncryptedText(JSON.stringify(request)),
      };
      // requireRequest = CASHeUtils.getEncryptedText(JSON.stringify(request))
    }
  }

  if (params) {
    url += (url.indexOf("?") === -1 ? "?" : "&") + params;
  }

  let completeURL = "";

  if (isNotCASHeURL) {
    completeURL = url;
  } else {
    completeURL = `${baseURL2}${url}${pathParams ? pathParams : ""}`;
  }

  console.log("baseURL2+completeURL ", completeURL);

  if (APIMethod.POST == methodType) {
    if (isFile) {
      try {
        const response = await axios.post(completeURL, requireRequest, {
          headers: reqHeaders,
          responseType: "blob",
        });
        console.log(response);
        let imgUrl = URL.createObjectURL(response.data);
        // if (response && response.data.type === "application/pdf") {

        // let imgUrlData = await getUploadImages(response.data)
        // console.log(imgUrlData);
        // return { imgUrl: imgUrlData, data: response.data }

        // } else {
        return { imgUrl, data: response.data };
        // }

        // return imgUrl
      } catch (error) {
        return null;
      }
    } else {
      try {
        // CASHeLogglyManager.log(LogLevel.request_payload,request)
        const res = await axios.post(completeURL, requireRequest, {
          headers: reqHeaders,
          timeout: customTimeout ? customTimeout : 1000 * 120,
        });
        console.log("Fetch POST res:", res);
        if (res) {
          if (res.data.status >= 500) {
            let resReqData = {
              methodType,
              url,
              completeURL,
              pathParams,
              completeURL,
              isNotCASHeURL,
              requireRequest,
              token,
              isEncypted,
              params,
              error: res,
            };
            res.data.entity = CASHeUtils.getDecryptedText(res.data.entity);

            dispatch({
              type: ReduxValues.globalActions.SET_IS_POP_OPEN,
              isOpen: true,
              msg: Strings.errorMsg.error_500,
              cancleBtntxt: "OK",
              // showRedirection: true,
              navigatetohome: true,
              failureHandler: null,
            })
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            })

            dispatch({
              type: "SET_IS_ANIMATION_LOADING",
              isAnimationLoading: false,
            });
            return;
          } else if (res.data.status === 400 || res.data.statusType === "BAD_REQUEST") {
            // if (res.data.entity) {
            // res.data.entity = CASHeUtils.getDecryptedText(res.data.entity)

            // }
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: res.status,
              cancleBtntxt: "OK",
              showRedirection: false,
            });
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
            dispatch({
              type: "SET_IS_ANIMATION_LOADING",
              isAnimationLoading: false,
            });
            return;
          } else if (res.data.status === 401 && res.data["data"]) {
            if (res.data["entity"] && isEncypted) {
              res.data.entity = CASHeUtils.getDecryptedText(res.data.entity);
            }
            localStorage.removeItem("authData");
            sessionStorage.clear();
            window.location.href = BuildProperties.getHostedUrl();
            return;
          } else if (res.data.status > 401 && url != ApiConstant.merchant_verifyOtp) {
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: "Something went wrong! try re-login again.",
              cancleBtntxt: "OK",
              showRedirection: false,
              navigatetohome: true,
              failureHandler: null,
            });

            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
            return;
          } else {
            if (isEncypted) {
              let decResponse = CASHeUtils.getDecryptedText(
                CASHeUtils.removeAllWhiteSpaces(res.data.ciphertext)
              );

              if (decResponse == "true") {
                decResponse = true;
              } else if (decResponse == "false") {
                decResponse = false;
              } else {
                decResponse = decResponse;
              }

              res.data.ciphertext = decResponse;
              // CASHeLogglyManager.log(LogLevel.response,res)
              let resReqData = {
                methodType,
                url,
                completeURL,
                pathParams,
                isNotCASHeURL,
                request,
                token,
                isEncypted,
                params,
                response: res,
              };
              if (stopLoading) {
                dispatch({
                  type: "SET_IS_LOADING",
                  isLoading: false,
                });
              }
              if (res?.data?.ciphertext){
                return JSON.parse(res.data.ciphertext)
              }
              return res.data;
            } else {
              // CASHeLogglyManager.log(LogLevel.response,res)
              if (stopLoading) {
                dispatch({
                  type: "SET_IS_LOADING",
                  isLoading: false,
                });
              }

              return res.data;
            }
          }
        }
      } catch (error) {
        
        console.error("POST error:", error);
        if (error.response?.data?.ciphertext){
          console.error("POST error:", error);
          error.response.data =  JSON.parse(CASHeUtils.getDecryptedText(error.response?.data?.ciphertext)) ;
        }
        
        let resReqData = {
          methodType,
          url,
          completeURL,
          pathParams,
          isNotCASHeURL,
          request,
          token,
          isEncypted,
          params,
          error: error,
        };
        if (error.message === "timeout of 25000ms exceeded" || error.message.includes("timeout")) {
          dispatch({
            type: "SET_IS_POP_OPEN",
            isOpen: true,
            msg: "Oops! Your Internet is Unstable",
            cancleBtntxt: "Retry",
            failureHandler: () =>
              retryApiCall(
                methodType,
                url,
                pathParams,
                isNotCASHeURL,
                request,
                token,
                isEncypted,
                dispatch,
                params
              ),
          });
        } else {
          if (error.response.status === 401) {
            localStorage.removeItem("authData");
            sessionStorage.clear();
            window.location.href = BuildProperties.getHostedUrl();
          }else if(error.response?.data?.error){
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: error.response.data.error,
              cancleBtntxt: "OK",
              navigatetohome: true,
              failureHandler: null,
            });
          } else if (error.response.status === 400) {
            if (error.response["statusText"] === "Bad Request") {
              dispatch({
                type: "SET_IS_POP_OPEN",
                isOpen: true,
                msg: error.response["statusText"] ? error.response["statusText"] : "Bad Request",
                cancleBtntxt: "OK",
                navigatetohome: true,
                failureHandler: null,
              });
            } else if (error.response["data"]) {
              let res = error.response["data"];
              let errmsg = res;
              if (res["ciphertext"] && isEncypted) {
                errmsg = CASHeUtils.getDecryptedText(res.ciphertext);
                errmsg = JSON.parse(errmsg);
              }

              if (errmsg) {
                let msg = "Something went wrong!";
                if (errmsg['non_field_errors']) {
                  msg = errmsg['non_field_errors'][0]
                } else if (errmsg?.errors?.length > 0 && errmsg?.errors[0]?.error) {
                  
                  msg = errmsg.errors[0]?.error;
                  console.log("msg 112",msg)
                } else if (errmsg && errmsg instanceof Object && Object.keys(errmsg).length > 0) {
                  const  allkeys = Object.keys(errmsg)
                  const value = errmsg[allkeys[0]]
                  if (value instanceof Array && value.length > 0) {
                    msg = value[0]
                  }else{
                    msg = value
                  }
                  console.log("msg 112",msg)
                }
                if (
                  msg == "PIN not found" ||
                  msg == "PIN Not found" ||
                  msg == "Enter a valid PIN Code"
                ) {
                  
 
                  dispatch({
                    type: "SET_IS_POP_OPEN",
                    isOpen: true,
                    title: "PIN Not found",
                    msg:"We apologise for the inconvenience",
                    PopUpFooterText: "Looks like we are unable to recognise your PIN code at our end. Please drop a mail at partnersupport@13karat.in with your PIN code to resume your journey.",
                    cancleBtntxt: "OK",
                    failureHandler: null,
                    
                  });
                  // res.ciphertext = res?.ciphertext ? CASHeUtils.getDecryptedText(res.ciphertext) : null ;
                  // return res;
                } 
               else if(msg=="AADHAAR already registered"){
                  // console.log("msg 3",msg)
                  dispatch({
                    type: 'SET_IS_POP_OPEN',
                    isOpen: true,
                    msg: "Aadhaar already registered",
                    cancleBtntxt: 'Go Back',
                    title:"Invalid Aadhaar",
                    customImg:Images.AadharAlreadyRegistered,
                    failureHandler: null,
                  })
                  return ;
                }
                else {
                  dispatch({
                    type: "SET_IS_POP_OPEN",
                    isOpen: true,
                    msg: msg,
                    cancleBtntxt: "OK",
                    failureHandler: null,
                  });
                }
              } else {
                const errorMessage = error.response.data.error;
                dispatch({
                  type: "SET_IS_POP_OPEN",
                  isOpen: true,
                  msg: errorMessage ? errorMessage : "Something went wrong!",
                  cancleBtntxt: "OK",
                  failureHandler: null,
                });
              }
            } else {
              const errorMessage = error.response.data.status;

              let errorMsg = "";
              if (error.response["data"]) {
                errorMsg = Object.keys(error.response.data).map((ele) => {
                  return <p> {error.response.data[ele][0]}</p>;
                });
              }
              dispatch({
                type: "SET_IS_POP_OPEN",
                isOpen: true,
                msg: errorMsg,
                cancleBtntxt: "OK",
                failureHandler: null,
              });
            }
          } else if (error.response.status >= 500) {
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
            const errorMessage = error.response.data.error;
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              // msg: error['message']?error.message: Strings.errorMsg.error_500,
              msg: errorMessage
                ? errorMessage
                : error["message"]
                ? error.message
                : Strings.errorMsg.error_500,
              cancleBtntxt: "OK",
              showRedirection: false,
              navigatetohome: false,
              failureHandler: null,
            });
          } else {
            return error["response"] ? error.response : null;
          }
        }
      }
    }
  } else if (APIMethod.GET == methodType) {
    if (isFile) {
      const response = await axios.get(completeURL, {
        headers: reqHeaders,
        responseType: "blob",
      });
      console.log(response);
      let imgUrl = URL.createObjectURL(response.data);
      return imgUrl;
    } else {
      try {
        const res = await axios.get(completeURL, {
          headers: reqHeaders,
          timeout: customTimeout ? customTimeout : 1000 * 120,
        });
        console.log(res);
        if (res && res.data) {
          if (res.data?.status >= 500) {
            let resReqData = {
              methodType,
              url,
              completeURL,
              pathParams,
              isNotCASHeURL,
              request,
              token,
              isEncypted,
              params,
              error: res,
            };
            // dispatch(customerLastApicall(resReqData))
            if (isEncypted && res.data.ciphertext) {
              res.data.ciphertext = CASHeUtils.getDecryptedText(res.data.ciphertext);
            }
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: Strings.errorMsg.error_500,
              cancleBtntxt: "OK",
              showRedirection: true,
            });
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });

            dispatch({
              type: "SET_IS_ANIMATION_LOADING",
              isAnimationLoading: false,
            });

            return;
          } else if (res.data?.status === 401) {
            localStorage.removeItem("authData");
            sessionStorage.clear();
            window.location.href = BuildProperties.getHostedUrl();
            // dispatch({
            // type: 'SET_IS_POP_OPEN',
            // isOpen: true,
            // msg: 'Session expired,Please login again!',
            // shouldLogout:true,
            // cancleBtntxt: 'OK',
            // showRedirection: false,
            // navigatetohome: true,
            // failureHandler: null,
            // })
          } else if (res.data?.status > 401) {
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: "Something went wrong! try re-login again.",
              cancleBtntxt: "OK",
              showRedirection: false,
              navigatetohome: true,
              failureHandler: null,
            });
          } else {
            if (isEncypted) {
              let decResponse = null;

              if (res.data.ciphertext) {
                decResponse = CASHeUtils.getDecryptedText(
                  CASHeUtils.removeAllWhiteSpaces(res.data.ciphertext)
                );
              }

              if (decResponse == "true") {
                decResponse = true;
              } else if (decResponse == "false") {
                decResponse = false;
              } else {
                decResponse = decResponse;
              }
              res.data.ciphertext = decResponse;
              console.log("res.data.ciphertext", res.data.ciphertext);

              let resReqData = {
                methodType,
                url,
                completeURL,
                pathParams,
                isNotCASHeURL,
                request,
                token,
                isEncypted,
                params,
                response: res,
              };
              // dispatch(customerLastApicall(resReqData))

              // CASHeLogglyManager.log(LogLevel.response,res)
              if (stopLoading) {
                dispatch({
                  type: "SET_IS_LOADING",
                  isLoading: false,
                });
              }
              if (res?.data?.ciphertext){
                return JSON.parse(res.data.ciphertext)
              }
              return res.data;
            } else {
              if (stopLoading) {
                dispatch({
                  type: "SET_IS_LOADING",
                  isLoading: false,
                });
              }
              let resReqData = {
                methodType,
                url,
                completeURL,
                pathParams,
                isNotCASHeURL,
                request,
                token,
                isEncypted,
                params,
                response: res,
              };
              // dispatch(customerLastApicall(resReqData))

              // CASHeLogglyManager.log(LogLevel.response,res)
              return res.data;
            }
          }
        }
      } catch (error) {
        console.error(error);
        console.error("rawmsg", error.rawmsg);

        // dispatch(customerLastApicall(resReqData))
        if (error.message === "timeout of 25000ms exceeded" || error.message.includes("timeout")) {
          dispatch({
            type: "SET_IS_POP_OPEN",
            isOpen: true,
            msg: "Oops! Your Internet is Unstable",
            cancleBtntxt: "Retry",
            failureHandler: () =>
              retryApiCall(
                methodType,
                url,
                pathParams,
                isNotCASHeURL,
                request,
                token,
                isEncypted,
                dispatch,
                params
              ),

            // showRedirection:true,
          });
        } else {
          // CASHeLogglyManager.log(LogLevel.webServiceError,error)
          if (error?.response?.status === 401) {
            localStorage.removeItem("authData");
            sessionStorage.clear();
            window.location.href = BuildProperties.getHostedUrl();
          } else if (error?.response?.status === 500) {
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: Strings.errorMsg.error_500,
              cancleBtntxt: "OK",
            });

            return {
              errorAPi: url,
              error: "error",
              isErrorOccure: true,
              response: error.response,
            };
          } else if (error?.response?.status > 500) {
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
            dispatch({
              type: "SET_IS_POP_OPEN",
              isOpen: true,
              msg: error["message"] ? error.message : Strings.errorMsg.error_500,
              cancleBtntxt: "OK",
            });
          } else {
            return {
              errorAPi: url,
              error: "error",
              isErrorOccure: true,
              response: error?.response,
            };
          }
        }
      }
    }
  }
};

export const whatsapp_authentication = ({ request, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    let headers;
    headers = {
      "Content-Type": "application/json",
      // "x-encryption-off": 1
    };


    try {
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.whatsappAuthentication,
        isReqToken:  true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));

        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res;
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.MOBILE_NUMBER,
          payload: resData,
        });
        dispatch({
          type: ReduxValues.RestApiActions.NEW_USER,
          payload: resData,
        });
        callback(resData);

      } else {
        // console.log('StateList response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.whatsappAuthentication,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const otp_trigger = ({ request, isIFAPortalUser, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    let headers;
    if(isIFAPortalUser) {
    headers = {
      "Content-Type": "application/json",
      "Authorization":BuildProperties.getCustomAuth()
      // "PARTNER-KEY": "8V9qrCqpXYRu1yqEwHSJwvrc1UfZ1cYskW88xKQQ",
      // "x-encryption-off": 1
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      // "PARTNER-KEY": "8V9qrCqpXYRu1yqEwHSJwvrc1UfZ1cYskW88xKQQ",
      // "x-encryption-off": 1,
      "x-auth-group": 3,
      "Authorization":BuildProperties.getCustomAuth()
    };     
  }

    try {
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.triggerOTP,
        isReqToken: isIFAPortalUser ? true : false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));

        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res;
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.MOBILE_NUMBER,
          payload: resData,
        });
        callback(resData);
      } else if (resData) {
        // console.log('StateList response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.triggerOTP,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const otp_verify = ({ request, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    let headers;
    headers = {
      "Content-Type": "application/json",
      // "PARTNER-KEY": "8V9qrCqpXYRu1yqEwHSJwvrc1UfZ1cYskW88xKQQ",
      // "x-encryption-off": 1,
      "x-auth-group": 3,
      "Authorization":BuildProperties.getCustomAuth()
    };


    try {
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.verifyOTP,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        localStorage.setItem("authData", JSON.stringify({ isLoggedIn: true, entity: resData }));
        console.log("Login response:", resData);
        callback(resData);
      }
    } catch (error) {
      console.error("FlyRefferalCode error", error);
    }
  };
};

export const forgot_password_otp_verify = ({ request, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    let headers;
    headers = {
      "Content-Type": "application/json",
      // "PARTNER-KEY": "8V9qrCqpXYRu1yqEwHSJwvrc1UfZ1cYskW88xKQQ",
      // "x-encryption-off": 1,
      "x-auth-group": 3,
      "Authorization":BuildProperties.getCustomAuth()
    };


    try {
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.verifyOTP,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        // localStorage.setItem("authData", JSON.stringify({ isLoggedIn: true, entity: resData }));
        console.log("Login response:", resData);
        callback(resData);
      }
    } catch (error) {
      console.error("FlyRefferalCode error", error);
    }
  };
};

export const reinvestmentPreference = ({ request, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    let headers;
    headers = {
      "Content-Type": "application/json",
      // "x-encryption-off": 1
    };


    try {
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.ReinvestmentPreference,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        console.log("response:", res);
        callback(resData);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const createProfile = ({ request, isIFAPortalUser, callback }) => {
  console.log("Create Profile::", request);
  if(isIFAPortalUser) {
  return async (dispatch) => {
    try {
      // let headers =  { "x-encryption-off": "1" } ;
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.portalProfileDetailsApi,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: headers,
      });
      if (res) {
       // dispatch(setIsLoading(false));
      
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res;
        console.log("resDataAPiData", resData);
        
        if (resData && resData?.is_profile_completed) {
          dispatch({
            type: ReduxValues.RestApiActions.PORTAL_USER_PROFILE,
            // payload: res,
            payload: resData,
          });
          callback(resData);
        }else if(resData){
          callback({
            resData,
            isProfileSaved:true});
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
  } else {
    return async (dispatch) => {
      try {
        // let headers = {"x-encryption-off": "1"}
        const res = await FetchData({
          methodType: APIMethod.POST,
          url: ApiConstant.userProfileDetailsApi,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          request: request,
          // customHeader: headers,
        });
        if (res) {
          ;
          dispatch(setIsLoading(false));
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
          // const resData = res;
          console.log("resDataAPiData", resData);
          if (resData && resData?.user) {
            dispatch({
              type: ReduxValues.RestApiActions.FETCH_PROFILE,
              // payload: res,
              payload: resData,
            });
            callback(resData);
          } else if (resData) {
            console.log("Login response:", resData);
            callback({
              isErrorOccure: true,
              errorUrl: ApiConstant.userProfileDetailsApi,
              errorText: "Something went wrong!",
              title: "Error",
              ...resData,
            });
          } else {
            callback(null);
          }
        } else {
          callback(null);
        }
      } catch (error) {
        console.log("validate error", error);
      }
    };
  }
};

export const fetchUserProfile = ({ pathParams, callback }) => {
  return async (dispatch) => {
    try {
      // let headers = {"x-encryption-off": "1"}
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.portalProfileDetailsApi,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        pathParams: pathParams,
        // customHeader: headers
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // const resData = res;
      if (resData) {
        sessionStorage.setItem('fetchPortalUserProfileResponse', JSON.stringify(resData))
        dispatch({
          type: ReduxValues.RestApiActions.SET_USER_GET_PROFILE_DATA,
          payload: resData
        });
        callback(resData)
    console.log("------resData -----------" + resData)
      } else if (resData) {
        // console.log('StateList response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankList,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const fetchProfile = ({ callback }) => {
  return async (dispatch) => {
    try {
      // let customHeader = {
      //   "x-encryption-off": 1,
      // };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.userProfileDetailsApi,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader: customHeader,
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
  
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_PROFILE,
          payload: resData,
        });

        if(resData?.aadhar_number){
          dispatch({
            type: ReduxValues.RestApiActions.SET_AADHAR_NUMBER,
            aadharNumber: resData?.aadhar_number,
          });
        }


        if(resData?.gst_certificate_no){
          dispatch({
            type: ReduxValues.RestApiActions.SET_GST_NUMBER,
            gstNumber: resData?.gst_certificate_no,
          });
        }
        callback(resData);
      } else if (resData) {
        // console.log('StateList response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankList,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const GetPdfCertificate = ({ pathParams, callback }) => {

  return async (dispatch) => {

    try {
      let headers = { "x-encryption-off": "1" }
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.PdfCertificate,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        isFile: true,
        pathParams: pathParams,
        customHeader: headers,
      });
      dispatch(setIsLoading(false));
      const resData = res

      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.PDFCERIFICATE,
          payload: resData,
        });
        callback(resData)
      } else if (resData) {
        console.log('Pdf Api response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.PdfCertificate,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);
    }
  }
};

export const uploaddocDetail = ({ request, isIFAPortalUser, callback }) => {
  console.log("formdata", request);
  if(isIFAPortalUser) {
    return async (dispatch) => {
      try {
        let headers = { "Content-Type": "multipart/form-data", "x-encryption-off": "1"};
  
        const res = await FetchData({
          methodType: APIMethod.POST,
          url: ApiConstant.portalUploadDoc,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: false,
          request: request,
          customHeader: headers,
        });
  
        dispatch(setIsLoading(false));
    //    const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
        if ( res && (res?.status === "Document Updated Successfully" ||
          res?.status === "Document Created Successfully" ||
          res?.status === "Aadhaar Updated Successfully")
        ) {
          dispatch({
            type: ReduxValues.RestApiActions.PORTAL_UPLOAD_DOC,
            payload: res
          });
          callback(res);
        } else if (res) {
          console.log("Login response:", res);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.portalUploadDoc,
            errorText: "Something went wrong!",
            title: "Error",
            ...res,
          });
        }
      } catch (error) {
        console.log("validate error", error);
      }
    };
  } else {
  return async (dispatch) => {
    try {
      let headers = { "Content-Type": "multipart/form-data", "x-encryption-off": "1" };

      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.uploadDoc,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));
      // const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if ( res && (res?.status === "Document Updated Successfully" ||
          res?.status === "Document Created Successfully" ||
          res?.status === "Aadhaar Updated Successfully")
        ) {
        dispatch({
          type: ReduxValues.RestApiActions.UPLOAD_DOC,
          payload: res,
          // payload:JSON.parse(res.ciphertext)
        });
        callback(res);
      } else if (res) {
        console.log("Login response:", res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.uploadDoc,
          errorText: "Something went wrong!",
          title: "Error",
          ...res,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
}
};

export const uploadNetWorthdocDetail = ({ request, isIFAPortalUser, callback }) => {
  console.log("formdata", request);
  if(isIFAPortalUser) {
    return async (dispatch) => {
      try {
        let headers = { "x-encryption-off": "1"};
  
        const res = await FetchData({
          methodType: APIMethod.POST,
          url: ApiConstant.poralNetWorthAPI,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: false,
          request: request,
          customHeader: headers,
        });
  
        dispatch(setIsLoading(false));
    //    const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
        if (
          (res && res?.message === "Document Updated Successfully") ||
          res?.message === "Document Created Successfully" || res?.message === "Document Uploaded Successfully"
        ) {
          dispatch({
            type: ReduxValues.RestApiActions.PORTAL_UPLOAD_DOC,
            payload: res
          });
          callback(res);
        } else if (res) {
          console.log("Login response:", res);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.poralNetWorthAPI,
            errorText: "Something went wrong!",
            title: "Error",
            ...res,
          });
        }
      } catch (error) {
        console.log("validate error", error);
      }
    };
  } else {
  return async (dispatch) => {
    try {
      let headers = { "Content-Type": "multipart/form-data", "x-encryption-off": "1" };

      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.uploadDoc,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        request: request,
        customHeader: headers,
      });

      dispatch(setIsLoading(false));
      // const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (
        (res && res?.status === "Document Updated Successfully") ||
        res?.status === "Document Created Successfully"
      ) {
        dispatch({
          type: ReduxValues.RestApiActions.UPLOAD_DOC,
          payload: res,
          // payload:JSON.parse(res.ciphertext)
        });
        callback(res);
      } else if (res) {
        console.log("Login response:", res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.uploadDoc,
          errorText: "Something went wrong!",
          title: "Error",
          ...res,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
}
};

export const fetchDocDetail = ({ req, docType, callback }) => {
  return async (dispatch) => {
    try {
      let headers = { "x-encryption-off": "1" };

      const res = await FetchData({
        methodType: APIMethod.POST,
        request: req,
        url: ApiConstant.getDoc,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        isFile: true,
        customHeader: headers,
      });

      if(res?.data?.type==="application/pdf" && res?.imgUrl){
        let imgdata= await convertPdfToImages(res.data)
        if(imgdata){
          res.imgUrl=imgdata[0]
        }
        // console.log("imgdata*********",imgdata)
      }

      dispatch(setIsLoading(false));
      dispatch({
        type: ReduxValues.RestApiActions.FETCH_DOC,
        payload: { docType, res: res },
        // payload:JSON.parse(res.ciphertext)
      });
      callback(res);
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const fetchUserDocDetail = ({ req, docType, callback }) => {
  return async (dispatch) => {
    try {
      let headers = { "x-encryption-off": "1" };

      const res = await FetchData({
        methodType: APIMethod.POST,
        request: req,
        url: ApiConstant.getUserDoc,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        isFile: true,
        customHeader: headers,
      });

      if(res.data.type==="application/pdf" && res?.imgUrl){
        let imgdata= await convertPdfToImages(res.data)
        if(imgdata){
          res.imgUrl=imgdata[0]
        }
        // console.log("imgdata*********",imgdata)
      }

      dispatch(setIsLoading(false));
      dispatch({
        type: ReduxValues.RestApiActions.FETCH_USER_DOC,
        payload: { docType, res: res },
        // payload:JSON.parse(res.ciphertext)
      });
      callback(res);
    } catch (error) {
      console.log("validate error", error);
    }
  };
};
export const fetchNetWorthDocDetail = ({ req, docType, callback }) => {
  return async (dispatch) => {
    try {
      let headers = { "x-encryption-off": "1" };

      const res = await FetchData({
        methodType: APIMethod.POST,
        request: req,
        url: ApiConstant.getNetWorthUserDoc,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        isFile: true,
        customHeader: headers,
      });

      if(res.data.type==="application/pdf" && res?.imgUrl){
        let imgdata= await convertPdfToImages(res.data)
        if(imgdata){
          res.imgUrl=imgdata[0]
        }
        // console.log("imgdata*********",imgdata)
      }

      dispatch(setIsLoading(false));
      dispatch({
        type: ReduxValues.RestApiActions.FETCH_USER_DOC,
        payload: { docType, res: res },
        // payload:JSON.parse(res.ciphertext)
      });
      callback(res);
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const fetchNetWorthSample = ({callback }) => {
  return async (dispatch) => {
    try {
      let headers = { "x-encryption-off": "1" }
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.getNetWorthUserDoc,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        isFile: false,
        customHeader: headers,
      });
      dispatch(setIsLoading(false));
      const resData = res

      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.NETWORTHCERTIFICATE,
          payload: resData,
        });
        callback(resData)
      } else if (resData) {
        console.log('Pdf New Worth Api response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.getNetWorthUserDoc,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);
    }
  };
};

export const fetchBankList = ({ callback }) => {
  return async (dispatch) => {
    try {
      // let customHeader = {
      //   "x-encryption-off": 1,
      // };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.bankList,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader: customHeader,
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // const resData = res;
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_BANK_LIST,
          payload: resData,
        });
        callback(resData);
      } else if (resData) {
        console.log("Login response:", resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankList,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const fetchPortalBankList = ({ callback }) => {
  return async (dispatch) => {
    try {
      // let customHeader = {
      //   "x-encryption-off": 1,
      // };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.portalbankList,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader: customHeader,
      });

      dispatch(setIsLoading(false));
      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // const resData = res;
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_PORTAL_BANK_LIST,
          payload: resData,
        });
        callback(resData);
      } else if (resData) {
        console.log("Login response:", resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankList,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const createBankAccount = ({ request, isIFAPortalUser, callback }) => {
  if(isIFAPortalUser) {
  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "1" };
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.portalBankDataApi,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: headers,
      });

      dispatch(setIsLoading(false));

      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // const resData = res
      
      if (resData && resData?.user) {
        
        
        dispatch({
          type: ReduxValues.RestApiActions.CREATE_BANK_ACCOUNT,
          // payload: res,
          payload: resData,
        });
        
        callback(resData)

      } else if (resData) {
        
        console.log('Login response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.portalBankDataApi,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
} else {
  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "1" };
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.bankDataApi,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: headers,
      });

      dispatch(setIsLoading(false));

      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
     
      
      if (resData && resData?.user) {
        
        
        dispatch({
          type: ReduxValues.RestApiActions.CREATE_BANK_ACCOUNT,
          // payload: res,
          payload: resData,
        });
        
        callback(resData)

      } else if (resData) {
        
        console.log('Login response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankDataApi,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
}
};

export const fetchBankAccountDetails = ({ callback }) => {
  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "1" };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.bankDataApi,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader: headers,
      });

      dispatch(setIsLoading(false));

      const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // const resData = res;

      if (resData && resData?.user) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_BANK_ACCOUNT,
          payload: resData,
        });

        // dispatch({
        //   type: ReduxValues.RestApiActions.SET_BANK_NAME_VALUE,
        //   bankName: resData?.bank_name,
        // });
        callback(resData);
      } else if (resData) {
        console.log("Login response:", resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankDataApi,
          errorText: "Something went wrong!",
          title: "Error",
          ...resData,
        });
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};


export const GetCityList = ({ request, callback }) => {
  return async (dispatch) => {
    try {

      // let headers = { "x-encryption-off": "1" }
      console.log(request, "cityList")

      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.GetCityList,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        request: request,
        // customHeader: headers
      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {

        console.log("cityList", resData)

        dispatch({
          type: ReduxValues.RestApiActions.FETCH_CITY_LIST,
          payload: resData
          // payload:res
        });
        callback(resData)

      } else if (resData) {
        console.log('City response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.GetCityList,
          errorText: "Something went wrong!",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const GetBranchIfsc = ({ request, callback }) => {

  return async (dispatch) => {

    try {
      // let customHeader = {
      //   'x-encryption-off': 1,

      // };
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.GetBranchIfsc,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        request: request,
        // customHeader: customHeader,
      });

     // dispatch(setIsLoading(false));
       const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_BRANCH_DATA,
          payload: resData,
        });
        callback(resData)


      }
      if (resData && Array.isArray(resData) && resData.length === 0) {

        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.GetBranchIfsc,
          errorText: "No results found. Select Another City",
          ...resData
        });
        console.log('fetch Branch List Response response:', resData);
      }
      else if (resData) {
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.GetBranchIfsc,
          errorText: "Something went wrong!",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const GetStateList = ({ callback }) => {
  return async (dispatch) => {
    try {
      // let customHeader = {
      //   'x-encryption-off':1,

      // };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.GetStateList,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader:customHeader,

      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_STATE_LIST,
          payload: resData,
        });
        callback(resData)

      } else if (resData) {
        // console.log('StateList response:', res);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankList,
          errorText: "Something went wrong!",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const getBankDetailsOnIFSC = ({ request, callback }) => {
  return async (dispatch) => {
    try {
      let customHeader = {
        'x-encryption-off': 1,
      };
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.bankIfsccode,
        isReqToken: false,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        request: request,
        customHeader: customHeader,

      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData && resData?.bank_name) {

        dispatch({
          type: ReduxValues.RestApiActions.FETCH_BANK_DETAILS_ON_IFSC_CODE,
          payload: resData,
        });
        // if (resData?.bank_name) {
        //   dispatch({
        //     type: ReduxValues.RestApiActions.SET_BANK_NAME_VALUE,
        //     bankName: resData.bank_name
        //   })
        // }
        callback(resData)

      } else if (resData) {
        console.log('Login response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankIfsccode,
          errorText: "Something went wrong!",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const logoutUser = ({ token, callback }) => {
  
  return async (dispatch) => {
    try {

      let headers = { "x-auth-group": 3, }
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.logout,
        isReqToken: token ? false : true,
        token: token ? token : null,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        customHeader: headers
      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData && resData['status'] && resData.status[0] === "user logged out successfully") {
        dispatch(setIsLoading(false));
        dispatch({
          type: ReduxValues.RestApiActions.LOGOUT_USER,
          payload: resData,
        });
       
        callback(resData)
      } else if (resData) {
        dispatch(setIsLoading(false));
        console.log('Login response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.uploadDoc,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }

    } catch (error) {
      dispatch(setIsLoading(false));
      console.log('validate error', error);

    }
  }
};

export const faqDataUse = ({ callback }) => {

  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "1" }

      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.faqDataGet,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader:headers,
      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // const resData=res
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FAQ_DATA,
          payload: resData
        });
        callback(res)

      } else if (resData) {
        console.log('FAQ response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.faqDataGet,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const setIsLoggedIn = (flag) => {
  return {
    type: ReduxValues.RestApiActions.setIsLoggedIn,
    flag,
  };
};

export const setIsLoading = (flag) => {
  return {
    type: "SET_IS_LOADING",
    isLoading: flag,
  };
};

export const setFetch_Doc_State = ({ payload }) => {
  return async (dispatch) => {
    dispatch({
      type: ReduxValues.RestApiActions.FETCH_DOC,
      payload,
    });
  };
};

export const create_esign_request = ({ request, callback }) => {
  console.log("Request URL::", request);
  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "0" };
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.createEsignRequest,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: headers,
      });
      if (res) {
        ;
        dispatch(setIsLoading(false));
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res;
        console.log("resDataAPiData", resData);
        if (resData && resData?.data) {
          dispatch({
            type: ReduxValues.RestApiActions.CREATE_ESIGN_REQUEST,
            // payload: res,
            payload: resData.data,
          });
          callback(resData.data);
        } else if (resData) {
          console.log("Login response:", resData.data);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.createEsignRequest,
            errorText: "Something went wrong!",
            title: "Error",
            ...resData,
          });
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const fetch_esign_request = ({ callback }) => {
  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "1" };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.createEsignRequest,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader: headers,
      });
      if (res) {
        ;
        dispatch(setIsLoading(false));
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      
        console.log("resDataAPiData", resData);
        if (resData && resData?.data) {
          dispatch({
            type: ReduxValues.RestApiActions.CREATE_ESIGN_REQUEST,
            // payload: res,
            payload: resData.data,
          });
          callback(resData.data);
        } else if (resData) {
          console.log("Login response:", resData.data);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.createEsignRequest,
            errorText: "Something went wrong!",
            title: "Error",
            ...resData,
          });
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const downloadAggrementPDF = ({ reqURL, callback }) => {
  return async (dispatch) => {
    try {
       let headers = { "x-encryption-off": "1" };
      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.esignDownload,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: false,
        isFile: true,
        customHeader: headers

        // customBaseUrl:reqURL
      });
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        ;
        dispatch(setIsLoading(false));
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
        console.log("resDataAPiData",resData)
        if (resData) {
          callback(resData)

        } else if (resData) {
          console.log("Login response:", resData.data);
          callback({
            isErrorOccure: true,
            errorUrl: resData.data.error,
            errorText: "Something went wrong!",
            title: "Error",
            ...resData,
          });
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    } catch (error) {
      console.log("validate error", error);
    }
  };
};

export const fetchTxnHistory = ({ request, isPagination, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      // let headers = { "x-encryption-off": "1" }
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.txnHistory,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: headers
      });

        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      
      

      if (resData) {
        dispatch(setIsLoading(false));

        if (!isPagination) {
          sessionStorage.setItem('fetchTxnHistoryResponse', res.ciphertext)
          dispatch({
            type: ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA,
            // payload: res.transactions,
            payload: resData.transactions
          });
        }
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_TXN_DATA,
          // payload: res,

          payload: resData,
          isPagination,
        });
        callback(resData)

      } else if (resData) {
        dispatch(setIsLoading(false));
        console.log('fetchTxnHistory response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.txnHistory,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log('validate error', error);

    }
  }
};
export const FatchUserTxnHistory = ({ request, isPagination, callback }) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      // let headers = { "x-encryption-off": "1" }
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.FatchUserTxnHistory,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: headers
      });

        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData) {
        dispatch(setIsLoading(false));

        // if (!isPagination) {
          sessionStorage.setItem('fetchUserHistoryResponse', res.ciphertext)
          // dispatch({
          //   type: ReduxValues.RestApiActions.PORTAL_USER_PROFILE,
          //   // payload: res.transactions,
          //   payload: resData.transactions
          // });
        // }
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_USER_DATA,
          // payload: res,FETCH_USER_DATA,PORTAL_USER_PROFILE
          payload: resData,
          isPagination,
        });
        callback(resData)

      } else if (resData) {
        dispatch(setIsLoading(false));
        console.log('fetchTxnHistory response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.txnHistory,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      console.log('validate error', error);

    }
  }
};

export const cancelPayment = ({ request, callback }) => {
  
  return async (dispatch) => {
    try {
      // let customHeader = { 'x-encryption-off': 1,};
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.CancelPayment,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: customHeader,

      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData && resData?.message) {

        dispatch({
          type: ReduxValues.RestApiActions.CANCELPAYMENT,
          payload: resData,
        });
        callback(resData)

      } else if (resData) {
        console.log('Cancel Payment response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.CancelPayment,
          errorText: "Something went wrong!",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

// UserNotifications
export const UserNotifications = ({ request, callback }) => {
  
  return async (dispatch) => {
    try {
      // let customHeader = {
      //   'x-encryption-off': 1,
      // };
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.UserNotifications,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: customHeader,

      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      if (resData && resData?.notifications) {

        dispatch({
          type: ReduxValues.RestApiActions.NOTIFICATIONSDATA,
          payload: resData,
        });
        // if (resData?.bank_name) {
        //   dispatch({
        //     type: ReduxValues.RestApiActions.SET_BANK_NAME_VALUE,
        //     bankName: resData.bank_name
        //   })
        // }
        callback(resData)

      } else if (resData) {
        console.log('Login response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.bankIfsccode,
          errorText: "Something went wrong!",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const UpcomingMaturity = ({ callback }) => {

  return async (dispatch) => {
    try {
      // let headers = { "x-encryption-off": "1" }

      const res = await FetchData({
        methodType: APIMethod.GET,
        url: ApiConstant.UpcomingMaturity,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        // customHeader:headers,
      });

      dispatch(setIsLoading(false));
      //   const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      const resData=res
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.UPCOMINGMATURITY,
          payload: resData
        });
        callback(res)

      } else if (resData) {
        console.log('FAQ response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.UpcomingMaturity,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

export const payOrder = ({ request, callback }) => {
  
  return async (dispatch) => {

    try {
      // let headers = { "x-encryption-off": "1" }
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.payOrder,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        customTimeout: 1000 * 120,
        // customHeader: headers,
        customTimeout: 1000 * 120,
      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      // if(headers["x-encryption-off"] == "0"){
      //   resData = JSON.parse(res.ciphertext)
      // }

      if (resData && resData?.merchant_transaction_id) {
        let reqData = {
          request: request,
          response: resData
        }
        sessionStorage.setItem('payOrderResponse', JSON.stringify(reqData))
        dispatch({
          type: ReduxValues.RestApiActions.PAY_ORDER,
          // payload: res,
          payload: resData,
        });
        callback(resData)

      } else if (resData) {
        console.log('payOrderResponse response:', resData);
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.payOrder,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

  export const createInvestment = ({ request, callback }) => {

    return async (dispatch) => {
      try {
        // let headers = { "x-encryption-off": "1" }
        const res = await FetchData({
          methodType: APIMethod.POST,
          url: ApiConstant.createInvestment,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          // isEncypted:headers?.["x-encryption-off"] === "0"?true:false,
          isEncypted:true,
          request: request,
          // customHeader: headers
        });
  
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
        dispatch(setIsLoading(false));
       
        if (resData) {
          sessionStorage.setItem('createInvestmentResponse', res.ciphertext)
  
          dispatch({
            type: ReduxValues.RestApiActions.CREATE_INVESTMENT,
            // payload: res,
            payload: resData
          });
          callback(resData)
  
        } else if (resData) {
          console.log('Login response:', resData);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.createInvestment,
            errorText: "Something went wrong!",
            title:"Error",
            ...resData
          })
        }
      } catch (error) {
        console.log('validate error', error);
  
      }
    }
  };

  export const fetchBankAccountDetailsPortal = ({pathParams, callback }) => {
    return async (dispatch) => {
      try {
        // let headers = { "x-encryption-off": "1" };
        const res = await FetchData({
          methodType: APIMethod.GET,
          url: ApiConstant.bankAutofetchPortal,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          pathParams: pathParams,
          // customHeader: headers,
        });
  
        dispatch(setIsLoading(false));
  
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
        
  
        if (resData && resData?.user) {
          dispatch({
            type: ReduxValues.RestApiActions.FETCH_BANK_ACCOUNTPORTAL,
            payload: resData,
          });
  
          // dispatch({
          //   type: ReduxValues.RestApiActions.SET_BANK_NAME_VALUE,
          //   bankName: resData?.bank_name,
          // });
          callback(resData);
        } else if (resData) {
          console.log("Login response:", resData);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.bankDataApi,
            errorText: "Something went wrong!",
            title: "Error",
            ...resData,
          });
        }
      } catch (error) {
        console.log("validate error", error);
      }
    };
  };

  export const fetchPerviousTransactionsPortal = ({pathParams, callback }) => {
    return async (dispatch) => {
      try {
        // let headers = { "x-encryption-off": "1" };
        const res = await FetchData({
          methodType: APIMethod.GET,
          url: ApiConstant.perviousTransactions,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          pathParams: pathParams,
          // customHeader: headers,
        });
  
        dispatch(setIsLoading(false));
  
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
   
  
        if (resData) {
          // dispatch({
          //   type: ReduxValues.RestApiActions.FETCH_BANK_ACCOUNTPORTAL,
          //   payload: resData,
          // });
          callback(resData);
        } else if (resData) {
          console.log("Login response:", resData);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.bankDataApi,
            errorText: "Something went wrong!",
            title: "Error",
            ...resData,
          });
        }
      } catch (error) {
        console.log("validate error", error);
      }
    };
  };

  export const CustomerNetWorth = ({pathParams, callback }) => {
    return async (dispatch) => {
  
      try {
        // let customHeader = {'x-encryption-off':1,};
        const res = await FetchData({
          methodType: APIMethod.GET,
          url: ApiConstant.CustomerNetWorthUrl,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          // customHeader:customHeader,
          pathParams:pathParams,
        });
  
        dispatch(setIsLoading(false));
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
      
        if (resData) {
          dispatch({
            type: ReduxValues.RestApiActions.USERNETWORTH,
            payload: resData,
          });
          callback(resData)
  
        } else if (resData) {
          console.log('Deactivate response:', res);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.CustomerNetWorthUrl,
            errorText: "Something went wrong!",
            title:"Error",
            ...resData
          })
        }
      } catch (error) {
        console.log('validate error', error);
  
      }
    }
  };

    // Send NommineeRelation to Beckend 
export const NommineeRelationUpdate = ({pathParams, request, callback }) => {

  return async (dispatch) => {

    try {
      // let customHeader = {'x-encryption-off': 1,};
      const res = await FetchData({
        methodType: APIMethod.POST,
        url: ApiConstant.fetchRelationDetails,
        isReqToken: true,
        isNotCASHeURL: false,
        dispatch: dispatch,
        isEncypted: true,
        request: request,
        // customHeader: customHeader,
        // pathParams: pathParams,
      });

      dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
  
      if (resData) {
        dispatch({
          type: ReduxValues.RestApiActions.FETCH_BRANCH_DATA,
          payload: resData,
        });
        callback(resData)


      }
      // if (resData && Array.isArray(resData) && resData.length === 0) {

      //   callback({
      //     isErrorOccure: true,
      //     errorUrl: ApiConstant.GetBranchIfsc,
      //     errorText: "No results found. Select Another City",
      //     ...resData
      //   });
      //   console.log('fetch Branch List Response response:', resData);
      // }
      else if (resData) {
        callback({
          isErrorOccure: true,
          errorUrl: ApiConstant.fetchRelationDetails,
          errorText: "Something went wrong!",
          title:"Error",
          ...resData
        })
      }
    } catch (error) {
      console.log('validate error', error);

    }
  }
};

    // NommineeRelationUpdate
  export const fetchRelationDetails = ({pathParams, callback }) => {
    return async (dispatch) => {
      try {
  
        // let headers= { "x-encryption-off": "1"}
        const res = await FetchData({
          methodType: APIMethod.GET,
          url: ApiConstant.fetchRelationDetails,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          // customHeader:headers,
          pathParams: pathParams,
        });
  
        dispatch(setIsLoading(false));
  
          const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
       
  
        if (resData && resData?.relations) {
  
          dispatch({
            type: ReduxValues.RestApiActions.FETCH_RELATION_LIST,
            payload: resData,
          });
  
          // dispatch({
          //   type: ReduxValues.RestApiActions.SET_RELATION_LIST,
          //   relationName: resData,
          // })
          callback(resData)
  
        } else if (resData) {
          console.log('Login response:', resData);
          callback({
            isErrorOccure: true,
            errorUrl: ApiConstant.fetchRelationDetails,
            errorText: "Something went wrong!",
            title:"Error",
            ...resData
          })
        }
      } catch (error) {
        console.log('validate error', error);
  
      }
    }
  };
  export const UserPasswordLogin = ({ request, callback }) => {
    return async (dispatch) => {
      dispatch(setIsLoading(true));
      let headers;
      headers = {
        "Content-Type": "application/json",
        // "PARTNER-KEY": "8V9qrCqpXYRu1yqEwHSJwvrc1UfZ1cYskW88xKQQ",
        "Authorization": BuildProperties.getCustomAuth(),
        "x-auth-group": 3,
      };
  
  
      try {
        const res = await FetchData({
          methodType: APIMethod.POST,
          url: ApiConstant.passwordLogin,
          isReqToken: false,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          request: request,
          customHeader: headers,
        });
  
        dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res
        if (resData) {
          localStorage.setItem("authData", JSON.stringify({ isLoggedIn: true, entity: resData.data }));
          console.log("Login response:", resData);
          callback(res);
        }
      } catch (error) {
        console.error("FlyRefferalCode error", error);
      }
    };
  };
  export const UserUpdatePasswordLogin = ({ request,token, callback }) => {
    return async (dispatch) => {
      dispatch(setIsLoading(true));
      let headers;
      headers = {
        "x-auth-group": 3,
        "Authorization": token || BuildProperties.getCustomAuth(),
      };
  
  
      try {
        const res = await FetchData({
          methodType: APIMethod.POST,
          url: ApiConstant.updatePassword,
          isReqToken: true,
          isNotCASHeURL: false,
          dispatch: dispatch,
          isEncypted: true,
          request: request,
          customHeader: headers,
        });
  
        dispatch(setIsLoading(false));
        const resData = res?.ciphertext ? JSON.parse(res.ciphertext) : res

        if (resData) {
          // localStorage.setItem("authData", JSON.stringify({ isLoggedIn: true, entity: res }));
          // console.log("Login response:", res);
          callback(resData);
        }
      } catch (error) {
        console.error("FlyRefferalCode error", error);
      }
    };
  };



export const setTotalTxnData = (data) => {
  return {
    type: ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA,
    payload: data,

  }
}

export const SetCurrentTxnFilterdata=(data)=>{
  return {
    type: ReduxValues.RestApiActions.SET_TOTAL_TXN_DATA_FILTER,
    payload: data,

  }
}
export const SetCurrentUserFilterdata=(data)=>{
  return {
    type: ReduxValues.RestApiActions.SET_TOTAL_USER_DATA_FILTER,
    payload: data,

  }
}


export const SetUserListdata=(data)=>{
  return {
    type: ReduxValues.RestApiActions.FETCH_USER_DATA,
    payload: data,
  }
}

export const SetFetchTxnHistoryReponse=(resData)=>{
  return{
    type: ReduxValues.RestApiActions.FETCH_TXN_DATA,  
    payload: resData,
  }

}   