
class FormItem {
    constructor({ title, value = null, fieldName, label, placeholder, keyboardtype, inputLimit, formType, type, rightView, showBottomBorder, defaultvalue, disabled, selectivedata = [], isRequired = false, inputRef, inputList = [], isFromPortal, onchangeHandler, onFocus, onBlur, customWidth, defaultFun, inputValue, radioButtonElements = [], customRightData,customLeftData }) {
        this.title = title;
        this.fieldName = fieldName;
        this.type = type;
        this.label = label;
        this.defaultvalue = defaultvalue;
        this.placeholder = placeholder;
        this.keyboardtype = keyboardtype;
        this.inputLimit = inputLimit;
        this.formType = formType;
        this.rightView = rightView;
        this.showBottomBorder = showBottomBorder;
        this.disabled = disabled;
        this.selectivedata = selectivedata;
        this.isRequired = isRequired;
        this.inputRef = inputRef;
        this.inputList = inputList;
        this.isFromPortal =isFromPortal;
        this.onchangeHandler = onchangeHandler;
        this.value = value;
        this.onFocus = onFocus;
        this.onBlur = onBlur;
        this.customWidth = customWidth;
        this.defaultFun = defaultFun;
        this.inputValue = inputValue;
        this.radioButtonElements = radioButtonElements;
        this.customRightData = customRightData;
        this.customLeftData= customLeftData;
    }
}

export { FormItem };

