import React, { useEffect, useState } from 'react';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import Stack from "@mui/material/Stack";
import Backdrop from '@mui/material/Backdrop';

import SideBar from './Portal_Sidebar';
import { border, height } from '@mui/system';
import PortalImages from '../../Images/Portal_Images/PortalImages';
import { useSelector, useDispatch } from 'react-redux'
// import { setShowSideDrawer, setShowDesktopSideDrawer } from '../ReduxStore/Actions/globalAction'
import Styles from '../../Style/Portal/header.module.css'
import CASHeUtils from '../../Utils/CASHeUtils';

const HeaderComponent = (props) => {
    const localStorageAuthData= JSON.parse(localStorage.getItem("authData"));
    const [showHeader,setShowHeader]=useState(false)
    const globalState = useSelector(state => state.globalState);
    const dispatch = useDispatch();
    const restApiState = useSelector(state=>state.restApiState);


    const sideMenuHandler = () => {
        console.log('burger')
       // dispatch(setShowSideDrawer(true))
        //dispatch(setShowDesktopSideDrawer(true))

    }


    const closeSideDrawer = () => {
       // dispatch(setShowSideDrawer(false))
        //dispatch(setShowDesktopSideDrawer(false))
    }


    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '100%' }} className="container-fluid">

                    <span style={{ fontSize: 11, fontFamily: 'Gilroy-Bold', alignSelf: 'center' }} className={globalState.showSideDrawer ? Styles.titleAnimation : Styles.reverseTitleAnimation}>{props.title}</span>
                    <Stack direction="row" spacing={4} sx={{ display: 'flex', alignItems: 'center' }}>

                        {!globalState.showSideDrawer && <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, width: '100%', padding: 1, borderRadius: 10 }}>
                            <img src={PortalImages.CASHeMainLogo} style={{ height: "75%", width: '90%' }} />
                        </Stack>}
                        <MenuSharpIcon sx={{ color: '#555555', height: 30, width: 30 }} onClick={sideMenuHandler} />
                    </Stack>
                </div>

            </>

        )
    }


// if(restApiState.isLoggedIn){
    return (
        <>
        {CASHeUtils.isMobileDevice()&& <Backdrop
             sx={{ color: '#fff' }}
             open={globalState.showSideDrawer && CASHeUtils.isMobileDevice()}
         />
        }
        <SideBar PaperProps={{ elevation: 0 }} />
         <SideBar appBar={renderHeader}
             open={true}
             showDesktopSideBar={globalState.showDesktopSideBar}
             onClose={closeSideDrawer}
             title={props.title}
             sideMenuHandler={sideMenuHandler}
             PaperProps={{ elevation: 0 }}
         >
             {props.children}
         </SideBar>

        
     </>
    )
// }else{
//     return (
//         <>
//         {props.children}  
//         </>
//     )
// }

}

export default HeaderComponent